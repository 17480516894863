import Gikam from './gikam-core';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N.js';
import { RsaEncryptUtils } from '@/gikam/js/utils/rsaEncryptUtils';
import { LoginUtils } from '../utils/loginUtils';

window.onbeforeunload = function(e) {
    if (window.workspace && window.workspace.speakHandler) {
        Gikam.stopSpeak(window.workspace.speakHandler);
    }

    const confirmValue = window.workspace.onbeforeunload && window.workspace.onbeforeunload();
    if (confirmValue) {
        const _e = e || window.event;
        if (_e) {
            _e.returnValue = confirmValue;
        }
        return confirmValue;
    }
};

//销毁dropDownMenu组件
const dropDownDestroyHandle = function() {
    Gikam.dropDownMenuPicker && Gikam.dropDownMenuPicker.close();
};

//销毁grid下generalGroup组件
const generalGroupDestroyHandle = function() {
    Gikam.generalGroupPanel && Gikam.generalGroupPanel.destroy();
};
//销毁richText组件
const richTextDestroyHandle = function() {
    Gikam.textareaPicker && Gikam.textareaPicker.close();
};

//销毁grid高级搜索组件
const genericQueryDestroyHandle = function() {
    Gikam.genericQueryPicker && Gikam.genericQueryPicker.close();
};

//销毁chooseInput模拟title气泡
const poptipPanelDestroyHandle = function() {
    Gikam.poptipPanel && Gikam.poptipPanel.close instanceof Function && Gikam.poptipPanel.close();
};

//销毁下拉框组建
const selectDestroyHandle = function() {
    if (Gikam.selectPicker) {
        Gikam.selectPicker.close();
    }
    if (Gikam.insertablePicker) {
        Gikam.insertablePicker.close();
    }

    if (Gikam.comboboxPicker) {
        Gikam.comboboxPicker.combobox.cleanPicker();
    }
};

// //销毁日期选择组件
const dateDestroyHandle = function() {
    if (Gikam.datePicker) {
        Gikam.datePicker.destroy();
    }
};

// //销毁年选择组件
const yearDestroyHandle = function() {
    if (!Gikam.yearPicker) {
        return;
    }
    Gikam.yearPicker.destroy();
};

//销毁时间选择组件
const timeDestroyHandle = function() {
    if (Gikam.timePicker) {
        Gikam.timePicker.destroy();
    }
};

const cleanGlobalField = function(e) {
    if (Gikam.isEmpty(e)) {
        return (Gikam.globalActiveField = null);
    }
    //增加e.target.dataset判断，解决项目内手动调用document 事件 造成的影响
    if (e.target.tagName === 'INPUT' || (e.target.dataset && e.target.dataset.user === 'memoryGlobalField')) {
        return;
    }
    if (Gikam.globalActiveField) {
        return (Gikam.globalActiveField = null);
    }
};

document.addEventListener('click', function(e) {
    dropDownDestroyHandle();
    generalGroupDestroyHandle();
    dateDestroyHandle();
    yearDestroyHandle();
    timeDestroyHandle();
    cleanGlobalField(e);
    genericQueryDestroyHandle();
});

document.addEventListener('touchmove', dropDownDestroyHandle);
document.addEventListener('touchmove', generalGroupDestroyHandle);

const wheel = document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
document.addEventListener(wheel, function() {
    richTextDestroyHandle();
    poptipPanelDestroyHandle();
    selectDestroyHandle();
    dateDestroyHandle();
    yearDestroyHandle();
    timeDestroyHandle();
});

document.addEventListener('mousedown', function() {
    dateDestroyHandle();
    yearDestroyHandle();
    timeDestroyHandle();
    richTextDestroyHandle();
    selectDestroyHandle();
});

// eslint-disable-next-line complexity
document.addEventListener('keydown', function(e) {
    if (e.keyCode === 9 && e.target.tagName === 'BODY' && top.Gikam) {
        e.preventDefault();
        return;
    }
    if ([38, 40].indexOf(e.keyCode) >= 0) {
        if (!top.Gikam || !top.Gikam.activeGrid) {
            return;
        }
        e.preventDefault();
        const grid = top.Gikam.activeGrid;
        const maxLength = grid.getData().length;
        if (maxLength <= 0) {
            return;
        }
        const activeRow = grid.getActivedRow();
        if (maxLength === 1 && activeRow) {
            return;
        }
        if (!activeRow) {
            grid.activeRowByIndex(0);
            return;
        }

        const currentIndex = activeRow.index;
        if ((currentIndex === 0 && e.keyCode === 38) || (currentIndex === maxLength - 1 && e.keyCode === 40)) {
            return;
        }

        let rowIndex;
        if (e.keyCode === 38) {
            rowIndex = activeRow.index - 1 >= 0 ? activeRow.index - 1 : 0;
        } else if (e.keyCode === 40) {
            rowIndex = activeRow.index + 1 < maxLength ? activeRow.index + 1 : maxLength - 1;
        }

        grid.activeRowByIndex(rowIndex);
        grid.model.$nextTick(() => {
            const id = grid.getData() && grid.getData()[rowIndex] && grid.getData()[rowIndex].id;
            grid._positionScrollY(id);
        });
    }
});

// 错误详情弹框
function createErrorMessage(response) {
    const message = Gikam.parseJson(response.message);
    if (!message) {
        return;
    }
    const info = {};
    if (Gikam.isString(message)) {
        info.message = Gikam.propI18N(message);
    } else {
        if (message.stacktrace && message.message) {
            const ajaxParam = Gikam.ajaxParamContainer[response.path];
            info.message = Gikam.propI18N(message.message);
            info.detailMessage = `${JSON.stringify(ajaxParam) || ''}\r\n${message.stacktrace}`;
        }
    }
    Gikam.create('ErrorAlert', info);
}

// 判断是否超时
function isSessionTimeout(response) {
    const unauthorized = 'core.security.login.unauthorized';
    if (typeof response === 'string' && (response.indexOf('<!DOCTYPE') === 0 || response.includes(unauthorized))) {
        return true;
    }
    if (typeof response === 'object' && response.message === unauthorized) {
        return true;
    }
    return false;
}

jQuery.ajaxSetup({
    beforeSend(xhr) {
        if (!Gikam.isEmptyObject(Gikam.loginUser)) {
            xhr.setRequestHeader('sunway-remember-me', Gikam.loginUser.ext$['loginToken'.toLocaleLowerCase()]);
        }
        xhr.setRequestHeader('locale', Gikam.locale);
    },

    error(response) {
        if (!response.responseText) {
            return;
        }
        const responseObject = Gikam.parseJson(response.responseText);
        if (!responseObject) {
            return;
        }
        if (response.status === 410) {
            Gikam.create('reLoginAlert', {
                title: I18N.prop('core.loginAbnormal'),
                message: I18N.prop('core.loginAbnormalMessage'),
                confirmText: I18N.prop('core.confirmText'),
                countdown: false,
                onClick: function() {
                    location.reload();
                }
            });
            return;
        }
        if (![400, 500].includes(responseObject.status)) {
            return;
        }
        if (
            Gikam.ignoreErrorUrl.some(item => {
                return responseObject.path.includes(item);
            })
        ) {
            return;
        }
        createErrorMessage(responseObject);
    },

    success: function(response) {
        if (isSessionTimeout(response)) {
            Gikam.removeInterval();

            if (window !== top.window) {
                return;
            }

            if (Gikam.reLoginModal) {
                return;
            }

            if (!Gikam.loginUser.id) {
                location.href = '/';
                return;
            }

            if (Gikam.sessionTimeout && Gikam.isFunction(Gikam.sessionTimeout)) {
                Gikam.sessionTimeout();
                return;
            }

            const login = async password => {
                const publicKey = await RsaEncryptUtils.getPublicKey();
                Gikam.ajax({
                    url: Gikam.IFM_CONTEXT + '/core/module/sys/login',
                    type: 'post',
                    beforeSend: xhr => {
                        xhr.setRequestHeader('SECURITY-RSA-KEY', publicKey);
                    },
                    data: {
                        id: LoginUtils.getLoginUserId(),
                        password: RsaEncryptUtils.encode(publicKey, password),
                        orgId: Gikam.loginUser.ext$.loginorgid,
                        roleId: Gikam.loginUser.ext$.loginroleid,
                        loginType: LoginUtils.getLoginType()
                    }
                })
                    .done(() => {
                        Gikam.reLoginModal = null;
                        simpleModal.modal.close();
                        setTimeout(() => {
                            const modal = Gikam.getLastModal();
                            if (modal && modal.options.title !== '超时，请重新登录') {
                                modal.window.pageUrl && modal.window.load(modal.window.pageUrl);
                                return;
                            }
                            Gikam.window && Gikam.window.pageUrl && Gikam.window.load(Gikam.window.pageUrl);
                            Gikam.renewInterval();
                        }, 0);
                    })
                    .fail(r => {
                        Gikam.alert(Gikam.propI18N(JSON.parse(r.responseText).message));
                    });
            };

            const simpleModal = top.Gikam.createSimpleModal({
                title: I18N.prop('core.timeOut'),
                width: 400,
                height: 200,
                showCloseBtn: false,
                showMaxBtn: false,
                bodyStyle: {
                    padding: '0 16px'
                },
                toolbarFormatter() {
                    return [
                        {
                            type: 'button',
                            text: I18N.prop('core.login'),
                            class: 'blue',
                            onClick() {
                                form.validate() && login(form.getData().password);
                            }
                        }
                    ];
                }
            });

            Gikam.reLoginModal = simpleModal;

            const form = Gikam.create('form', {
                renderTo: simpleModal.layout.options.center.$dom,
                columns: 1,
                titleWidth: 50,
                autoSave: false,
                fields: [
                    {
                        title: I18N.prop('core.userId'),
                        field: 'userId',
                        readonly: true,
                        value: LoginUtils.getLoginUserId()
                    },
                    {
                        title: I18N.prop('core.password'),
                        field: 'password',
                        type: 'password',
                        validators: ['notEmpty'],
                        remembered: false,
                        onEnterKeyDown(value) {
                            form.validate() && login(value);
                        }
                    }
                ]
            });
        }
    }
});
