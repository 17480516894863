var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hiddenGrid),expression:"!hiddenGrid"}],staticClass:"form",class:_vm.getClass(),style:(_vm.options.style),attrs:{"id":_vm.options.id}},_vm._l((_vm.options.panels),function(panel,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(panel.hidden !== true),expression:"panel.hidden !== true"}],key:index,staticClass:"panel",class:{ 'no-title': !panel.title },attrs:{"id":panel.id}},[(panel.title)?_c('header',[_vm._v(_vm._s(_vm.propI18N(panel.title)))]):_vm._e(),(_vm.options.caption.text)?_c('sunway-caption',{attrs:{"options":_vm.options.caption}}):_vm._e(),(panel.caption && panel.caption.text)?_c('sunway-caption',{attrs:{"options":panel.caption}}):_vm._e(),_c('section',{class:{ floatMode: _vm.options.mode === 'surround' || panel.mode === 'surround' }},_vm._l((panel.fields),function(field){return _c('div',{key:field,ref:'group_' + field.field,refInFor:true,staticClass:"form-group",style:({
                    width: field.width
                        ? field.width + 'px'
                        : (1 / _vm.options.columns) * (field.colspan || 1) * 100 + '%'
                })},[(field.field)?[_c('div',{staticClass:"title",style:({
                            width: _vm.options.titleWidth + 'px',
                            justifyContent: _vm.options.titleAlign === 'left' ? 'flex-start' : null
                        }),attrs:{"title":_vm.formatTitleTip(field)}},[_c('span',{staticClass:"text",class:_vm._f("getLabelClass")(field),style:(field.titleStyleFormatter
                                    ? field.titleStyleFormatter(field.field, _vm.options.data)
                                    : {})},[_vm._v(" "+_vm._s(_vm.propI18N(field.title))+" ")])]),(field.tip)?_c('div',{staticClass:"tip",on:{"mouseenter":function($event){$event.stopPropagation();return _vm.hoverTip($event, field)},"mouseleave":function($event){$event.stopPropagation();return _vm.leaveTip.apply(null, arguments)}}},[_c('help-exclamation-img')],1):_vm._e(),(field.title)?_c('span',{staticClass:"colon"},[_vm._v("：")]):_vm._e(),(field.field)?_c('div',{staticClass:"field"},[_c('editor',{attrs:{"options":field,"formData":_vm.options.data}})],1):_c('div',{staticClass:"field"})]:_vm._e()],2)}),0)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }