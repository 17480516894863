import Gikam from 'gikam';
import jQuery from 'jquery';
import Vue from 'vue';
import Base from '../../base.js';
import table from '../vue/resultInput.vue';
import { columnsAddWidth, baseErgodicTreeData, addParentFields, getLeafCol } from './utils';
import { tableStore } from './resultInputStore';

let defaultOptions = {
    height: 400,
    fill: false,
    defaultFill: false,
    headerHeight: 35,
    columns: [],
    data: [],
    fieldChangeFlag: true,
    group: {
        groupCheckbox: true,
        fields: [],
        formatter: Gikam.emptyFunction,
        expand: true
    },
    pageNum: -1,
    pageSize: -1,
    requestData: {},
    requestDataDeleteNull: true,
    cellClickEffectRow: true,
    showBody: true,
    //默认排序字段
    order: {},
    columnGroup: {
        field: []
    },
    // 激活单元格时是否带有边框
    activeCell: false,
    //为les审计跟踪使用
    hasAudit: false,
    activeOnCheck: false,
    checkOnActive: true
};

export default class ResultInput extends Base {
    constructor(options) {
        super('ResultInput');
        this.$upCompat();
        this.getListeners();
        this.initialize(options, defaultOptions);
        this.init();
    }

    getListeners() {
        this.listeners = {
            rendered: Gikam.emptyFunction,
            rowActive: Gikam.emptyFunction,
            cellClick: Gikam.emptyFunction,
            headerCellClick: Gikam.emptyFunction,
            columnsWidthChange: Gikam.emptyFunction,
            loadSuccess: Gikam.emptyFunction
        };
    }

    $upCompat() {
        this.errorFields = [];
    }

    $initStore() {
        this.store = tableStore(Vue, this.options);
    }

    //改变列 最后执行函数
    $initColumns(columns) {
        // 1.初始化宽度
        columnsAddWidth(columns);

        // 2.初始化field
        addParentFields(columns);

        //更新视图
        this.store.commit('changeColumns', columns);
    }

    //改变数据 最后执行函数
    $commitData(data) {
        const _data = [];
        //插入列中的值
        this.options.data.forEach(row => {
            _data.push(Gikam.deepExtend(this.fieldsData, row, data));
        });

        this.store.commit('changeData', _data);
        this.trigger('loadSuccess', _data);
    }

    async createModel() {
        const el = Gikam.createDom('div', this.options.renderTo);
        await this.$cacheSelectItems(this.options.columns);
        const _this = this;
        this.model = new Vue({
            el: el,
            store: this.store,
            provide: {
                bindGridHook: callback => callback.bind(_this),
                triggerEvent: _this.trigger.bind(_this),
                autoSave: Gikam.emptyFunction,
                grid: _this
            },
            render() {
                return <sunway-table ref="vm" id={_this.options.id}></sunway-table>;
            },
            components: {
                sunwayTable: table
            }
        });
        await this.model.$nextTick();
        this.bindInstance(this.model.$el);
        this.trigger('rendered');

        this.$getColumnsValue(this.options.columns);
        if (this.$initGetDate()) {
            this.refresh();
        } else {
            this.$commitData();
        }
    }

    $initGetDate() {
        if (Gikam.isNotEmpty(this.options.url)) {
            return true;
        } else {
            return false;
        }
    }

    async $cacheSelectItems(columns) {
        const leftColumns = getLeafCol(columns);
        const filterType = ['Select', 'SelectInput', 'ComboBox'];
        const codeArray = leftColumns.filter(function(item) {
            return filterType.indexOf(Gikam.toInitialUpperCase(item.type || '')) > -1 && Gikam.isEmpty(item.items);
        });

        if (Gikam.isEmpty(codeArray)) {
            return;
        }
        await new Promise(resolve => {
            Gikam.select.cacheItems(codeArray).always(() => {
                resolve();
            });
        });
        //此处为异步
    }

    $getColumnsValue(columns) {
        const hasItemsData = {};
        baseErgodicTreeData(columns, 'subColumns', function() {
            const upperCaseType = Gikam.toInitialUpperCase(this.type || 'text');
            const _field = this.field;
            if (['Select', 'SelectInput', 'ComboBox'].indexOf(upperCaseType) > -1) {
                const item = this.items && this.selectedIndex && this.items[this.selectedIndex];
                _field && this.value && (hasItemsData[_field] = item.value);
            } else {
                _field && this.value && (hasItemsData[_field] = this.value);
            }
        });

        this.fieldsData = hasItemsData;
    }

    queryParams(options, pageNum) {
        if (this.options.requestDataDeleteNull) {
            Gikam.each(this.options.requestData, (k, v) => {
                if (Gikam.isEmpty(v)) delete this.options.requestData[k];
            });
        }
        const requestData = {
            f: Gikam.extend(true, {}, this.options.requestData, options),
            n: Gikam.isNotEmpty(pageNum) ? pageNum : this.options.page ? this.options.pageNum : -1,
            s: this.options.pageSize
        };

        if (!Gikam.isEmptyObject(this.options.order)) {
            const orderArray = [];
            for (const key in this.options.order) {
                const order = {};
                order[key] = this.options.order[key];
                orderArray.push(order);
            }
            requestData.o = orderArray;
        }

        return Gikam.getJsonWrapper(requestData);
    }

    onResize() {
        this.model.$refs.vm.onResize();
    }

    //。。。。。。。。。。。。。。。。。。。。。。暴露的方法 👇

    /**
     * @description 动态刷新表格列
     * @public
     * @param {Array} columns 新的字段数组
     * @returns {Deferred}
     * @memberof Table
     */
    refreshColumns(columns) {
        this.$cacheSelectItems(columns).then(() => {
            this.$getColumnsValue(columns);
            this.$commitData();
            this.$initColumns(columns);
        });
    }

    refreshByData({ rows }) {
        this.options.data = rows;
        this.$commitData();
    }

    getLeafColumns() {
        const _leafColumns = this.store.getters.leafColumns;
        return _leafColumns ? Gikam.deepExtend(_leafColumns) : [];
    }

    getColumns() {
        const _columns = this.store.state.columns;
        return _columns ? Gikam.deepExtend(_columns) : [];
    }

    getData() {
        const _data = this.store.state.data;
        return _data ? Gikam.deepExtend(_data) : [];
    }

    setDataByIndex(index, field, value) {
        const _data = this.getData()[index - 1];
        if (Gikam.isEmpty(_data)) {
            return;
        }
        const _row = Gikam.deepExtend(_data);
        _row[field] = value;
        Vue.set(this.store.state.data, index - 1, _row);
    }

    setDataById(id, field, value) {
        const _data = this.getData();
        let index = null;
        Gikam.each(_data, (i, row) => {
            if (id === row.id) {
                index = i;
                return false;
            }
        });
        if (index === null) {
            return;
        }
        this.setDataByIndex(index + 1, field, value);
    }

    refresh(param) {
        const def = jQuery.Deferred();
        param && this.setOptions(param);
        if (this.trigger('beforeRefresh', this.options.requestData) === false) {
            return def.fail();
        }

        Gikam.post(this.options.url, this.queryParams())
            .done(r => {
                this.refreshByData(r);
                def.resolve(this);
            })
            .fail(() => {
                def.fail();
            });
        return def;
    }

    getActiveCell() {
        return this.store.state.gridCell.activeCellVue;
    }

    getCellVueFromIndex(rowIndex, colIndex) {
        return this.model.$refs.vm.$refs['table-body'].getCell(rowIndex, colIndex);
    }

    setOptions(option) {
        const newOptions = Gikam.deepExtend(this.options, option);
        this.options = newOptions;
        this.store.commit('changeDefaultOptions', newOptions);
        return this;
    }

    hide() {
        this.store.commit('changeShowTable', false);
        return this;
    }

    show() {
        this.store.commit('changeShowTable', true);
        return this;
    }

    getSelections() {
        if (this.store.state.selectRow.checkAll === true) {
            return Gikam.deepExtend(this.store.state.data);
        } else {
            const getSelectIds = this.store.getters['selectRow/getSelectIds'];
            return this.store.state.data.filter(item => getSelectIds.includes(String(item.id))).slice(0);
        }
    }

    selectRowByIndex(indexArr) {
        const ids = [];
        const data = this.store.state.data;
        indexArr.forEach(index => {
            data[index] && ids.push(data[index].id);
        });
        this.selectRowById(ids);
        return this;
    }

    selectRowById(ids) {
        const map = {};
        ids.forEach(id => {
            map[id] = true;
        });
        this.store.state.selectRow.selectMap = map;
        return this;
    }

    activeRowByIndex(index) {
        if (Gikam.isEmpty(index) || index < 0 || index >= this.store.state.data.length) {
            return Gikam.error('当前行没有数据');
        }
        this.store.commit('gridBody/changeActiveRowIndex', { index, type: 'row' });
        return this;
    }

    activeRowById(id) {
        const index = this.getIndexFromId(id);
        this.activeRowByIndex(index);
        return this;
    }

    //。。。。。。。。。。。。。。。。。。。。。。暴露的方法 👆

    init() {
        this.$initStore();
        this.$initColumns(this.options.columns);
        this.createModel();
    }
}
