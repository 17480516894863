import Gikam from '../../core/gikam-core.js';
import Base from '../base.js';

let defaultOptions = {
    iframe: null,
    version: null,
    fileType: null,
    fileId: null,
    fileName: null,
    fileUrl: null,
    mode: 'view',
    viewType: 'modal',
    callbackUrl: null,
    popupIsModal: true,
    otherParam: {
        download: true,
        print: true
    },
    onAfterClose: Gikam.emptyFunction
};

export default class EditorOffice extends Base {
    constructor(options) {
        super();
        this.getListeners();
        options.viewType =
            options.otherParam && options.otherParam.viewType ? options.otherParam.viewType : options.viewType;
        this.initialize(options, defaultOptions).init();
    }

    /**
     * @description 可配置钩子函数
     * @private
     * @memberof EditorOffice
     */
    getListeners() {
        this.listeners = {
            warn: Gikam.emptyFunction,
            error: Gikam.emptyFunction
        };
    }

    /**
     * @description 加载onlyoffice组件
     * @private
     * @memberof onlyoffice
     */
    loadDocEditor() {
        //监听加载状态
        this.iframe.onload = function() {
            //加载完毕初始化编辑页面
            this.iframe.contentWindow.initDocEditorPage(this.serverUrl, this.editorOfficeObj, this.officeHook);
        }.bind(this);

        //监听编辑页面无法加载时的情况
        this.iframe.onerror = function() {
            Gikam.alert(`无法找到编辑页面，请联系管理员配置文档编辑页面。`);
            this.trigger('error');
            this.destroy();
        }.bind(this);
    }

    getFileType() {
        return Gikam.isNotEmpty(this.options.fileUrl)
            ? this.options.fileUrl.substring(this.options.fileUrl.lastIndexOf('.') + 1)
            : '';
    }

    getFileName() {
        return Gikam.isNotEmpty(this.options.fileUrl)
            ? this.options.fileUrl.substring(this.options.fileUrl.lastIndexOf('/') + 1)
            : '';
    }

    /**
     * @description 初始化组件参数
     * @private
     * @memberof onlyoffice
     */
    // eslint-disable-next-line complexity
    async initParams() {
        //判断文件数据是否完整
        let fileDetailCompleted = true;
        //判断使用桌面模式还是移动模式
        let type = 'desktop';
        let key = new Date().getTime().toString();
        this.options.fileType = this.getFileType().toLocaleLowerCase();
        this.options.fileName = this.getFileName();

        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) type = 'mobile';

        //获取onlyoffice服务地址
        this.serverUrl = Gikam.onlyoffice.serverUrl || Gikam.IFM_CONTEXT;
        if (Gikam.isNotEmpty(this.options.fileId)) {
            await this.getFileUrl()
                .done(file => {
                    this.options.fileId = file.id;
                    this.options.fileUrl = Gikam.isNotEmpty(this.options.fileUrl)
                        ? this.options.fileUrl
                        : file.downloadUrl.replace(file.name, encodeURIComponent(file.name));
                    this.options.fileName = file.name || '';
                    this.options.fileType = (file.fileExt || '').toLocaleLowerCase();
                    this.options.version = file.version || '';
                    this.iframe.src = `${Gikam.IFM_CONTEXT}/static/plugin/onlyoffice/office.html`;
                })
                .fail(() => {
                    this.destroy();
                    fileDetailCompleted = false;
                    Gikam.alert(`无法获取文件信息，请联系管理员。`);
                });
            key = this.options.fileId.toString();
            if (this.options.version) {
                key = `${key.substring(0, 16)}-V${this.options.version}`;
            } else {
                key = key.substring(0, 14);
            }
        }
        this.iframe.src = `${Gikam.IFM_CONTEXT}/static/plugin/onlyoffice/office.html`;

        if (!fileDetailCompleted) return;

        //配置office编辑器所需参数
        this.editorOfficeObj = {
            //文档参数
            document: {
                //文件类型
                fileType: this.options.fileType,
                //文件ID
                key: key,
                //文件名称
                title: this.options.fileName,
                //文件下载地址
                url: Gikam.onlyoffice.downloadUrl + this.options.fileUrl,
                permissions: {
                    download: this.options.otherParam.download, // 下载按钮，默认true，传入false会隐藏
                    print: this.options.otherParam.print, //打印按钮，默认true，传入false会隐藏
                    comment: this.options.otherParam.comment === false ? false : true // 传入false禁止批注功能
                }
            },
            //编辑器参数
            editorConfig: {
                // 传了回调的保存接口后，直接调用，不传就用核心的
                callbackUrl:
                    this.options.callbackUrl ||
                    `${Gikam.IFM_CONTEXT}/core/module/item/files/onlyoffices/${this.options.fileId}/action/invoke`,
                ///编辑模式（view : 查看 | edit : 编辑）
                mode: this.options.mode,
                // 工作流程对应的文本控制标签, 值是数组, 如果不传值, 默认都能修改, 都不能修改则对应的是mode = 'view'预览模式
                workflow: this.options.otherParam.workflow || [],
                customization: {
                    forcesave: true, // 默认配置, 修改慎重修改, 此处是把onlyoffice.html文件中的默认值给移到此处
                    help: false // 默认配置, 修改慎重修改, 此处是把onlyoffice.html文件中的默认值给移到此
                }
            },
            type: type
        };

        this.editorOfficeObj.editorConfig.user = {};
        if (window.workspace.user.userName) {
            this.editorOfficeObj.editorConfig.user.name = window.workspace.user.userName;
        }
        if (window.workspace.user.userNo) {
            this.editorOfficeObj.editorConfig.user.id = window.workspace.user.userNo;
        }
        // 预览模式下且能添加评论配置
        if (this.options.mode === 'view' && this.options.otherParam.comment !== false) {
            this.editorOfficeObj.document.permissions.edit = false;
            this.editorOfficeObj.editorConfig.mode = 'edit';
        }
        // 预览模式下，不能添加评论配置
        if (this.options.mode === 'view' && this.options.otherParam.comment === false) {
            this.editorOfficeObj.editorConfig.callbackUrl = '';
        }
        // 只能修改文本控制区域的内容， 且不可删除文本控制区域
        if (this.options.otherParam.fillForms === true) {
            this.editorOfficeObj.document.permissions.edit = false;
            this.editorOfficeObj.document.permissions.comment = false;
            this.editorOfficeObj.editorConfig.mode = 'edit';
            this.editorOfficeObj.document.permissions.fillForms = true;
        }
        this.options.viewType !== 'blank' && this.loadDocEditor();
    }

    /**
     * @description 获取文件信息
     * @private
     * @memberof EditorOffice
     */
    getFileUrl = function() {
        return Gikam.getJson(`${Gikam.IFM_CONTEXT}/core/module/item/files/${this.options.fileId}`);
    };

    /**
     * @description 初始化iframe容器
     * @private
     * @memberof onlyoffice
     */
    initEditorContainer() {
        if (this.options.viewType === 'modal') {
            this.initViewPort();
            let _this = this;
            this.modal = Gikam.create('modal', {
                title: '文档编辑',
                src: 'about:blank',
                isModal: Gikam.isFalse(_this.options.popupIsModal) ? false : true,
                onAfterClose: function() {
                    let tempMeta = document.getElementById('mobile-viewport');
                    if (tempMeta) tempMeta.parentNode.removeChild(tempMeta);
                    if (typeof _this.options.onAfterClose === 'function') _this.options.onAfterClose();
                }
            });

            this.iframe = this.modal.getIframeDom();
        } else if (this.options.viewType === 'iframe') {
            this.iframe = this.options.iframe;
        } else if (this.options.viewType === 'blank') {
            this.iframe = window.open(`${location.href}static/plugin/onlyoffice/office.html`);
            this.iframe.onload = async () => {
                this.iframe.document.title = this.options.fileName;
                await this.initParams();
                this.iframe.initDocEditorPage(this.serverUrl, this.editorOfficeObj, this.officeHook);
            };
        }

        if (!this.iframe) {
            this.destroy();
            Gikam.alert('未获取到嵌入页面，请联系管理员');
        }
    }

    /**
     * @description 初始化onlyoffice自用钩子函数
     * @private
     * @memberof onlyoffice
     */
    initOfficeHookFunction() {
        let _this = this;
        //组件错误时调用
        let errorFunc = function(event) {
            if (event.data.errorCode == -4) {
                Gikam.alert(`文件无法下载，请联系管理员。`);
            } else {
                Gikam.alert(`文件服务异常，请联系管理员配置文档编辑功能。`);
            }
            Gikam.error(`错误代码: ${event.data.errorCode} -------- 错误信息: ${event.data.errorDescription}`);
            _this.trigger('error', event);
            _this.destroy();
        };

        //组件警告时调用
        let warningFunc = function(event) {
            if (!event.data.warningCode) return;
            Gikam.warn(`警告代码: ${event.data.warningCode} -------- 警告信息: ${event.data.warningDescription}`);
            _this.trigger('warn', event);
        };

        //无法获取onlyoffice服务器api.js时调用
        let onScriptError = function() {
            Gikam.alert(`无法连接文件服务器，请联系管理员配置文档编辑功能。`);
            _this.trigger('error');
            _this.destroy();
        };

        //将应用程序加载到浏览器时调用的函数
        let onAppReady = Gikam.emptyFunction;
        //将文档加载到文档编辑器中时调用的函数
        let onDocumentReady = Gikam.emptyFunction;
        //修改文档时调用的函数
        let onDocumentStateChange = Gikam.emptyFunction;

        this.officeHook = {
            onError: errorFunc,
            onWarning: warningFunc,
            onScriptError: onScriptError,
            onAppReady: onAppReady,
            onDocumentReady: onDocumentReady,
            onDocumentStateChange: onDocumentStateChange
        };
    }

    initViewPort() {
        let tempMeta = document.getElementById('mobile-viewport');
        !tempMeta && (tempMeta = document.createElement('meta'));
        tempMeta.id = 'mobile-viewport';
        tempMeta.name = 'viewport';
        tempMeta.content =
            'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, minimal-ui';
        document.head.appendChild(tempMeta);
    }

    init() {
        this.initOfficeHookFunction();
        this.initEditorContainer();
        this.initParams();
    }

    /**
     * @description 关闭编辑页面
     * @private
     * @memberof onlyoffice
     */
    destroy() {
        if (this.modal) this.modal.close();
        if (this.iframe) this.iframe.src = '';
    }
}
