<template>
    <div class="checkbox-container" :class="{ invisible: invisible }" @mousedown.stop @click.stop>
        <div class="readonly-text" v-if="invisible" :title="valueText" @dblclick="dblclickHandle" @click="clickHandle">
            {{ readonlyText }}
        </div>
        <template v-else>
            <base-box
                v-if="showAllCheck"
                class="all"
                boxType="all"
                @checkedEvent="checkedAllHandler"
                :propsChecked="allChecked"
                :item="{ value: 'all', text: '全选' }"
                :propsReadonly="checkboxReadonly || readonlyAll"
            >
            </base-box>
            <base-box
                v-for="item in items"
                :key="item.value"
                class="item"
                boxType="box"
                @checkedEvent="checkedBoxHandler"
                :propsChecked="allChecked"
                :item="item"
                :propsValues="value"
                :propsReadonlyLists="readonlyList"
                :propsReadonly="checkboxReadonly || readonlyAll"
            >
            </base-box>
            <div class="input" :style="{ width: inputWidth }" v-if="showInput">
                <base-box
                    class="other"
                    boxType="other"
                    @checkedEvent="checkedOtherHandler"
                    :propsChecked="otherChecked"
                    :item="{ value: inputValue, text: inputText }"
                    :hasCheckbox="onlyInput"
                    :propsReadonly="readonlyAll"
                ></base-box>
                <input
                    :class="{ readonly: inputReadonly }"
                    :readonly="(inputReadonly ? 'readonly' : false) || readonlyAll"
                    type="text"
                    v-model.lazy.trim="inputValue"
                    :title="inputValue"
                    autocomplete="off"
                />
            </div>
        </template>
    </div>
</template>

<script>
import baseBox from './baseBox';
import Gikam from 'gikam';

export default {
    props: {
        options: Object,
        propValue: String,
        propReadonly: Boolean,
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const otherChecked = this.options.otherInput?.value ? true : false;
        const onlyInput = this.options.otherInput?.onlyInput || false;
        const value = otherChecked && onlyInput ? [] : (this.propValue || this.options.value)?.split(',') || [];
        return {
            invisible: this.propInvisible,
            items: this.options.items,
            value: value,
            inputValue: this.options.otherInput?.value || '',

            allChecked: false,
            otherChecked: otherChecked,
            readonlyAll : this.options.readonly ? this.options.readonly : false,

            inputReadonly: onlyInput && !otherChecked,
            checkboxReadonly: onlyInput && otherChecked,
            readonlyList: []
        };
    },

    computed: {
        readonlyText() {
            const _text = this.items.filter(item => this.value.includes(item.value)).map(item => item.text);
            return _text + (this.inputValue || '');
        },

        readonly() {
            return this.propReadonly || this.options.propReadonly;
        },

        showAllCheck() {
            return !Gikam.isFalse(this.options.showAllCheck) && this.items.length > 0;
        },

        showInput() {
            return this.options.otherInput ? true : false;
        },

        inputWidth() {
            return (this.options.otherInput?.width || 100) + 'px';
        },

        inputText() {
            return this.options.otherInput?.inputText || '其他';
        },

        onlyInput() {
            return this.options.otherInput?.onlyInput || false;
        }
    },

    components: { baseBox },

    methods: {
        //外部修改input框的值
        setInputValue(val) {
            if (this.onlyInput === true) {
                this.value = [];
            }
            this.otherChecked = true;
            this.inputValue = val;
        },

        //外部修改选项内容
        setItems(items) {
            this.items = items;
            this.value = [];
            if (this.onlyInput === true) {
                this.otherChecked = false;
                this.inputValue = '';
            }
        },

        //外部修改只读字段
        setReadonly(list) {
            this.readonlyList = list;
        },

        getValue() {
            const val = Gikam.deepExtend(this.value);
            this.inputValue !== '' && val.push(this.inputValue);
            return val.join(',');
        },

        //全选框选中
        checkedAllHandler(checked) {
            if (checked) {
                this.value = this.items.map(item => item.value);
            } else {
                this.value = [];
            }

            this.change(this.value, this.inputValue);
        },

        //选项框选中
        checkedBoxHandler(checked, value) {
            if (checked) {
                this.value.push(value);
            } else {
                this.value = this.value.filter(val => val !== value);
            }
            this.change(this.value, this.inputValue);
        },

        //其他框选中
        checkedOtherHandler(checked) {
            this.otherChecked = checked;
            this.inputReadonly = !checked;
            this.checkboxReadonly = checked;
            if (checked) {
                this.value = [];
            } else {
                this.inputValue = '';
            }
        },

        //触发change
        change(valueArr, inputValue) {
            const val = Gikam.deepExtend(valueArr);
            inputValue !== '' && val.push(inputValue);
            this.$emit('change', this.options.field, val.join(','));
        },

        clickHandle() {
            this.$emit('click', this);
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val.length >= this.items.length) {
                    this.allChecked = true;
                } else {
                    this.allChecked = false;
                }
            },
            immediate: true
        },

        propValue(val) {
            const reg = new RegExp(',?' + this.inputValue, 'g');
            const value = this.showInput && this.inputValue ? val.replace(reg, '') : val;
            this.value = value ? value.split(',') : [];
        },

        inputValue(val) {
            this.change(this.value, val);
        },

        propInvisible(val) {
            this.invisible = val;
        }
    }
};
</script>

<style scoped lang="scss">
.checkbox-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 30px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid rgba(0, 122, 255, 0.5);
    border-radius: 4px;
    padding: 0 8px;
    .readonly-text {
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
    }
    .item,
    .all {
        padding-right: 8px;
    }
    .other {
        padding-right: 0;
    }
    .input {
        display: flex;
        align-items: center;
        .other {
            white-space: nowrap;
        }
        input {
            flex: 1;
            height: 20px;
            min-width: 20px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            padding: 2px 4px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.65);
            &.readonly {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                color: rgba(0, 0, 0, 0.2);
                cursor: not-allowed;
            }
        }
    }
}
</style>
