import Grid from './grid/js/grid';
import Gikam from '../core/gikam-core';
import jQuery from 'jquery';

let defaultOptions = {
    checkbox: true,
    // 是否级联勾选
    cascadeCheck: true
};

export default class TreeGrid extends Grid {
    constructor(options) {
        super(options);
        if (Gikam.isEmpty(this.options.checkbox)) {
            this.options.checkbox = defaultOptions.checkbox;
        }

        if (Gikam.isEmpty(this.options.cascadeCheck)) {
            this.options.cascadeCheck = defaultOptions.cascadeCheck;
        }
        //存储第一层的id == 用于切换整个data的展开状态
        this.levelOneIds = [];
        //存储节点对应的children,用于切换
        this.relationshipMap = new Map();
        this.options.data = this.formatterTreeData(this.options.data, null, 0);
    }

    getListeners() {
        super.getListeners();
        Gikam.extend(this.listeners, {
            nodeSelected: Gikam.emptyFunction
        });
    }

    refreshByData(data) {
        let def = jQuery.Deferred();
        const rows = this.trigger('beforeLoad', data.rows) || data.rows;
        this.options.data = this.formatterTreeData(rows, null);
        const _total = data.total || this.options.data.length;
        this.options.totalPage = Math.ceil(_total / this.options.pageSize) || 1;
        this.options.totalRecord = _total;
        this.store.commit('updateTotalRecord', _total);
        this.model.$children[0].clean();
        this.model.$nextTick(() => {
            def.resolve();
            this.trigger('loadSuccess', rows);
        });
        return def;
    }

    formatterTreeData(data, result) {
        if (Gikam.isEmpty(result)) {
            result = [];
        }
        let _this = this;
        data.forEach(item => {
            item.expend = item.expend || true;
            item.show = item.show || true;
            result.push(item);
            if (item.lvl === 1) {
                this.levelOneIds.push(item.id);
            }
            if (Gikam.isNotEmpty(item.children)) {
                _this.relationshipMap.set(item.id, item.children);
                _this.formatterTreeData(item.children, result);
            }
        });
        return result;
    }

    /**
     * @description 通过id展开或关闭子节点
     * @public
     * @param { String } nodeId 节点id
     * @param { boolean } expend  子节点状态 true/false
     * @memberof TreeGrid
     */
    changeChildren(nodeId, expend) {
        let children = this.getNodeChildren(nodeId);
        Gikam.each(children, (index, item) => {
            item.show = expend;
            item.expend = expend;
            if (Gikam.isNotEmpty(item.children)) {
                this.changeChildren(item.id, expend);
            }
        });
        this.reRenderDataFromId(nodeId, children);
    }

    /**
     * @description 通过id展开或关闭子节点
     * @public
     * @param { boolean } expend  子节点状态 true/false
     * @memberof TreeGrid
     */
    changeAllNodes(expend) {
        Gikam.each(this.options.data, (index, item) => {
            if (this.levelOneIds.indexOf(item.id) !== -1) {
                item.expend = expend;
                this.reRenderDataFromId(item.id, item.children);
                this.changeChildren(item.id, expend);
            }
        });
    }

    /**
     * @description 冲缓存map里获取节点对应的children
     * @private
     * @param { String } nodeId 节点id
     * @returns { Array } 返回节点的children
     * @memberof TreeGrid
     */
    getNodeChildren(nodeId) {
        return this.relationshipMap.get(nodeId);
    }

    /**
     * @description 响应式修改options.data， 暂不支持外部调用修改data
     * @private
     * @param { String } id 节点id
     * @param { children } children
     * @memberof TreeGrid
     */
    reRenderDataFromId(id, children) {
        const index = this.getIndexNode(id);
        let row = this.options.data[index];
        row.children = children;
        for (let i = 0; i < row.children.length; i++) {
            for (let j = this.options.data.length - 1; j > 0; j--) {
                if (row.children[i].id === this.options.data[j].id) {
                    this.options.data[j].expend = row.children[i].expend;
                    this.options.data[j].show = row.children[i].show;
                }
            }
        }
        this.model.$set(this.options.data, index, row);
    }

    /**
     * @description 通过id 所在行的 下标
     * @private
     * @param { String } id 节点id
     * @returns { Number } 节点下标
     * @memberof TreeGrid
     */
    getIndexNode(id) {
        let dataArr = this.options.data;
        for (let i = 0; i < dataArr.length; i++) {
            if (dataArr[i].id === id) {
                return i;
            }
        }
    }

    getSelectedNode() {
        return this.model.$refs.vm.getCheckedNodeRow();
    }

    getSelections() {
        if (this.options.checkbox) {
            return this.getSelectedNode();
        } else {
            return super.getSelections();
        }
    }

    getCheckedRowIndex(index) {
        return this.model.$refs.vm.$refs['node_' + index][0].getCheckedNodesIndex();
    }

    /**
     * @description 通过id选中或取消选中节点
     * @public
     * @param { String } nodeId 节点id
     * @param { boolean } checked  节点选中状态 true/false
     * @memberof TreeGrid
     */
    unCheckNodeById(nodeId, checked) {
        let children = this.getNodeChildren(nodeId);
        if (defaultOptions.cascadeCheck) {
            Gikam.each(children, (index, item) => {
                item.checked = checked;
                if (Gikam.isNotEmpty(item.children)) {
                    this.unCheckNodeById(item.id, checked);
                }
            });
        }
        const index = this.getIndexNode(nodeId);
        let row = this.options.data[index];
        if (children && defaultOptions.cascadeCheck) {
            row.children = children;
        } else {
            row.checked = checked;
        }
        this.model.$set(this.options.data, index, row);
    }

    /**
     * @description 获取
     * @memberof TreeGrid
     * @public
     */
    getCheckedNode() {
        return this.store.state.checkedTreeNodes.map(item => Gikam.deepExtend(item.row));
    }
}
