<template>
    <div class="alert" @keydown.enter="keydownHandle">
        <div class="alert-container">
            <div class="alert-error-icon">
                <tipImg width="20px" height="20px" />
            </div>
            <div class="title">{{ options.title }}</div>
            <section v-html="$xss(options.message)"></section>
            <footer>
                <div class="confirm-btn" @click="closeHandle">{{ confirmText }}</div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: Object
    },

    data() {
        return {
            confirmText: this.options.confirmText,
            time: this.options.time
        };
    },

    methods: {
        closeHandle() {
            // 清理时间时间
            clearTimeout(this.timeHandle);
            this.timeHandle = null;

            this.$emit('click');
            this.options.onClick && this.options.onClick();
        },

        keydownHandle() {
            this.closeHandle();
        },

        // 倒计时
        setTimeOutHandle() {
            this.timeHandle = setTimeout(() => {
                this.time -= 1;
                this.confirmText = this.options.confirmText + `(${this.time}s)`;
                if (this.time > 0) {
                    this.setTimeOutHandle();
                } else {
                    this.closeHandle();
                }
            }, 1000);
        }
    },

    mounted() {
        if (this.options.countdown) {
            this.confirmText = this.options.confirmText + `(${this.time}s)`;
            this.setTimeOutHandle();
        }
    }
};
</script>

<style scoped>
.alert {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Microsoft YaHei';
    z-index: 10;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

.alert > .alert-container {
    width: 408px;
    height: 204px;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}

.alert > .alert-container > .alert-close-btn {
    color: #444;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert > .alert-container > .alert-error-icon {
    position: absolute;
    left: 16px;
    top: 38px;
}

.title {
    position: absolute;
    left: 48px;
    top: 40px;
    width: 320px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

section {
    position: absolute;
    left: 50px;
    right: 24px;
    top: 74px;
    bottom: 60px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    overflow: auto;
}

footer {
    position: absolute;
    height: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    align-items: center;
}

.confirm-btn {
    border-radius: 4px;
    height: 28px;
    padding: 0 16px;
    margin-left: 20px;
    background-color: #007aff;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: center;
    align-items: center;
}
</style>
