import { render, staticRenderFns } from "./gridFooter.vue?vue&type=template&id=8a352558&scoped=true&"
import script from "./gridFooter.vue?vue&type=script&lang=js&"
export * from "./gridFooter.vue?vue&type=script&lang=js&"
import style0 from "./gridFooter.vue?vue&type=style&index=0&id=8a352558&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a352558",
  null
  
)

export default component.exports