/* eslint-disable no-console */
import Gikam from './gikam-core.js';
import jQuery from 'jquery';

let cache = {};
let config = {};

export default {
    /*配置参数格式如下
     * [{
     *      category : '',
     *      firstBlank: '',
     *      url : '',
     *      valueField : '',
     *      textField : ''
     * }]
     * */

    addConfigParam: function(param) {
        param.forEach(function(item) {
            config[item.category] = item;
        });
    },

    getConfigParam: function(category) {
        if (Gikam.isEmpty(category)) return;
        return config[category];
    },

    removeCache: function(code) {
        delete cache[code];
    },

    getRequestUrl: function(option) {
        let config = this.getConfigParam(option.category);
        if (!config) throw console.error('do not find select config by ' + option.category);
        const url = Gikam.IFM_CONTEXT + config.url.replace(location.origin, '');
        return url + (option.param ? '?' + jQuery.param(option.param) : '');
    },

    getItems: function(option) {
        if (!option.category) return;
        return cache[this.getRequestUrl(option)];
    },

    cacheSelectItems: function(array) {
        let def = jQuery.Deferred();
        let _this = this;
        let sendRequest = function() {
            let option = array[0];
            let config = _this.getConfigParam(option.category);
            Gikam.getJson(_this.getRequestUrl(option)).done(function(dataList) {
                let items = dataList.map(item => {
                    Object.assign(item, {
                        value: Gikam.getFieldValue(item, config.valueField),
                        text: Gikam.getFieldValue(item, config.textField)
                    });
                    return item;
                });
                //第一个位置增加空白选项
                if (option.firstBlank !== false) {
                    items.unshift({
                        text: '',
                        value: ''
                    });
                }
                cache[_this.getRequestUrl(option)] = items;
                array.splice(0, 1);
                array.length > 0 ? sendRequest() : def.resolve();
            });
        };

        sendRequest();
        return def;
    },

    addOptions(select) {
        let _this = this;
        select.forEach(item => {
            item.items = _this.getItems(item);
        });
    },

    cacheItems: function(codeArray) {
        let _this = this;
        let def = jQuery.Deferred();

        const needRequestArray = codeArray.filter(function(option) {
            if (!option.category) return false;
            const config = _this.getConfigParam(option.category);
            const cacheable = Gikam.isEmpty(option.cacheable) ? config?.cacheable === '1' : option.cacheable;
            return Gikam.isEmpty(cache[_this.getRequestUrl(option)]) || cacheable === false;
        });

        if (Gikam.isEmpty(needRequestArray)) {
            _this.addOptions(codeArray);
            return def.resolve();
        }

        this.cacheSelectItems(needRequestArray).done(function() {
            _this.addOptions(codeArray);
            def.resolve();
        });

        return def;
    },

    /**
     *
     * @description 清除select列表缓存
     * @public
     * @param {String} category
     * @param {Object} param
     * @returns
     */
    clean(category, param) {
        if (!category) {
            return;
        }
        delete cache[this.getRequestUrl({ category, param })];
    }
};
