<template>
    <div class="form" :id="options.id" :class="getClass()" :style="options.style" v-show="!hiddenGrid">
        <div
            class="panel"
            v-for="(panel, index) in options.panels"
            :key="index"
            :id="panel.id"
            :class="{ 'no-title': !panel.title }"
            v-show="panel.hidden !== true"
        >
            <header v-if="panel.title">{{ propI18N(panel.title) }}</header>
            <sunway-caption v-if="options.caption.text" :options="options.caption"></sunway-caption>
            <sunway-caption v-if="panel.caption && panel.caption.text" :options="panel.caption"></sunway-caption>
            <section :class="{ floatMode: options.mode === 'surround' || panel.mode === 'surround' }">
                <div
                    class="form-group"
                    v-for="field in panel.fields"
                    :style="{
                        width: field.width
                            ? field.width + 'px'
                            : (1 / options.columns) * (field.colspan || 1) * 100 + '%'
                    }"
                    :key="field"
                    :ref="'group_' + field.field"
                >
                    <template v-if="field.field">
                        <div
                            class="title"
                            :title="formatTitleTip(field)"
                            :style="{
                                width: options.titleWidth + 'px',
                                justifyContent: options.titleAlign === 'left' ? 'flex-start' : null
                            }"
                        >
                            <span
                                class="text"
                                :class="field | getLabelClass"
                                :style="
                                    field.titleStyleFormatter
                                        ? field.titleStyleFormatter(field.field, options.data)
                                        : {}
                                "
                            >
                                {{ propI18N(field.title) }}
                            </span>
                        </div>
                        <div
                            class="tip"
                            v-if="field.tip"
                            @mouseenter.stop="hoverTip($event, field)"
                            @mouseleave.stop="leaveTip"
                        >
                            <help-exclamation-img></help-exclamation-img>
                        </div>
                        <span class="colon" v-if="field.title">：</span>
                        <div class="field" v-if="field.field">
                            <editor :options="field" :formData="options.data" />
                        </div>
                        <div class="field" v-else></div>
                    </template>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';

function getPropValue(formData, fieldOptions) {
    const value = Gikam.getFieldValue(formData, fieldOptions.field);
    if (Gikam.isEmpty(value, true)) {
        if (Gikam.isNotEmpty(fieldOptions.value)) {
            return fieldOptions.value;
        }
    }
    return value;
}

function getFieldWidth(fieldOptions) {
    if (fieldOptions.inputWidth) {
        return 'width : ' + (typeof fieldOptions.inputWidth === 'string' ? fieldOptions.inputWidth : fieldOptions.inputWidth + 'px');
    } else {
        return '';
    }
}

export default {
    props: {
        options: Object
    },

    filters: {
        getLabelClass(field) {
            const isNotEmpty = field.validators && field.validators.indexOf('notEmpty') >= 0 ? true : false;
            if (isNotEmpty && field.titleHighlightNotEmpty) {
                return 'titleHighlight';
            } else if (isNotEmpty) {
                return 'hasRedStar';
            } else {
                return '';
            }
        }
    },

    components: {
        editor: {
            functional: true,
            props: {
                options: Object,
                formData: Object
            },

            inject: ['form'],

            render: (h, context) => {
                const options = context.props.options;
                let tag = (options.type || 'text') + 'Field';
                if (options.type === 'checkbox' && Gikam.isNotEmpty(options.items)) {
                    tag = 'frontConfigurationCheckboxField';
                }
                return (
                    <tag
                        class="field-container"
                        options={context.props.options}
                        validateArg={context.props.formData}
                        dataId={context.props.formData.id}
                        propValue={getPropValue(context.props.formData, options)}
                        ref={options.field}
                        style={getFieldWidth(options)}
                        onChange={(...args) => {
                            Gikam.setFieldValue(context.props.formData, args[0], args[1]);
                            if (options.onChange && context.injections.form.triggerChangeFlag === true) {
                                options.onChange.apply(context.injections.form, args);
                            }
                            if (context.injections.form.canAutoSave) {
                                const data = { id: context.props.formData.id };
                                data[args[0]] = args[1];
                                context.injections.form.autoSave(data);
                            }
                            if (context.injections.form.triggerChangeFlag === true) {
                                context.injections.form.filedChangeHandle({
                                    field: args[0],
                                    value: args[1]
                                });
                            }
                        }}
                        onEnterKeyDown={(...args) => {
                            Gikam.setFieldValue(context.props.formData, args[1], args[0]);
                        }}
                        onAfterChoose={(...args) => {
                            if (!options.onAfterChoose) {
                                return;
                            }
                            options.onAfterChoose.apply(context.injections.form, args);
                        }}
                        onFocus={(...args) => {
                            Gikam.trigger(options, 'onFocus', context.injections.form, args);
                        }}
                        onClick={(...args) => {
                            Gikam.trigger(options, 'onClick', context.injections.form, args);
                        }}
                        onEditing={state => {
                            context.injections.form.editorType = state ? options.type || 'text' : false;
                            context.injections.form.editorId = context.props.formData.id;
                            if (state) {
                                const wheel = document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
                                Gikam.simulatedEvent(document, wheel);
                                Gikam.simulatedEvent(document, 'click');
                            }
                        }}
                        onRendered={(...args) => {
                            if (!options.onRendered) {
                                return;
                            }
                            Gikam.finalDelay(
                                'formSelectRendered' + options.field,
                                () => {
                                    options.onRendered.apply(context.injections.form, args);
                                },
                                41
                            );
                        }}
                    ></tag>
                );
            }
        }
    },

    data() {
        return {
            rows: [],
            hiddenGrid: this.options.hidden,
            tipPanelTimer: null,
            cleanTtipPanelTimer: null,
            tipPanel: null
        };
    },

    methods: {
        changeHiddenGridState(arg) {
            this.hiddenGrid = arg;
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        getData() {
            this.$children.forEach(item => {
                if (item.field && Gikam.isNotEmpty(item.value)) {
                    Gikam.setFieldValue(this.options.data, item.field, item.value);
                }
            });
            return Gikam.deepExtend(this.options.data);
        },

        getClass() {
            return {
                'title-width-auto': this.options.titleWidthAuto,
                border: this.options.border,
                margin: this.options.margin
            };
        },

        formatTitleTip(item) {
            const title = item.titleTip ? item.titleTip : item.title;
            return this.propI18N(title);
        },

        hoverTip(e, field) {
            this.tipPanelTimer = setTimeout(() => {
                this.showTipPanels(e, field);
            }, 500);
        },

        showTipPanels(event, { tip }) {
            const hoverPanel = () => {
                this.cleanTtipPanelTimer && clearTimeout(this.cleanTtipPanelTimer);
            };
            this.tipPanel = new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    tipPanel: () => import('../components/tipPanel.vue')
                },
                methods: {
                    close() {
                        this.$refs.vm.close();
                    },
                    hoverPanel: hoverPanel
                },
                render() {
                    return (
                        <tip-panel
                            ref="vm"
                            targetDom={event.target}
                            content={tip}
                            onleavePanel={this.close}
                            onhoverPanel={this.hoverPanel}
                        ></tip-panel>
                    );
                }
            });
        },

        leaveTip() {
            this.tipPanelTimer && clearTimeout(this.tipPanelTimer);
            if (this.tipPanel) {
                this.cleanTtipPanelTimer = setTimeout(() => {
                    this.tipPanel.close();
                    this.tipPanel = null;
                }, 500);
            }
        }
    }
};
</script>

<style scoped>
.form {
    background-color: white;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    padding-top: 10px;
}

.form.border {
    border: 1px solid #eee;
}

.form.border > .panel {
    border-left: none;
    border-right: none;
}

.form.border > .panel:last-child {
    border-bottom: none;
}

.form.margin {
    margin: 8px;
}

.panel {
    border: 1px solid #eee;
    padding-top: 20px;
    position: relative;
    padding-right: 10px;
    margin-bottom: 10px;
}
.panel:last-child {
    margin-bottom: 0;
}

.panel.no-title {
    border: none;
    padding-top: 0;
}

.panel header {
    display: inline-block;
    position: absolute;
    left: 16px;
    top: -10px;
    padding: 0 8px;
    background-color: #fff;
    font-size: 12px;
    color: black;
    font-weight: bold;
}

.panel section {
    display: flex;
    flex-wrap: wrap;
}

.panel > section > .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.panel > section.floatMode {
    zoom: 1;
    display: block;
}

.panel > section::after {
    content: '';
    height: 0;
    line-height: 0;
    display: block;
    visibility: hidden;
    clear: both;
}
.panel > section.floatMode > .form-group {
    float: left;
}

.form-group .title {
    color: rgba(0, 0, 0, 0.85);
    width: 100px;
    display: flex;
    height: 30px;
    position: relative;
    align-items: center;
    justify-content: flex-end;
}

.form .form-group .title .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form .form-group .title .text.titleHighlight,
.form .form-group .title .text.hasRedStar {
    position: relative;
    padding-right: 8px;
}

.form .form-group .title .text.titleHighlight {
    color: #ff3b30;
}

.form .form-group .title .text.titleHighlight::before,
.form .form-group .title .text.hasRedStar::before {
    content: '*';
    color: #ff3b30;
    position: absolute;
    right: 0;
    top: -3px;
}

.form.title-width-auto .form-group .title {
    width: auto;
    padding-left: 8px;
}

.form .form-group .tip {
    height: 12px;
    margin: 0 2px;
}

.form .form-group .colon {
    color: rgba(0, 0, 0, 0.85);
}

.form .form-group .field {
    flex: 1;
    align-items: center;
    justify-content: center;
}

.form .form-group .title,
.form .form-group .field {
    height: 100%;
    overflow: hidden;
}

.field * {
    min-height: 30px;
}

/* 解决IE下min-height不被子元素继承的问题 */
.field >>> .field-container > div:not(.checkbox),
.field >>> .field-container > input {
    min-height: 30px;
}

.form >>> .sign .container.readonly {
    background-color: #f4f4f4;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 40px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.panel .field >>> .readonly:not(.textarea) .readonly-text {
    display: block;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.panel .field >>> .readonly .link-text {
    display: block;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form > .panel > .caption-container {
    padding-left: 16px;
    padding-bottom: 8px;
}
</style>
