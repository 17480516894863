import Gikam from '../../../core/gikam-core.js';
import grid from '../vue/grid.vue';
import Base from '../../base.js';
import Vue from 'vue';
import { GridStore } from './gridStore';
import GridExport from './gridExport';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N.js';
import { GridColumn } from './gridColumn';
import { getColumnsGroupMap, getColumnGroupRelationship } from './utils';

import handleColumns from './columns.js';

let defaultOptions = {
    id: void 0,
    columns: [],
    url: void 0,
    customSearchData: void 0,
    width: null,
    fieldChangeFlag: true,
    customContent: void 0,
    // 定义工具栏内容，其子组件可为form，button
    toolbar: [],
    // 工具栏中按钮的对齐方式
    toolbarAlign: 'right',
    // 是否可以拖动单元格宽度
    columnResize: true,
    // 请求参数
    requestData: {},
    // 默认排序字段
    order: {},
    // 是否显示分页
    page: true,
    // 当前第几页
    pageNum: 1,
    // 每页显示数量
    pageSize: 50,
    // 显示数据
    data: [],
    // 高度
    height: 400,
    // 总页数
    totalPage: void 0,
    // 数据总数
    totalRecord: 0,
    // 是否显示整个表格
    showBody: true,
    // 是否自动保存
    autoSave: true,
    // 分页中，可选择的每页显示的数量
    pageList: null,
    readonlyFields: {},
    // 删除时，提示内容对应的格式化方法
    deleteFormatter: rows => rows.id,
    // 启用时，提示内容对应的格式化方法
    activateFormatter: rows => rows.id,
    // 是否显示通用查询
    genericQuery: false,
    genericQueryFields: [],
    // 激活行时是否勾选复选框
    checkOnActive: true,
    // 初始化时是否激活字段的change事件
    initTriggerChange: true,
    // 激活行时是否取消其他行的选中
    checkOneOnActive: false,
    // 分组字段
    group: {
        groupCheckbox: true,
        fields: [],
        formatter: Gikam.emptyFunction,
        //分组节点是否展开
        expand: true
    },
    //表格列合并
    columnGroup: null,
    // 组件宽高是否填充满父元素
    fill: false,
    // 快捷查询是否展开
    filterOpen: false,
    // 是否开启快捷查询功能
    filter: true,
    // 自定义功能组['refresh', 'import', 'export'],刷新、导入、导出
    generalButtonGroup: [],
    // 允许的最大宽度，由父组件传值
    allowWidth: null,
    // 初始化时是否请求数据
    initRequest: true,
    // 编辑器是否可见，为true时，编辑器对应的表头颜色为蓝色，双击编辑器才可对其进行编辑
    editorInvisible: false,
    // 控制单元格文本内容过长是否换行，
    textLineFeed: false,
    // 控制勾选数量角标是否显示
    showCheckedNum: true,
    // 控制列是否充满整个grid(默认true)
    columnsFill: true,
    // 每列的默认宽度（默认为120）
    defaultColumnWidth: 120,
    // 鼠标右击时的菜单选项,例如:[{text:'编辑',icon:'edit',type:'button',onClick:function(){}}]
    contextmenu: [],
    // 需要合计的列以及显示合计要合并的列
    sum: {
        fields: [],
        beginIndex: 0,
        endIndex: 1,
        fixed: false
    },
    // 是否前台筛选
    serverSearch: true,
    // 只读内容对齐状态
    contentAlign: 'left',
    // 实时保存路径
    instantSavePath: void 0,
    // 记录上次设置为只读的索引
    preEditingIndex: void 0,
    // 数据刷新成功后是否激活第一行
    activateFirstRow: false,
    // 表头是否可以选中列
    columnsSelect: false,
    // 隐藏当前grid
    hidden: false,
    // 表头快捷查询数据
    headRequestData: {},
    // 勾选复选框时是否激活行
    activeOnCheck: false,
    // 删除、激活、注销等对列表的操作时，对哪个字段的值进行提示
    confirmField: null,
    // 冻结行，[0, 1，2]代表冻结前3行,暂时支持固定最后合计行
    rowFixed: [],
    firstRowFixed: false,
    // 控制表格内滚动条是否一直显示可选'visible' 和'auto'(默认)
    scroll: 'auto',
    // 初始化时的列 是否先从缓存中去取
    useStorageColumns: true,
    // 表格初始化时是否显示或隐藏顶部工具栏
    toolbarHidden: true,
    // 导出表格数据时，指定下载文件名称
    name: null,
    // 快捷查询输入值后是否立即触发
    filterImmediate: false,
    // 是否显示表格列排序图标
    showSortArrow: false,
    // 分组勾选
    onGroupSelect: null,
    // 分组取消勾选
    onGroupUnSelect: null
};

export default class Grid extends Base {
    constructor(options) {
        super('Grid');
        this.$lastChild = true;
        this.getListeners();
        this.canAutoSave = true;
        this.lastRequestData = null;
        this.$changeFields = {};
        this.validators = {};
        //用于存储选择隐藏的列
        this.hideColumnArray = [];
        //用于检测是否编辑的字段变量
        this.checkEditorFields = [];
        //保存计时器，方便清除
        this.timer = null;
        //移动端改变列宽的遮罩
        this.gridMask = null;
        //记录校验未通过的字段
        this.errorFields = [];
        //原生的列
        this.$rawColumns = [];
        this.initialize(options, defaultOptions);
        this.$initWindowParam(options);
        this.init();
    }

    /**
     * @description 初始化从window组件传递的参数
     * @private
     * @memberof Grid
     */
    $initWindowParam(options) {
        if (this.$window && this.$window.$param && this.$storeCompIndex === 0) {
            options.requestData = Gikam.extend({}, this.$window.$param, options.requestData);
        }
    }

    getListeners() {
        this.listeners = {
            beforeRefresh: Gikam.emptyFunction,
            beforeLoad: Gikam.emptyFunction,
            cellClick: Gikam.emptyFunction,
            rendered: Gikam.emptyFunction,
            rowActive: Gikam.emptyFunction,
            columnActive: Gikam.emptyFunction,
            select: Gikam.emptyFunction,
            unSelect: Gikam.emptyFunction,
            allSelect: Gikam.emptyFunction,
            unAllSelect: Gikam.emptyFunction,
            checkboxClick: Gikam.emptyFunction,
            afterChoose: Gikam.emptyFunction,
            loadSuccess: Gikam.emptyFunction,
            updated: Gikam.emptyFunction,
            beforeDelete: json => json,
            beforeActivate: json => json,
            beforeSuspend: json => json,
            beforeUpdate: (data, keys) => [data, keys],
            cellDbClick: Gikam.emptyFunction,
            validating: Gikam.emptyFunction,
            columnsWidthChange: Gikam.emptyFunction,
            loadFail: Gikam.emptyFunction
        };
    }

    createModel() {
        let _this = this;
        let container = Gikam.createDom('div', this.options.renderTo);
        if (Gikam.isNotEmpty(this.options.data)) {
            this.initRowGroup(this.options.data);
        }
        this.cacheSelectItems(this.options.columns).done(() => {
            this.$rawColumns = Gikam.deepExtend(this.options.columns);
            this.store = new GridStore(this.options, this).store;
            handleColumns(this.store, this.store.state.columns);

            this.initColumnsGroupRelationship(this.options.columnGroup);
            this.initColumnsGroupMap();

            this.model = new Vue({
                el: container,
                store: this.store,
                render() {
                    return <grid ref="vm" options={this.options} onAllCheck={this.allCheckHandle}></grid>;
                },
                provide: {
                    grid: _this
                },
                components: {
                    grid
                },
                data() {
                    return {
                        options: _this.options
                    };
                },
                methods: {
                    allCheckHandle(checked) {
                        if (checked) {
                            _this.trigger('allSelect', _this.getSelections);
                        } else {
                            _this.trigger('unAllSelect', _this.getSelections);
                        }
                    }
                },
                beforeDestroy() {
                    this.$el.removeEventListener('click');
                },
                mounted() {
                    this.$nextTick(() => {
                        this.$el.addEventListener('click', _this.clickGrid.bind(_this), true);
                    });
                }
            });
            this.caseSelectValue();
            let notHasForm =
                this.options.toolbar.filter(item => {
                    return item.type === 'form';
                }).length === 0;
            if (notHasForm) {
                this.refresh();
            }
            this.bindInstance(_this.model.$el);
            this.trigger('rendered');
            this.model.$nextTick(() => {
                this.notify();
                this.onResize();
            });
        });
    }

    caseSelectValue() {
        const hasItemsData = {};
        this.options.columns
            .filter(function(item) {
                return (
                    ['Select', 'SelectInput', 'ComboBox', 'InsertableSelect'].indexOf(
                        Gikam.toInitialUpperCase(item.type || '')
                    ) > -1 &&
                    item.items &&
                    Gikam.isNotEmpty(item.selectedIndex)
                );
            })
            .forEach(item => {
                item.items[item.selectedIndex] && (hasItemsData[item.field] = item.items[item.selectedIndex].value);
            });

        this.selectIndexValue = hasItemsData;
    }

    clickGrid() {
        if (Gikam.isTrue(this._ignoreClickEvent)) {
            return;
        }
        if (top.Gikam.activeGrid === this) {
            return;
        } else {
            top.Gikam.activeGrid = this;
        }
    }

    setOptions(option) {
        Gikam.extend(true, this.options, option);
    }

    getOptions() {
        return Gikam.deepExtend(this.options);
    }

    //与上次查询条件进行比对，条件是否发生变化
    compareRequestData(requestData, lastRequestData) {
        if (this.lastUrl && this.lastUrl !== this.options.url) {
            this.lastUrl = this.options.url;
            return false;
        }
        this.lastUrl = this.options.url;

        if (!lastRequestData) {
            return true;
        }

        if (requestData.s !== lastRequestData.s) {
            return false;
        }

        let result = true;

        Gikam.each(requestData.f, function(key) {
            if (typeof this === 'object') {
                return true;
            }
            if (this !== lastRequestData.f[key]) {
                result = false;
                return false;
            }
        });

        Gikam.each(lastRequestData.f, function(key) {
            if (typeof this === 'object') {
                return true;
            }
            if (this !== requestData.f[key]) {
                result = false;
                return false;
            }
        });

        return result;
    }

    // eslint-disable-next-line complexity
    queryParams(options, pageNum, compare = true) {
        const state = this.store.state;
        this.removeEmptyRequestData();
        const requestData = {
            f: Gikam.extend(true, {}, this.options.requestData, this.options.customSearchData, options),
            n:
                state.pageSize === 'grid.all.data'
                    ? -1
                    : Gikam.isNotEmpty(pageNum)
                    ? pageNum
                    : this.options.page
                    ? state.pageNum
                    : -1,
            s: state.pageSize === 'grid.all.data' ? null : state.pageSize,
            qf: Gikam.deepExtend(this.options.headRequestData)
        };
        if (Gikam.isNotEmpty(this.options.sum.fields)) {
            requestData.tf = this.options.sum.fields.join(',');
        }
        if (!Gikam.isEmptyObject(this.options.order)) {
            const orderArray = [];
            for (let key in this.options.order) {
                let order = {};
                order[key] = this.options.order[key];
                orderArray.push(order);
            }
            requestData.o = orderArray;
        }
        //条件发生变化，将页面重置为第一页
        //如果当前是第二页，条件发生变革回到第一页开始
        if (compare === true) {
            if (this.compareRequestData(requestData, this.lastRequestData) === false && requestData.n !== -1) {
                requestData.n = 1;
                this.options.pageNum = 1;
            }
            this.lastRequestData = requestData;
        }

        return Gikam.getJsonWrapper(requestData);
    }

    /**
     * @description
     * @memberof Grid
     */
    removeEmptyRequestData() {
        const _this = this;
        if (this.options.requestData) {
            Gikam.each(this.options.requestData, function(key, value) {
                if (Gikam.isEmpty(value)) {
                    delete _this.options.requestData[key];
                }
            });
        }
        if (this.options.headRequestData) {
            Gikam.each(this.options.headRequestData, function(key, value) {
                if (Gikam.isEmpty(value)) {
                    delete _this.options.headRequestData[key];
                }
            });
        }
    }

    initColumnsGroupRelationship(columnGroup) {
        if (columnGroup) {
            const relationship = getColumnGroupRelationship(columnGroup);
            this.store.commit('columnsGroup/changeRelationship', relationship);
        }
    }

    initColumnsGroupMap(columns, data) {
        if (Object.keys(this.store.state.columnsGroup.relationship).length) {
            const columnsGroupMap = getColumnsGroupMap(
                columns || this.store.state.columns,
                data || this.options.data,
                this.store.state.columnsGroup.relationship
            );
            this.store.commit('columnsGroup/changeGroupMap', columnsGroupMap);
        }
    }

    refreshByData(data, editorCell) {
        this.$window && this.$window.changeSaveButtonPrompt(false);
        this.$initPageSearch(data);
        this._setCurrentEditor(null);
        if (this.options.initTriggerChange === false) {
            this.options.fieldChangeFlag = false;
        }
        const def = jQuery.Deferred();
        const _this = this;
        _this.backData = data;
        this.canAutoSave = false;
        const rows = this.trigger('beforeLoad', data.rows) || data.rows;

        data.columnTypeMap && this.store.commit('refreshTypeMapper', data.columnTypeMap);
        const _total = _this.backData.total || rows.length;
        this.store.commit('updateTotalRecord', _total);

        rows.forEach((item, index) => {
            item.gridIndex = index + 1;
        });

        this.initRowGroup(rows);
        if (rows) {
            this.options.data = rows.map(item => {
                return Gikam.deepExtend(this.selectIndexValue, item);
            });

            this.initColumnsGroupMap();
        }
        this.model.$children[0].clean();
        this.model.$nextTick(() => {
            _this.canAutoSave = true;
            def.resolve();
            _this.trigger('loadSuccess', data.rows);
            _this.options.fieldChangeFlag = true;
            if (this.options.activateFirstRow) {
                this.activeRowByIndex(0);
            }
            editorCell && this.focusOldCell();
        });
        // 记录初始数据，用于通知页面通用保存按钮
        this.initData = Gikam.deepExtend(data.rows);
        return def;
    }

    focusOldCell() {
        if (Gikam.isEmpty(this.store.state.activeCoordinate)) {
            return;
        }
        const [rowIndex, colIndex] = this.store.state.activeCoordinate;
        const currentCell = this.model.$refs.vm.$refs[rowIndex + '_' + colIndex];
        if (
            Gikam.isEmpty(currentCell) ||
            currentCell.options.readonly === true ||
            currentCell.$options.name !== 'textInput'
        ) {
            return;
        }
        this.baseFocusField(rowIndex, colIndex, currentCell);
    }

    getGroupValue(row) {
        let rowGroupValue = null;
        this.options.group.fields.forEach(field => {
            const value = Gikam.getFieldValue(row, field);
            if (value !== undefined) {
                rowGroupValue += value;
            }
        });
        return rowGroupValue;
    }

    /**
     * @description 初始化分组数据
     * @private
     * @param {Array} rows 表格数据
     * @returns
     * @memberof Grid
     */

    initRowGroup(rows) {
        if (Gikam.isEmpty(rows)) {
            return;
        }
        this.processData(rows);
        if (Gikam.isEmpty(this.options.group.fields)) {
            return;
        }
        const _this = this;
        let currentGroupValue;
        let currentGroupIndex = -1;
        for (let i = 0; i < rows.length; i++) {
            const rowGroupValue = _this.getGroupValue(rows[i]);

            if (rowGroupValue !== currentGroupValue) {
                currentGroupIndex++;
                currentGroupValue = rowGroupValue;
                rows.splice(
                    i,
                    0,
                    Gikam.deepExtend(
                        {
                            _groupRow: true,
                            _groupIndex: currentGroupIndex,
                            _expand: Gikam.isNotEmpty(rows._expand)
                                ? rows._expand
                                : Gikam.isNotEmpty(_this.options.group.expand)
                                ? _this.options.group.expand
                                : true
                        },
                        rows[i],
                        {
                            id: rows[i].id + '_group',
                            $rowStyle: 'group'
                        }
                    )
                );
            } else {
                Gikam.extend(rows[i], {
                    _parentGroupIndex: currentGroupIndex
                });
            }
        }
    }

    /**
     * @description 对数据进行处理
     * @private
     * @param {Array} rows
     * @memberof Grid
     */
    processData(rows) {
        rows.forEach(row => {
            //如果数据中没有id，对id赋值
            if (Gikam.isEmpty(row.id)) {
                row.id = Gikam.sequence.getNextValue();
            }
        });
        return rows;
    }

    loadData(data) {
        this.refreshByData({ rows: data });
    }

    refresh(param, editorCell) {
        let _this = this;
        let def = jQuery.Deferred();
        param && this.setOptions(param);
        if (param && param.requestData) {
            this.store.commit('changeRequestData', param.requestData);
        }
        if (this.trigger('beforeRefresh', this.options.requestData) === false) {
            def.fail();
            return;
        }
        let model = this.model.$children[0];
        if (model.checkeds.length > 0) model.checkedAll = model.checkeds;
        if (this.options.url) {
            model.options.data = [];
            Gikam.post(this.options.url, this.queryParams())
                .done((r, status, xhr) => {
                    this.store.state.totalMap = r.totalMap;
                    if (r.pageNumber > 0) {
                        this.store.state.pageNum = r.pageNumber;
                    }
                    if (_this.options.initRequest === false) {
                        if (_this.options.requestData.scheme_only) {
                            delete _this.options.requestData.scheme_only;
                        }
                        _this.options.initRequest = true;
                    }
                    _this.refreshByData(r, editorCell).done(() => {
                        def.resolve(_this);
                    });
                    this.storeInstantSavePath(xhr);
                    const gridComp = this.model.$children[0];
                    gridComp.removeErrorPanel();
                    gridComp.showRowFixedBottom = false;
                    gridComp.showRowFixedTop = false;
                    const { scrollLeft, scrollTop } = this.store.state;
                    scrollLeft > 0 && this.store.commit('changeScrollLeft', scrollLeft + 2);
                    scrollTop > 0 && this.store.commit('changeScrollTop', scrollTop + 2);
                    this.model.$nextTick(() => {
                        gridComp.handleRowFixed();
                        setTimeout(() => {
                            scrollTop > 0 && this.store.commit('changeScrollTop', scrollTop);
                            scrollLeft > 0 && this.store.commit('changeScrollLeft', scrollLeft);
                        }, 300);
                    });
                })
                .fail(r => {
                    _this.model.$nextTick(() => {
                        _this.trigger('loadFail', r);
                    });
                    def.reject(r);
                });
        } else {
            _this.model.$nextTick(() => {
                _this.trigger('loadSuccess');
            });
            if (Gikam.isNotEmpty(this.options.data)) {
                this.options.data.forEach(item => {
                    for (let key in this.selectIndexValue) {
                        if (Gikam.isEmpty(item[key])) {
                            item[key] = this.selectIndexValue[key];
                        }
                    }
                });
            }
            def.resolve();
        }
        // 刷新列表的时候把字段校验没通过的信息给清空
        _this.errorFields = [];
        return def;
    }

    refreshNoFieldChange(param) {
        this.options.fieldChangeFlag = false;
        let _this = this;
        this.refresh(param).done(() => {
            _this.options.fieldChangeFlag = true;
        });
    }

    getSelections() {
        //去除分组的行数据
        return this.model.$children[0].getSelections().filter(row => !row._groupRow);
    }

    getData() {
        let rows = [];
        jQuery.each(this.options.data, function() {
            let row = { ext$: {} };
            Gikam.each(this, (key, value) => {
                if (key.indexOf('ext$.') === 0) {
                    row.ext$[key.replace('ext$.', '')] = value;
                } else {
                    row[key] = value;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    /**
     * @description 缓存所有下拉框选项值
     * @private
     * @param {Array} 需要筛选的字段配置参数
     * @returns
     * @memberof Grid
     */
    cacheSelectItems(columns) {
        let filterType = ['Select', 'SelectInput', 'ComboBox', 'InsertableSelect'];
        let codeArray = columns.filter(function(item) {
            return filterType.indexOf(Gikam.toInitialUpperCase(item.type || '')) > -1 && Gikam.isEmpty(item.items);
        });
        if (Gikam.isEmpty(codeArray)) {
            return jQuery.Deferred().resolve();
        }
        return Gikam.select.cacheItems(codeArray);
    }

    refreshRowByIndex(index) {
        const _this = this;
        //查询数据时，如果字段被清空，那么后台返回的字段值就不存在，
        //此时要添加这种字段为空
        const formatterData = data => {
            _this.options.columns
                .filter(item => item.field)
                .forEach(function(item) {
                    if (Gikam.isEmpty(Gikam.getFieldValue(data, item.field))) {
                        Gikam.setFieldValue(data, item.field, '');
                    }
                });
            return data;
        };

        Gikam.post(
            this.options.url,
            this.queryParams(
                {
                    id_EQ: this.getData()[index].id
                },
                1,
                false
            )
        ).done(data => {
            if (data.rows.length === 0) {
                _this.model.$children[0].refreshCheck(index);
                const _data = Gikam.deepExtend(_this.options.data);
                let noSibling = true;
                if (Gikam.isNotEmpty(_this.options.group.fields)) {
                    const currentGroupValue = _this.getGroupValue(_data[index]);
                    const previousGroupValue = _data[index - 1]._groupRow
                        ? 'null'
                        : _this.getGroupValue(_data[index - 1]);
                    const nextGroupValue = _this.getGroupValue(_data[index + 1]);
                    if (currentGroupValue === previousGroupValue || currentGroupValue === nextGroupValue) {
                        noSibling = false;
                    }
                } else {
                    noSibling = false;
                }

                if (noSibling) {
                    _data.splice(index - 1, 2);
                } else {
                    _data.splice(index, 1);
                }
                _this.store.commit('updateTotalRecord', _data.length);
                _this.options.data = _data;
            } else {
                const row = formatterData(data.rows[0]);
                const oldData = _this.options.data[index];

                row.gridIndex = oldData.gridIndex;
                if (Gikam.isNotEmpty(_this.options.group.fields)) {
                    row._parentGroupIndex = oldData._parentGroupIndex;
                }
                Vue.set(_this.options.data, index, row);
            }
        });
    }

    /**
     *
     * 通过id向后台发起请求,刷新此条数据
     * @param {String,Number} id
     * @returns
     * @memberof Grid
     */
    refreshRowById(id) {
        if (Gikam.isEmpty(id)) {
            return;
        }
        let index;
        Gikam.each(this.options.data, function(i) {
            if (this.id === id) {
                index = i;
                return true;
            }
        });
        this.refreshRowByIndex(index);
    }

    /**
     * @description
     * @param {*} url
     * @param {*} selectRows
     * @returns
     * @memberof Grid
     * @public
     */
    deleteRows(url, selectRows) {
        const _this = this;
        const options = {
            url: url,
            confirm: {
                msg: 'GIKAM.TIP.CONFIRM_TO_DELETE_FOLLOWING_ITEMS', // 确认弹出框中左侧的信息
                title: 'GIKAM.TIP.CONFIRM_TO_DELETE'
            },
            formatter: function(row) {
                return _this.options.deleteFormatter(row);
            },
            // 提交前处理json数据的方法
            beforeProcess: 'beforeDelete',
            // 调用的Html请求的方法
            method: 'del',
            // 自定义删除的数据
            data: selectRows
        };

        return this.processRows(options);
    }

    activateRows(url) {
        let _this = this,
            options = {
                url: url,
                flag: {
                    name: 'activatedFlag',
                    expectedValue: '0',
                    unexpectedMsg: 'GIKAM.TIP.CHOOSE_DEACTIVATED_ITEM'
                },
                confirm: {
                    msg: 'GIKAM.TIP.WHETHER_TO_ACTIVATE_FOLLOWING_ITEMS',
                    title: 'GIKAM.TIP.CONFIRM_TO_ACTIVATE'
                },
                formatter: function(row) {
                    return _this.options.activateFormatter(row);
                },
                beforeProcess: 'beforeActivate'
            };

        return _this.processRows(options);
    }

    deactivateRows(url) {
        let _this = this;
        let options = {
            url: url,
            flag: {
                name: 'activatedFlag',
                expectedValue: '1',
                unexpectedMsg: 'GIKAM.TIP.CHOOSE_ACTIVATED_ITEM'
            },
            confirm: {
                msg: 'GIKAM.TIP.WHETHER_TO_DEACTIVATE_FOLLOWING_ITEMS',
                title: 'GIKAM.TIP.CONFIRM_TO_DEACTIVATE'
            },
            formatter: function(row) {
                return _this.options.activateFormatter(row);
            },
            beforeProcess: 'beforeActivate'
        };

        return _this.processRows(options);
    }

    suspendRows(url) {
        let _this = this,
            options = {
                url: url,
                flag: {
                    name: 'lastSuspendedFlag',
                    expectedValue: '0',
                    unexpectedMsg: 'GIKAM.TIP.CHOOSE_UNSUSPENDED_ITEM'
                },
                confirm: {
                    msg: 'GIKAM.TIP.WHETHER_TO_SUSPEND_FOLLOWING_ITEMS',
                    title: 'GIKAM.TIP.CONFIRM_TO_SUSPEND'
                },
                formatter: function(row) {
                    return _this.options.suspendFormatter(row);
                },
                beforeProcess: 'beforeSuspend'
            };

        return _this.processRows(options);
    }

    unsuspendRows(url) {
        let _this = this,
            options = {
                url: url,
                flag: {
                    name: 'lastSuspendedFlag',
                    expectedValue: '1',
                    unexpectedMsg: 'GIKAM.TIP.CHOOSE_SUSPENDED_ITEM'
                },
                confirm: {
                    msg: 'GIKAM.TIP.WHETHER_TO_UNSUSPEND_FOLLOWING_ITEMS',
                    title: 'GIKAM.TIP.CONFIRM_TO_UNSUSPEND'
                },
                formatter: function(row) {
                    return _this.options.suspendFormatter(row);
                },
                beforeProcess: 'beforeSuspend'
            };

        return _this.processRows(options);
    }

    /**
     * @description
     * @param {*} options
     * @returns
     * @memberof Grid
     * @private
     */
    getProcessRowsData(options) {
        return options.data || this.getSelections();
    }

    processRows(options) {
        let def = jQuery.Deferred();
        const selectedRows = this.getProcessRowsData(options);
        if (Gikam.isEmpty(selectedRows)) {
            Gikam.alert('GIKAM.TIP.CHOOSE_AT_LEAST_ONE_ITEM');
            return def;
        }
        if (options.onlyOne === true && selectedRows.length !== 1) {
            Gikam.alert('GIKAM.TIP.CHOOSE_ONE_ITEM');
            return def;
        }
        if (options.flag) {
            let valid = true;
            selectedRows.forEach(function(row) {
                if (row[options.flag.name] !== options.flag.expectedValue) {
                    valid = false;
                }
            });
            if (!valid) {
                Gikam.alert(options.flag.unexpectedMsg);
                return def;
            }
        }
        let _this = this;
        let selectedRowsJson = selectedRows.map(row => {
            return { id: row.id };
        });
        let selectedHtml = selectedRows.reduce(function(total, row) {
            return (total +=
                '<div style="padding-top:5px;">' + _this.getProcessConfirmFieldValue(row, options) + '</div>');
        }, '');

        let params = null;
        if (options.beforeProcess) {
            if (Gikam.isString(options.beforeProcess)) {
                params = _this.trigger(options.beforeProcess, selectedRowsJson, selectedRows);
            } else if (Gikam.isFunction(options.beforeProcess)) {
                params = options.beforeProcess(selectedRowsJson, selectedRows);
            }
        }

        Gikam.create('confirm', {
            title: Gikam.propI18N(options.confirm.title),
            subTitle: Gikam.propI18N(options.confirm.msg),
            message: selectedHtml,
            height: 250,
            onYesClick: function() {
                if (Gikam.isNotEmpty(_this.$window)) {
                    _this.$window.showMask(true);
                }
                Gikam[options.method ? options.method : 'put'](
                    options.url,
                    Gikam.getJsonWrapper(null, ['', params ? params : selectedRowsJson])
                )
                    .done(function(r) {
                        if (options.gridRefresh !== false) {
                            _this.refresh().done(() => {
                                def.resolve(r);
                            });
                        } else {
                            def.resolve(r);
                        }
                    })
                    .always(function() {
                        if (Gikam.isNotEmpty(_this.$window)) {
                            _this.$window.closeMask(true);
                        }
                    });
            }
        });

        return def;
    }

    /**
     * @description 针对列表的操作，提示的字段值
     * @memberof Grid
     * @private
     */
    getProcessConfirmFieldValue(row, options) {
        const confirmField = this.options.confirmField;
        if (confirmField) {
            const fieldOption = this.options.columns.filter(item => item.field === confirmField)[0];
            const fieldValue = Gikam.getFieldValue(row, confirmField);
            if (!fieldOption || Gikam.isEmpty(fieldValue)) {
                return '';
            }
            if (fieldOption.type === 'select') {
                const selectItem = fieldOption.items.filter(item => item.value.toString() === fieldValue.toString())[0];
                return selectItem ? selectItem.text : '';
            }
            return fieldValue;
        }
        return options.formatter ? options.formatter(row) : row.id;
    }

    /**
     * @description 表格导出excel
     * @public
     * @param {String} rows 导出的文件名称
     * @param {Boolean} rows 导出使用的url
     * @param {Boolean} rows 导出时是否选中导出列
     * @memberof Grid
     */
    exportExcel(...arg) {
        const _url = arg[1] || this.options.url;
        if (!_url) {
            return;
        }
        const params = JSON.parse(this.queryParams()).p;
        const options = Gikam.deepExtend({
            arg: arg,
            url: _url,
            grigFileName: this.options.name,
            params: params,
            store: this.store
        });
        new GridExport(options);
    }

    getActivedRow() {
        return this.model.$children[0].getActiveRow();
    }

    getActivedColumn() {
        const columns = this.store.state.columns;
        const selectIndex = this.store.state.chooseColumns.columnsSelectIndex;
        if (Gikam.isEmpty(columns) || !Gikam.isNumber(selectIndex)) {
            return '';
        }
        return columns[selectIndex] ? columns[selectIndex].field : '';
    }

    getColumns() {
        return Gikam.deepExtend(this.store.state.columns);
    }

    cleanActivedColumn() {
        this.store.commit('chooseColumns/changeColumnsSelectIndex', null);
    }

    setActivedColumn(index) {
        this.store.commit('chooseColumns/changeColumnsSelectIndex', index - 1);
    }

    async setData(param, autoSaveFlag = true, cleanRowData) {
        if (Gikam.isEmpty(param.index) && Gikam.isEmpty(param.id)) {
            Gikam.error('No index or id was found when updating data');
        }
        this.canAutoSave = false;
        let row;
        let index = param.index;
        if (Gikam.isNotEmpty(param.index)) {
            row = this.options.data[param.index];
        } else {
            Gikam.each(this.options.data, function(i) {
                if (param.id === this.id) {
                    index = i;
                    row = this;
                    return false;
                }
            });
        }
        !row && Gikam.error('No updated data was found');
        let data = Gikam.deepExtend(row, param);
        if (cleanRowData) {
            if (!param.id) {
                param.id = row.id;
            }
            data = param;
        }

        Vue.set(this.options.data, index, data);
        await this.model.$nextTick();
        this.canAutoSave = true;
        if (autoSaveFlag) {
            return this.autoSave(param);
        } else {
            return Promise.resolve();
        }
    }

    /**
     *
     * @description 校验表格中数据是否满足规则，也可以校验指定行数据
     * @public
     * @param {Array} rows 校验指定的数据
     * @returns
     * @memberof Grid
     */
    validate(rows, noModal) {
        document.body.click();
        if (this.validateExistsError(rows) === false) {
            return false;
        }
        let _this = this;
        let error = [];
        const data = Gikam.isNotEmpty(rows) ? rows : this.getData();
        Gikam.each(data, function(rowIndex) {
            if (Gikam.isNotEmpty(this._groupIndex)) {
                return true;
            }
            let rowValidateResult = true;
            let data = this;
            Gikam.each(_this.validators, function(field) {
                let errorMessage = [];
                let errorResult = true;
                Gikam.each(this, function() {
                    // 唯一性校验，在调用validate方法时不验证
                    if (['unique', 'remote'].includes(this.type)) {
                        return true;
                    }
                    let r = Gikam.validator[this.type](
                        Gikam.getFieldValue(data, field),
                        this.rule,
                        field,
                        data.id,
                        Gikam.extend(this, { data })
                    );
                    if (r.result === false) {
                        error.push({
                            field: field,
                            title: this.title,
                            message: r.message
                        });
                        errorMessage.push({
                            field: field,
                            title: this.title,
                            message: r.message
                        });
                        rowValidateResult = false;
                        errorResult = false;
                    }
                });
                let fieldNumber;
                for (let i = 0; i < _this.options.columns.length; i++) {
                    if (_this.options.columns[i].field === field) {
                        fieldNumber = i;
                    }
                }
                const childComp =
                    _this.model.$children[0].$refs[
                        (Gikam.isNotEmpty(rows) ? _this.getRowIndexById(data.id) : rowIndex) + '_' + fieldNumber
                    ];
                if (childComp) {
                    childComp.validateResult = errorResult;
                    if (Gikam.isFalse(childComp.validateResult)) {
                        childComp.error = errorMessage;
                    }
                }
            });

            const projectValidate = _this.trigger('validating', data);

            if (
                Gikam.isPlainObject(projectValidate) &&
                Gikam.isFalse(projectValidate.result) &&
                Gikam.isNotEmpty(projectValidate.message)
            ) {
                projectValidate.title = '';
                error.push(projectValidate);
                rowValidateResult = false;
            }

            if (rowValidateResult === false) {
                return false;
            }
        });

        if (Gikam.isEmpty(error)) {
            return true;
        } else {
            noModal !== true && this.showValidateMessage(error);
            return false;
        }
    }

    showValidateMessage(error) {
        let tpl =
            '<div class="validator-error-container">' +
            '<span class="error-title">' +
            Gikam.propI18N('GIKAM.TIP.INVALID_INPUT_FIELDS') +
            '</span>' +
            '<div class="error-zone">{errorMessage}</div>' +
            '</div>';
        let errorMessage = '';
        error.forEach(function(item) {
            errorMessage +=
                '<div>' +
                '<span class="error-field-desc">' +
                Gikam.propI18N(item.title) +
                '</span>' +
                Gikam.propI18N(item.message) +
                '</div>';
        });

        Gikam.alert(
            Gikam.printf(tpl, {
                errorMessage: errorMessage
            }),
            200
        );
    }

    insert(url, data, param) {
        let _this = this;
        let def = jQuery.Deferred();
        let json = Gikam.getJsonWrapper(param, data ? ['', data] : null);
        let window = Gikam.getInstance(jQuery(this.model.$el).closest('.window'));
        window.showMask(true);
        Gikam.postText(url, json)
            .done(function(id) {
                def.resolve(id);
                _this.refresh();
            })
            .always(function() {
                window.closeMask();
            });
        return def;
    }

    moveUp(url) {
        let selections = this.getSelections();
        let _this = this;
        let selectedIndex = this.model.$children[0].checkeds[0];
        let def = Gikam.getDeferred();

        if (Gikam.isEmpty(selections)) {
            Gikam.alert('GIKAM.TIP.MOVE_UP.NO_NODE_SELECTED');
            return false;
        }
        if (selections.length > 1) {
            Gikam.alert('GIKAM.TIP.MOVE_UP.MULTIPLE_NODES_SELECTED');
            return false;
        }
        if (selectedIndex === 0) {
            Gikam.alert('GIKAM.TIP.MOVE_UP.TOP_NODE_SELECTED');
            return false;
        }

        let window = Gikam.getInstance(jQuery(this.model.$el).closest('.window'));
        let data = this.getData();
        window.showMask(true);
        Gikam.put(
            Gikam.printf(url, {
                firstId: data[selectedIndex].id,
                secondId: data[selectedIndex - 1].id
            })
        )
            .done(function() {
                _this.refresh().done(function() {
                    _this.selectRowByIndex(selectedIndex - 1);
                    def.resolve();
                });
            })
            .always(function() {
                window.closeMask();
            });
        return def;
    }

    moveDown(url) {
        let selections = this.getSelections();
        let _this = this;
        let selectedIndex = this.model.$children[0].checkeds[0];
        let data = this.getData();
        let def = Gikam.getDeferred();

        if (Gikam.isEmpty(selections)) {
            Gikam.alert('GIKAM.TIP.MOVE_DOWN.NO_NODE_SELECTED');
            return false;
        }
        if (selections.length > 1) {
            Gikam.alert('GIKAM.TIP.MOVE_DOWN.MULTIPLE_NODES_SELECTED');
            return false;
        }
        if (selectedIndex === data.length - 1) {
            Gikam.alert('GIKAM.TIP.MOVE_DOWN.BOTTOM_NODE_SELECTED');
            return false;
        }

        let window = Gikam.getInstance(jQuery(this.model.$el).closest('.window'));
        window.showMask(true);
        Gikam.put(
            Gikam.printf(url, {
                firstId: data[selectedIndex].id,
                secondId: data[selectedIndex + 1].id
            })
        )
            .done(function() {
                _this.refresh().done(function() {
                    _this.selectRowByIndex(selectedIndex + 1);
                    def.resolve();
                });
            })
            .always(function() {
                window.closeMask();
            });
        return def;
    }

    selectRowByIndex(index) {
        this.model.$nextTick(() => {
            !this.model.$refs.vm.$refs['checkbox_' + index][0].checked &&
                this.model.$refs.vm.$refs['checkbox_' + index][0].$el.click();
        });
    }

    activeRowByIndex(index) {
        const id = this.options.data[index].id;
        this.model.$nextTick(() => {
            this.model.$refs.vm.$refs['row-' + id][0].click((this._ignoreClickEvent = true));
            this._ignoreClickEvent = false;
        });
    }

    /**
     * @description 定位竖向滚动条
     * @private
     * @memberof Grid
     */
    _positionScrollY(id) {
        if (Gikam.isEmpty(id)) {
            return false;
        }
        const fieldComp = this.model.$refs.vm.$refs['row-' + id][0];
        const gridVue = this.model.$refs.vm;
        const wrapperHeight = gridVue.$refs['vs'].$el.offsetHeight;
        const cellDomHeight = fieldComp.offsetHeight;
        const cellDomTop = fieldComp.offsetTop;
        const { scrollTop } = gridVue.$refs['vs'].getPosition();

        if (scrollTop > cellDomTop || wrapperHeight + scrollTop < cellDomTop + cellDomHeight) {
            gridVue.transition = false;
            gridVue.$refs.vs.scrollTo({ y: cellDomTop - wrapperHeight + cellDomHeight });
            setTimeout(() => {
                gridVue.transition = true;
            }, 0);
            return true;
        } else {
            return false;
        }
    }

    checkAll() {
        const _this = this;
        Gikam.each(this.options.data, function() {
            _this.selectRowById(this.id);
        });
    }

    selectRowById(id) {
        let index = null;
        Gikam.each(this.options.data, function(i) {
            if (this.id === id) {
                index = i;
                return false;
            }
        });
        this.selectRowByIndex(index);
    }

    setReadonlyParam(isReadonly) {
        this.options.columns
            .filter(function(column) {
                return Gikam.isNotEmpty(column.editor);
            })
            .forEach(function(column) {
                column.editor = !isReadonly;
            });
    }

    //批量只读设置
    setReadonly(isReadonly) {
        let readonly = Gikam.isEmpty(isReadonly) ? true : isReadonly;
        this.setReadonlyParam(readonly);
        this.refresh();
    }

    toFieldsReadonly(index, fields) {
        if (!Gikam.isNumber(index)) {
            Gikam.error(`index ${index} is not a validate number`);
        }
        this.model.$children[0].setFieldsReadonly(index, fields, true);
    }

    toFieldsEdit(index, fields) {
        if (!Gikam.isNumber(index)) {
            Gikam.error(`index ${index} is not a validate number`);
        }
        this.model.$children[0].setFieldsReadonly(index, fields, false);
    }

    hideBody() {
        this.options.showBody = false;
        return this;
    }

    showBody() {
        this.options.showBody = true;
        return this;
    }

    //追加内容到表格之后，此方法与hideBody配合使用，表格部分隐藏之后，再追加内容
    appendContent(content) {
        this.options.customContent = content;
        return this;
    }

    cleanData() {
        this.refreshByData({
            rows: [],
            total: 0,
            pageNumber: 0
        });
    }

    formatterPageList() {
        const gridPageList = this.options.pageList ?? [50, 100, 200, 'grid.all.data'];
        this.options.pageList = gridPageList.map(item => {
            return {
                value: item,
                text: item === 'grid.all.data' ? I18N.prop(item) : item + I18N.prop('grid.pageValue')
            };
        });
    }

    watchColumns() {
        let columns = this.options.columns;
        Object.defineProperty(this.options, 'columns', {
            set(value) {
                columns = value;
            },
            get() {
                return columns;
            }
        });
    }

    /**
     * @description 传入fields隐藏指定列
     * @public
     * @param {Array} 包含field字符串的数组
     * @memberof Grid
     */ deleteFormatter;
    hideColumns(fields) {
        //获取当前columns数组
        let columns = this.store.state.columns.slice();

        //获取隐藏columns数组
        let tempHiddenColumns = this.hideColumnArray;

        //计算隐藏列原始位置
        let computedIndex = 0;
        let hiddenFields = [];
        //还原原始columns(避免循环调用隐藏指定列导致的位置错乱问题)
        for (let i = tempHiddenColumns.length - 1; i >= 0; i--) {
            //记录位置减空余位置，计算得出原始位置
            let index = this.hideColumnArray[i].columnsIndex - computedIndex;
            columns.splice(index, 0, this.hideColumnArray[i].field);
            hiddenFields.push(this.hideColumnArray[i].field.field);
            computedIndex += 1;
        }

        this.hideColumnArray = [];
        for (let i = columns.length - 1; i >= 0; i--) {
            //判断当前列是否为隐藏列
            if (columns[i].field && fields.concat(hiddenFields).indexOf(columns[i].field) !== -1) {
                //保存隐藏字段
                this.hideColumnArray.push({ columnsIndex: i, field: columns[i] });
                //删除当前渲染列
                columns.splice(i, 1);
            }
        }
        this.store.commit('refreshColumns', columns);
    }

    /**
     * @description 通过传入field显示被隐藏的列
     * @public
     * @param {Array} 包含field字符串的数组
     * @memberof Grid
     */
    showColumns(fields) {
        //获取当前隐藏列数组
        let hiddenColumns = this.hideColumnArray;
        //用于计算原始位置
        let computedIndex = 0;

        let columns = this.store.state.columns.slice();

        for (let i = hiddenColumns.length - 1; i >= 0; i--) {
            if (fields.indexOf(hiddenColumns[i].field.field) !== -1) {
                //记录位置减空余位置，计算得出原始位置
                let index = this.hideColumnArray[i].columnsIndex - computedIndex;
                columns.splice(index, 0, this.hideColumnArray[i].field);
                this.hideColumnArray.splice(i, 1);
            } else {
                computedIndex += 1;
            }
        }
        this.store.commit('refreshColumns', columns);
    }

    /**
     * @description 隐藏或显示当前grid
     * @public
     * @param {Boolean} 隐藏 true, 显示 false
     * @memberof Grid
     */
    setHidden(arg) {
        this.model.$refs.vm.changeHiddenGridState(arg);
    }

    /* 
    私有方法
    自动保存时使用，用于获取字段的type和items
     */
    getFieldInfoByData(data, index) {
        const fieldInfo = {};
        this.options.columns.forEach(columnOptions => {
            if (
                Gikam.isNotEmpty(data[columnOptions.field], true) &&
                ['select', 'simpleCheckbox'].indexOf(columnOptions.type) > -1
            ) {
                const options = {
                    type: columnOptions.type === 'simpleCheckbox' ? 'checkbox' : columnOptions.type || 'text'
                };
                if (columnOptions.type === 'select') {
                    const selectEditor = this.getField(
                        Gikam.isNotEmpty(index) ? index : this.getIndexByDataId(data.id),
                        columnOptions.field
                    );
                    const items = (selectEditor || columnOptions).items?.map(item => {
                        return { text: item.text, value: item.value };
                    });
                    options.items = items;
                }
                fieldInfo[columnOptions.field] = options;
            }
        });
        return fieldInfo;
    }

    // eslint-disable-next-line complexity
    autoSave(data, header) {
        if (!this.options.autoSave) {
            return Promise.resolve();
        }
        //审计跟踪来源
        Gikam.buttonText = '保存';
        const realData = this.trigger('beforeUpdate', data, Object.keys(data));
        if (realData === false) {
            return Promise.resolve();
        }
        if (Gikam.isPlainObject(realData) && !Gikam.isEmptyObject(realData)) {
            data = realData;
        }
        if (!this.options.instantSavePath) {
            return Promise.resolve();
        }
        if (Gikam.isEmpty(data.id)) {
            data.id = data.row ? data.row.id : this.options.data[data.index].id;
        }
        // 例如data:{id:1,field1:'123'},row:{ext${field1_ext$:{}}},则将field1的属性合并到data中
        const updateFields = Object.getOwnPropertyNames(data).filter(item => !['id', 'index', 'row'].includes(item));
        if (updateFields.length === 1 && data.row && data.row.ext$) {
            const preStr = updateFields[0] + '_ext$';
            const fieldData = data.row.ext$[preStr];
            Gikam.each(fieldData, function(field) {
                if (field === '$field') {
                    data[fieldData[field]] = data[updateFields[0]];
                    delete data[updateFields[0]];
                } else {
                    data[field] = fieldData[field];
                }
            });
        }
        const index = data.index;
        delete data.index;
        delete data.row;

        return new Promise((resolve, reject) => {
            Gikam.put(
                this.getInstantSavePath(data),
                Gikam.getJsonWrapper({ t: this.getFieldInfoByData(data, index) }, [this.options.service, [data]]),
                header
            )
                .done(() => {
                    this.trigger('updated', data, Object.keys(data));
                    resolve();
                    Gikam.buttonText = null;
                })
                .catch(() => {
                    reject();
                });
        });
    }

    enableAutoSave() {
        this.canAutoSave = true;
    }

    disableAutoSave() {
        this.canAutoSave = false;
    }

    initGenericQueryFields() {
        if (this.options.genericQuery) {
            this.options.genericQueryFields = this.options.columns
                .filter(item => item.field && item.genericQuery)
                .map(item => {
                    return {
                        field: item.field
                    };
                });
        }
    }

    initReadonly() {
        if (this.options.readonly === true) {
            this.options.columns.forEach(item => {
                item.readonly = true;
                item.editor = false;
            });
        }
    }

    /**
     * @description 清除验证内容
     * @public
     * @param {array} fields 需要清除验证的fields字段
     * @param {array} validators 需要清除验证内容
     * @memberof Grid
     */
    removeFieldsValidator(fields, validators) {
        const _this = this;
        Gikam.each(fields, function(index, field) {
            Gikam.each(_this.validators, function(key, value) {
                if (key === field) {
                    for (let i = value.length - 1; i > -1; i--) {
                        validators.indexOf(value[i].type) > -1 && value.splice(i, 1);
                    }
                }
                const column = _this.options.columns.filter(item => item.field === field)[0];
                for (let j = column.validators.length - 1; j > -1; j--) {
                    validators.indexOf(column.validators[j]) > -1 && column.validators.splice(j, 1);
                }
            });
        });
    }

    /**
     * @description 增加验证内容
     * @public
     * @param {array} fields 需要增加验证的fields字段
     * @param {array} validators 需要增加验证内容
     * @memberof Grid
     */
    addFieldsValidator(fields, validators) {
        var _this = this;
        fields.forEach(field => {
            for (let i = 0; i < _this.options.columns.length; i++) {
                let item = _this.options.columns[i];
                if (item.field === field) {
                    if (Gikam.isEmpty(item.validators)) {
                        _this.model.$set(item, 'validators', validators);
                    } else {
                        item.validators = validators;
                    }
                    _this.registerColumnValidators(item);
                    return false;
                }
            }
        });
    }

    registerValidators() {
        let _this = this;
        this.options.columns
            .filter(function(item) {
                return Gikam.isNotEmpty(item.validators);
            })
            .forEach(function(item) {
                _this.registerColumnValidators(item);
            });
        return this;
    }

    registerColumnValidators(item) {
        const _this = this;
        this.validators[item.field] = [];
        item.validators.forEach(function(name) {
            let validator = {};
            if (Gikam.isPlainObject(name)) {
                Gikam.extend(validator, name);
            } else if (name.indexOf('strLength') === 0) {
                Gikam.extend(validator, {
                    type: 'strLength',
                    rule: name.replace('strLength', '')
                });
            } else if (name.indexOf('numRange') === 0) {
                Gikam.extend(validator, {
                    type: 'numRange',
                    rule: name.replace('numRange', '')
                });
            } else if (name.indexOf('remote') === 0) {
                Gikam.extend(validator, {
                    type: 'remote',
                    rule: name.replace(/remote|\[|]/g, '')
                });
            } else if (name.indexOf('unique') === 0) {
                Gikam.extend(validator, {
                    type: 'unique',
                    rule: name.replace(/unique/, '').replace(/\[|]/g, '')
                });
            } else {
                Gikam.extend(validator, {
                    type: name
                });
            }
            validator.title = item.title;
            _this.validators[item.field].push(validator);
        });
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    getCheckedRowIndex() {
        return this.model.$refs.vm.checkeds;
    }

    /**
     * @description 动态刷新表格列
     * @public
     * @param {Array} columns 新的字段数组
     * @returns {Deferred}
     * @memberof Grid
     */
    refreshColumns(columns) {
        const def = Gikam.getDeferred();
        this.canAutoSave = false;
        this.cacheSelectItems(columns).done(() => {
            this.store.commit('refreshColumnsConfig', columns);

            this.initColumnsGroupMap();

            this.caseSelectValue();
            this.model.$nextTick(() => {
                this.store.commit('changeScrollLeft', 0);
                this.model.$refs.vm.initScroll();
                this.canAutoSave = true;
                def.resolve();
            });
        });
        return def;
    }

    /**
     * @description 获取快捷查询的条件
     * @private
     * @memberof Grid
     */
    getFilterRequestData() {
        const data = {};
        const filterData = Gikam.deepExtend(this.model.$refs.vm.$refs.header.filterData);
        for (let key in filterData) {
            if (Gikam.isNotEmpty(filterData[key])) {
                data[key] = filterData[key];
            }
        }
        return Object.keys(data).length === 0 ? null : data;
    }

    /**
     * @description 初始化请求查询条件
     * @private
     * @memberof Grid
     */
    initRequestData() {
        if (this.options.initRequest === false) {
            this.setOptions({
                requestData: {
                    //传此标志后台不进行数据查询
                    scheme_only: true
                }
            });
        }
    }

    /**
     * @description 通过id获取该条数据的索引
     * @param {String,Number} id 数据的id
     * @memberof Grid
     */
    getRowIndexById(id) {
        let index;
        Gikam.each(this.getData(), function(i) {
            if (this.id === id) {
                index = i;
                return false;
            }
        });
        return index;
    }

    /**
     * @description 获取单元格内组建
     * @public
     * @param {number/String} index 所在行
     * @param {String} field  所在列对应的field字段
     * @memberof Grid
     */
    getField(index, field) {
        return this.model.$refs.vm.$refs[index + '_' + this.getColumnsIndex(field)];
    }

    /**
     * @description 获取表头快捷查询编辑器
     * @param {*} field
     * @returns
     * @memberof Grid
     * @public
     */
    getHeadField(field) {
        return this.model.$refs.vm.$refs.header.$refs[field];
    }

    /**
     * @description 获取指定的列
     * @public
     * @param {*} fieldName
     * @returns
     * @memberof Grid
     */
    getColumn(fieldName) {
        return new GridColumn(this, fieldName);
    }

    /**
     * @description 通过数据id，获取索引
     * @public
     * @param {number/String} dataId 数据id
     * @memberof Grid
     */
    getIndexByDataId(dataId) {
        let index;
        Gikam.each(this.options.data, function(i) {
            if (this.id === dataId) {
                index = i;
                return true;
            }
        });
        return index;
    }

    /**
     * @description 激活单元格组件
     * @public
     * @param {number/String} index 所在行
     * @param {String} field  所在列对应的field字段
     * @memberof Grid
     */
    focusField(index, field) {
        const _field = this.getField(index, field);
        if (Gikam.isEmpty(_field) || _field.options.readonly === true) {
            return;
        }
        const { rowIndex, cellIndex } = _field;
        this.baseFocusField(rowIndex, cellIndex, _field);
    }

    /**
     * @description 激活单元格组件
     * @private
     */
    baseFocusField(rowIndex, cellIndex, field) {
        if (field.activeCell instanceof Function !== true) {
            return;
        }
        if (typeof rowIndex === 'number' && typeof cellIndex === 'number') {
            const scrollRes = this.model.$refs.vm.handleCursorPosition(rowIndex, cellIndex, field);
            if (scrollRes) {
                setTimeout(() => {
                    field.activeCell && field.activeCell();
                }, 500);
            } else {
                field.activeCell && field.activeCell();
            }
        }
    }

    /**
     * @description 根据field 获取所在列的下表
     * @private
     * @param {String} field 需要查找的列的field字段
     * @memberof Grid
     */
    getColumnsIndex(field) {
        const columns = this.store.state.columns || [];
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].field === field) {
                return i;
            }
        }
    }

    /**
     * @description 重新刷新Grid分组
     * @public
     * @param {分组参数} param {fields:[field1,field2],formatter:function(){}}
     * @memberof Grid
     */
    refreshGroup(param) {
        this.options.group = param;
        this.initRowGroup();
        this.refresh();
    }

    /**
     * @description 父组件尺寸变更时触发
     * @private
     * @param {object} size {width:100,height:100}
     * @memberof Tab
     */
    onResize() {
        this.store && this.store.commit('refreshColumns', this.store.state.columns);
        this.$parent && this.$parent.$width && (this.options.allowWidth = this.$parent.$width);
        if (this.model && this.model.$children) {
            this.model.$children[0].handleBtnMore();
            this.model.$children[0].$refs.vs.resizeScroll();
        }
    }

    /**
     * @description 刷新右键菜单
     * @public
     * @param {Array} menu 菜单列表
     * @memberof Grid
     */
    refreshContextmenu(menu) {
        this.store.commit('updateContextmenu', menu);
    }
    /**
     * @description 前端筛选
     * @public
     * @memberof Grid
     */
    pageSearchGrid() {
        const requestKeys = Object.keys(this.pageRequest);
        let newData = [...this.data];
        if (Gikam.isEmpty(requestKeys)) {
            this.refreshByData({ rows: newData, initPageData: false });
        } else {
            Gikam.each(requestKeys, (_index, key) => {
                if (Gikam.isEmpty(newData)) {
                    newData = [];
                    return false;
                }
                const condition = this.pageRequest[key];
                if (condition.column.type === 'filterDateField') {
                    newData = this.$queryTime(newData, key, condition);
                } else {
                    newData = this.$queryNormal(newData, key, condition);
                }
            });
            this.refreshByData({ rows: newData, initPageData: false });
        }
    }

    /**
     * @description 前端筛选初始化相应数据
     * @private
     * @memberof Grid
     */
    $initPageSearch(data) {
        if (data && Gikam.isFalse(data.initPageData)) {
            return;
        }
        this.data = [...data.rows];
        this.pageRequest = {};
        // this.model.$nextTick(()=>{
        //     const header = this.model.$refs.vm.$refs.header;
        //     for (let name in header.$refs) {
        //         if(typeof(header.$refs[name].value) !== 'undefined') {
        //             header.$refs[name].value = ''
        //         }
        //     }
        // })
    }

    /**
     * @description 前端筛选条件存储与删除
     * @private
     * @param {object} 存储的键名, 筛选值, 当前列的配置参数, select时的值
     * @memberof Grid
     */
    $setPageRequest({ field, value, column, selectText }) {
        if (Gikam.isEmpty(value)) {
            delete this.pageRequest[field];
        } else {
            this.pageRequest[field] = { value, column, selectText };
        }
    }

    /**
     * @description 获取单元格值
     * @private
     * @memberof Grid
     */
    $getFieldValue(row, key) {
        if (key.indexOf('ext$.') === 0) {
            return row.ext$[key.replace('ext$.', '')];
        } else {
            return row[key];
        }
    }

    /**
     * @description 时间框筛选
     * @private
     * @memberof Grid
     */
    $queryTime(rows, key, condition) {
        return rows.filter(item => {
            const targetValue = this.$getFieldValue(item, key) && this.$getFieldValue(item, key).split(' ')[0];
            if (Gikam.isEmpty(targetValue)) {
                return false;
            }
            const conditionValue = condition.value.split('~');
            let result;
            if (Gikam.isEmpty(conditionValue[1])) {
                const firstR = this.$targetValueIsBig(targetValue, conditionValue[0]);
                result = Gikam.isTrue(firstR) ? true : false;
            } else if (Gikam.isEmpty(conditionValue[0])) {
                const secondR = this.$targetValueIsBig(targetValue, conditionValue[1]);
                result = Gikam.isFalse(secondR) ? true : false;
            } else {
                const firstR = this.$targetValueIsBig(targetValue, conditionValue[0]);
                const secondR = this.$targetValueIsBig(targetValue, conditionValue[1]);
                result = (firstR && !secondR) || (secondR && !firstR);
            }
            return result;
        });
    }

    /**
     * @description 时间框比较大小的基本函数
     * @private
     * @memberof Grid
     */
    $targetValueIsBig(targetValue, conditionValue) {
        const targetArr = targetValue && targetValue.split('-');
        const conditionArr = conditionValue.split('-');
        while (Gikam.isNotEmpty(targetArr)) {
            const isBig = targetArr.shift();
            const isSmall = conditionArr.shift();
            if (0 > isBig - isSmall) {
                return false;
            } else if (0 < isBig - isSmall) {
                return true;
            }
        }
        return true;
    }

    /**
     * @description 除时间框之外的筛选
     * @private
     * @memberof Grid
     */
    $queryNormal(rows, key, condition) {
        return rows.filter((item, index) => {
            let targetValue = this.$getFieldValue(item, key);
            let conditionValue = condition.value;
            if (condition.column.type === 'selectField') {
                conditionValue = condition.selectText;
                Gikam.each(condition.column.items, function() {
                    if (this.value === targetValue) {
                        targetValue = this.text;
                        return false;
                    }
                });
            }
            if (Gikam.isNotEmpty(condition.column.formatter)) {
                targetValue =
                    condition.column.type === 'selectField'
                        ? condition.column.formatter(index, targetValue, item, condition.selectText)
                        : condition.column.formatter(index, targetValue, item);
            }
            return new String(targetValue).indexOf(conditionValue) >= 0;
        });
    }

    /**
     * @description 滚动至刷新前选中项
     * @public
     * @memberof Grid
     */
    selectLatestSelectedRow() {
        //获取model对象
        const model = this.model.$refs.vm;
        //获取刷新前选中行
        if (!model.checkedAll || !model.checkedAll?.length) {
            return;
        }
        const checkeds = model.checkedAll.sort((pre, cur) => pre - cur);

        const minIndex = checkeds[0];
        // //如果数据为空则不做任何操作
        if (this.options.data.length === 0) return;
        const row = this.options.data[minIndex];

        if (!row) {
            return;
        }
        //获取选中行dom对象
        let temp = this.model.$refs.vm.$refs[`row-${row.id}`];
        if (temp.length) {
            //滚动至选中对象
            let scrollTop = temp[0].offsetTop;
            this.model.$refs.vm.$refs.vs.scrollTo({
                y: scrollTop
            });
        }

        checkeds.forEach(item => {
            this.selectRowByIndex(item);
        });
        if (!this.options.checkOnActive && !this.options.checkOneOnActive) {
            this.activeRowByIndex(minIndex);
        }
    }

    /**
     * @description 滚动至刷新前选中项
     * @public
     * @memberof Grid
     */
    activateLatestActivatedRow() {
        //获取model对象
        const model = this.model.$refs.vm;
        //获取刷新前选中行
        let minActivatedCell = model.activeRow.rowIndex;
        if (typeof minActivatedCell !== 'number') return;
        //如果数据为空则不做任何操作
        if (this.options.data.length === 0) return;

        if (minActivatedCell > this.options.data.length) minActivatedCell = this.options.data.length;
        const row = this.options.data[minActivatedCell];
        //获取选中行dom对象
        let temp = this.model.$refs.vm.$refs[`row-${row.id}`];
        if (temp.length) {
            //滚动至选中对象
            let scrollTop = temp[0].offsetTop;
            this.model.$refs.vm.$refs.vs.scrollTo({
                y: scrollTop
            });
        }
        //激活指定行
        this.activeRowByIndex(minActivatedCell);
    }

    /**
     * @description 获取当前正在操作的单元格组件 没有返回null
     * @public
     * @memberof Grid
     */
    getActiveEditor() {
        if (Gikam.isEmpty(this.store.state.editingChildren.coordinate)) {
            return null;
        }

        return this.model.$refs.vm.$refs[this.store.state.editingChildren.coordinate.join('_')];
    }

    getActiveCell() {
        if (Gikam.isEmpty(this.options.id) || Gikam.isEmpty(Gikam.globalActiveField)) {
            return {
                setValue() {
                    Gikam.error('当前系统不存在激活的单元格');
                }
            };
        }
        const cell = Gikam.globalActiveField[this.options.id];
        if (Gikam.isEmpty(cell)) {
            return {
                setValue() {
                    Gikam.error('当前系统不存在激活的单元格');
                }
            };
        }
        return {
            $Vue: cell,
            setValue(value) {
                if (Gikam.isEmpty(value)) {
                    return Gikam.error('未识别需要设置的值');
                }
                this.$Vue.value = value;
            }
        };
    }

    /**
     * @description 禁止不同用户对同一条数据同时编辑
     * @private
     * @memberof Grid
     */
    checkEditable() {
        const coordinate = this.store.state.editingChildren.coordinate;
        const gridId = this.options.id;
        const dataId = this.options.data[coordinate[0]].id;
        Gikam.isEmpty(this.checkEditorFields) &&
            this.options.columns.forEach(column => {
                column.field && !column.readonly && this.checkEditorFields.push(column.field);
            });
        Gikam.post(Gikam.IFM_CONTEXT + `/secure/core/module/gikam/repositories/mark-editable/${gridId}$${dataId}`).then(
            res => {
                if (!res) {
                    if (
                        ['sign', 'choose', 'richTextChoose', 'cron'].indexOf(this.store.state.editingChildren.type) > -1
                    ) {
                        Gikam.getLastModal() && Gikam.getLastModal().close();
                    } else {
                        Gikam.simulatedEvent(document, 'mousewheel');
                        Gikam.simulatedEvent(document, 'click');
                    }
                    this.refreshRowByIndex(coordinate[0]);
                    this.toFieldsReadonly(coordinate[0], this.checkEditorFields);
                    this.preEditingIndex = coordinate[0];
                    Gikam.alert(I18N.prop('grid.checkEditable'));
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.toFieldsEdit(this.preEditingIndex, this.checkEditorFields);
                    this.preEditingIndex = null;
                }
            }
        );
    }

    /**
     * @description 获取焦点后循环触发checkEditable
     * @private
     * @memberof Grid
     */
    repeatCheckEditor() {
        const coordinate = this.store.state.editingChildren.coordinate;
        if (Gikam.isEmpty(coordinate)) return;
        const gridId = this.options.id;
        const dataId = this.options.data[coordinate[0]].id;
        if (!gridId || !dataId) return;
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
        this.checkEditable();
        this.timer = setInterval(() => {
            this.checkEditable();
        }, 7e3);
    }

    /**
     *
     * @description 通过查询数据，存储新增或者保存url
     * @private
     * @param {*} xhr
     * @memberof Grid
     */
    storeInstantSavePath(xhr) {
        if (this.options.instantSavePath) {
            return;
        }
        this.options.instantSavePath = Gikam.IFM_CONTEXT + xhr.getResponseHeader('GIKAM-INSTANT-SAVE-PATH');
    }

    /**
     * @description 获取实时保存路径
     * @private
     * @param {*} data
     * @returns
     * @memberof Grid
     */
    getInstantSavePath(data) {
        if (Gikam.isEmpty(this.options.instantSavePath)) {
            return '';
        }
        return Gikam.printf(this.options.instantSavePath, data);
    }

    /**
     * @description 获取行专列字段数据{a:1,ext$:{field_ext$:{}}}
     * @param {*} index
     * @param {*} fieldName
     * @memberof Grid
     */
    getRowToColumnFieldData(index, field) {
        const row = this.options.data[index];
        if (!row.ext$) {
            return;
        }
        return row.ext$[field + '_ext$'];
    }

    /**
     * @description editorInvisible状态下 设置当前编辑的节点
     *
     */
    _setCurrentEditor(editor) {
        if (Gikam.isEmpty(editor) && Gikam.isNotEmpty(this.currentEditor)) {
            this.currentEditor.invisible = true;
            this.currentEditor.validateResult = true;
        }
        this.currentEditor = editor;
    }
    /**
     * @description editorInvisible状态下 获取当前编辑的节点
     *
     */
    _getCurrentEditor() {
        return this.currentEditor;
    }

    /**
     * @description 在移动端修改列宽时给全局添加遮罩
     *
     */
    _addMask() {
        const _this = this;
        _this.gridMask = new Vue({
            el: Gikam.createDom('div', _this.model.$el.parentNode),
            components: {
                GridMask: () => import('../vue/gridMask.vue')
            },
            mounted() {
                _this.model.$refs.vm.$refs.header.updateResizeField(true);
                document.querySelectorAll('.scroll-content').forEach(item => {
                    item.style.transform = '';
                });
            },
            beforeDestroy() {
                _this.model.$refs.vm.$refs.header.updateResizeField();
            },
            methods: {
                clear() {
                    Gikam.removeDom(this.$el);
                    this.$destroy();
                    _this.gridMask = null;
                }
            },
            render() {
                return <grid-mask propDom={_this.model.$el} onClose={this.clear} />;
            }
        });
    }

    /**
     * @private
     * @description
     * @memberof Form
     */
    validateExistsError(rows) {
        if (Gikam.isNotEmpty(rows)) {
            this.errorFields = [];
        }
        const errorMessages = this.errorFields.map(error => {
            return {
                title: error.title,
                message: error.message
            };
        });
        if (Gikam.isNotEmpty(errorMessages)) {
            this.showValidateMessage(errorMessages);
            return false;
        }
        return true;
    }

    init() {
        this.registerValidators();
        this.initRequestData();
        this.watchColumns();
        this.formatterPageList();
        this.initGenericQueryFields();
        this.initReadonly();
        this.createModel();
        this.$initPageSearch({ rows: this.options.data });
    }

    /**
     * @description 字段值改变
     * @memberof Form
     * @private
     */
    // eslint-disable-next-line complexity
    filedChangeHandle({ field, value, index }) {
        if (this.$changeFields['__ob__']) {
            delete this.$changeFields['__ob__'];
        }
        let isFieldChange = Gikam.getFieldValue(this.options.data[index], field) + '' !== value + '' ? true : false;
        const changeRow = this.$changeFields[index];
        if (value === '' && changeRow && changeRow[field] === undefined) {
            isFieldChange = false;
        }
        if (isFieldChange === false && changeRow && Gikam.isNotEmpty(changeRow[field])) {
            delete changeRow[field];
            if (Gikam.isEmptyObject(changeRow)) {
                delete this.$changeFields[index];
            }
        } else {
            if (changeRow) {
                changeRow[field] = value;
            } else {
                this.$changeFields[index] = { [field]: value };
            }
        }
        const changeFlag = Gikam.isEmptyObject(this.$changeFields) ? false : true;
        this.$window && this.$window.changeSaveButtonPrompt(this, changeFlag);
    }

    /**
     * @description 控制工具栏显示
     *
     */
    showToolbar() {
        this.options.toolbarHidden = true;
    }

    /**
     * @description 控制工具栏隐藏
     *
     */
    hideToolbar() {
        this.options.toolbarHidden = false;
    }
}
