<template>
    <div ref="notice" class="notice">
        <div class="notice-data" ref="noticeData" @mouseenter="stop" @mouseleave="play">
            <scroll
                class="data-scoll"
                ref="scroll"
                @handle-scroll="handleScroll"
                :scrollingX="false"
                :maxWidth="'100%'"
            >
                <div class="data-info" v-for="(item, index) in option" :key="index">
                    <div class="header">
                        <span>【{{ checkInfoCategory(item) }}】</span>
                        <div class="title" @click="noticehandle(item)">{{ item.title }}</div>
                        <div class="icon">
                            <noticeImg :color="importance(item)"></noticeImg>
                        </div>
                    </div>
                    <div class="content">
                        <div v-html="$xss(checkContent(item.content))"></div>
                    </div>
                    <div class="footer">
                        <span class="company">{{ item.publishOrgName }}</span>
                        <span v-if="item.validDate" class="date">有效期：{{ item.validDate }}</span>
                    </div>
                </div>
                <div class="no-record" v-if="option.length === 0">
                    <img class="no-record-img" src="../../../../img/no-record.png" />
                    <span class="no-record-text">暂无公告</span>
                </div>
            </scroll>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import noticeDetail from './noticeDetail';
import Vue from 'vue';

export default {
    props: {
        options: Object
    },

    data() {
        return {
            color: '#007aff',
            // 定时器
            timer: null,
            distance: 0,
            initInterval: 2.5,
            option: [],
            visualQuantity: 0,
            infoCategoryData: [],
            dataArr: []
        };
    },

    computed: {
        interval() {
            return this.initInterval * 1000;
        }
    },

    mounted() {
        this.visualQuantity = Math.ceil(this.$refs.noticeData.offsetHeight / 68);
        this.initInfoCategory();
    },

    methods: {
        initInfoCategory() {
            let selectItems = [
                {
                    type: 'select',
                    category: 'sys',
                    param: {
                        codeCategoryId: 'infocategory'
                    }
                }
            ];
            Gikam.select.cacheItems(selectItems).done(() => {
                this.infoCategoryData = selectItems[0].items;
                this.setOptions();
            });
        },

        setOptions() {
            Gikam.postText(this.options.url, Gikam.getJsonWrapper({ f: this.options.requestData }))
                .done(res => {
                    this.option = JSON.parse(res);
                    this.init();
                })
                .fail(function() {
                    this.option = [];
                });
        },

        init() {
            if (this.option.length >= this.visualQuantity) {
                this.dataArr = this.option;
                let option = [];
                option = option.concat(this.option);
                this.option = option;
                this.play();
            }
        },

        checkInfoCategory(item) {
            for (let i = 0; i < this.infoCategoryData.length; i++) {
                if (item.infoCategory == this.infoCategoryData[i].value) {
                    return this.infoCategoryData[i].text;
                }
            }
            return '';
        },

        importance(item) {
            if (item.importance) {
                if (item.importance == 'important') {
                    return 'rgb(255, 57, 57)';
                } else if (item.importance == 'ordinary') {
                    return 'rgb(19, 197, 150)';
                } else {
                    return 'rgb(255, 169, 57)';
                }
            }
        },

        checkContent(content) {
            if (!content) return '';
            return content
                .replace(/<\/?.+?\/?>/g, '')
                .replace(/\s/g, '')
                .replace(/&nbsp;/g, '');
        },

        noticehandle(item) {
            let modal = top.Gikam.create('modal', {
                title: '通知详情',
                width: 900,
                height: 520,
                toolbarTop: true,
                headerShow: true
            });

            let _this = this;

            this.noticeDetail = new Vue({
                el: modal.window.$dom,
                components: {
                    noticeDetail
                },
                render() {
                    return (
                        <noticeDetail
                            options={item}
                            bizId={_this.options.bizId}
                            infoCategoryData={_this.infoCategoryData}
                        ></noticeDetail>
                    );
                }
            });
        },

        move() {
            const viewHeight = this.$el.offsetHeight - 8;
            let allHeight = this.option.length * 68 - viewHeight;
            if (this.distance >= allHeight) {
                this.$refs.scroll.scrollTo(
                    {
                        y: 0
                    },
                    10
                );
                return;
            }
            let dy = allHeight - this.distance;
            if (dy >= 68) {
                dy = 68;
            }
            this.$refs.scroll.scrollBy(
                {
                    dy
                },
                1000
            );
        },

        handleScroll(v) {
            this.distance = v;
        },

        // 自动轮播
        play() {
            if (this.option.length < this.visualQuantity) {
                return;
            }
            if (this.timer) {
                window.clearInterval(this.timer);
                this.timer = null;
            }
            this.timer = window.setInterval(() => {
                this.move();
            }, this.interval);
        },

        // 停止轮播
        stop() {
            if (this.option.length < this.visualQuantity) {
                return;
            }
            window.clearInterval(this.timer);
            this.timer = null;
        }
    },

    destroyed() {
        // 销毁定时器
        window.clearInterval(this.timer);
        this.timer = null;
    }
};
</script>

<style scoped>
.notice {
    height: 100%;
    padding: 4px 8px;
}

.notice .notice-data {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.notice .notice-data .data-scoll {
    display: flex;
    flex-direction: column;
}

.notice .notice-data .data-scoll .data-info {
    width: 100%;
    height: 68px;
    padding: 4px;
}

.notice .notice-data .data-scoll .data-info:hover {
    background: #e5f5f1;
}

.notice .notice-data .data-scoll .data-info .header {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    font-family: '微软雅黑';
    text-align: left;
    display: flex;
    position: relative;
}

.notice .notice-data .data-scoll .data-info .header .title {
    cursor: pointer;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notice .notice-data .data-scoll .data-info .header .title:hover {
    color: #007aff;
}

.notice .notice-data .data-scoll .data-info .header .icon {
    position: absolute;
    top: 1px;
    right: 4px;
}

.notice .notice-data .data-scoll .data-info .content {
    width: 100%;
    line-height: 26px;
    font-family: '微软雅黑';
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    padding: 0 6px;
}

.notice .notice-data .data-scoll .data-info .content > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notice .notice-data .data-scoll .data-info .footer {
    font-size: 12px;
    font-family: '微软雅黑';
    color: rgba(0, 0, 0, 0.45);
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
}

.notice .no-record {
    background-color: #fff;
    text-align: center;
}

.notice .no-record .no-record-img {
    display: block;
    margin: 10px auto 0;
}

.notice .no-record .no-record-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
}
</style>
