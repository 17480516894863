import '../fonts/style.css';
import '../css/gikam.css';
import Gikam from 'gikam';
import jQuery from 'jquery';
import Vue from 'vue';
import '@/gikam/js/core/xss.js';
import './core/gikam-global-event.js';
import Base from './components/base.js';
import Window from './components/window.js';
import Layout from './components/layout/layout.js';
import BtnToolbar from './components/btnToolbar.js';
import BtnGroup from './components/btnGroup.js';
import DatePicker from './components/datePicker.js';
import Form from './components/form/js/form.js';
import Tab from './components/tab.js';
import Modal from './components/modal.js';
import Grid from './components/grid/js/grid';
import SimpleGrid from './components/simpleGrid.js';
import Tree from './components/tree/tree.js';
import validator from './core/validator.js';
import Alert from './components/alert/js/alert';
import ErrorAlert from './components/errorAlert/js/errorAlert';
import Confirm from './components/confirm.js';
import I18NComp from './components/I18N.js';
import TreeGrid from './components/treeGrid.js';
import Uploader from './components/uploader.js';
import ElnAuditTrail from './components/elnAuditTrail.js';
import DateUtils from './utils/dateUtils.js';
import choose from './core/chooseHelper.js';
import select from './core/selectHelper.js';
import SimpleUploader from './components/simpleUploader';
import Iframe from './components/iframe/js/iframe';
import SimpleTreeGrid from './components/simpleTreeGrid.js';
import Workflow from './components/workflow';
import md5 from './utils/md5.js';
import Echarts from './components/echarts.js';
import Button from './components/button.js';
import DropDownMenu from './components/dropDownMenu.js';
import ShuttleFrame from './components/shuttleFrame.js';
import SampleLocation from './components/sampleLocation.js';
import SubwayNav from './components/subwayNav.js';
import VueComponent from './components/vueComponent';
import installHugeComponent from './components/huge/hugeComponent';
import IconPanel from './components/iconPanel';
import EditorOffice from './components/onlyoffice/editorOffice';
import Eln from './components/eln/eln';
import ReLoginAlert from './components/reLoginAlert/js/reLoginAlert.js';
import Toast from './components/toast/js/toast';
import ImgGrid from './components/imgGrid/js/imgGrid';
import Gantt from './components/gantt/js/gantt';
import Notice from './components/notice/js/notice';
import Map from './components/map/js/map';
import ErrorPage from './components/errorPage/js';
import NumberUtils from './utils/numberUtils';
import SpreadEln from './components/eln/spreadEln';
import Viewer from './components/viewer.js';
import ResultInput from './components/resultInput/js/resultInput';
import { I18N } from '@/gikam/i18n/I18N.js';
import { RsaEncryptUtils } from '@/gikam/js/utils/rsaEncryptUtils';

Gikam.component.ResultInput = ResultInput;
Gikam.component.Base = Base;
Gikam.component.Window = Window;
Gikam.component.Layout = Layout;
Gikam.component.Modal = Modal;
Gikam.component.Tree = Tree;
Gikam.component.Alert = Alert;
Gikam.component.ErrorAlert = ErrorAlert;
Gikam.component.Confirm = Confirm;
Gikam.component.Grid = Grid;
Gikam.component.SimpleGrid = SimpleGrid;
Gikam.component.Tab = Tab;
Gikam.component.Form = Form;
Gikam.component.DatePicker = DatePicker;
Gikam.component.BtnToolbar = BtnToolbar;
Gikam.component.BtnGroup = BtnGroup;
Gikam.component.I18N = I18NComp;
Gikam.component.FrontI18N = I18N;
Gikam.component.TreeGrid = TreeGrid;
Gikam.component.Uploader = Uploader;
Gikam.component.SimpleUploader = SimpleUploader;
Gikam.component.Iframe = Iframe;
Gikam.component.SimpleTreeGrid = SimpleTreeGrid;
Gikam.component.Workflow = Workflow;
Gikam.component.Echarts = Echarts;
Gikam.component.Button = Button;
Gikam.component.DropDownMenu = DropDownMenu;
Gikam.component.ElnAuditTrail = ElnAuditTrail;
Gikam.component.ShuttleFrame = ShuttleFrame;
Gikam.component.SampleLocation = SampleLocation;
Gikam.component.SubwayNav = SubwayNav;
Gikam.component.IconPanel = IconPanel;
Gikam.component.ReLoginAlert = ReLoginAlert;
Gikam.component.Toast = Toast;
Gikam.component.ImgGrid = ImgGrid;
Gikam.component.Gantt = Gantt;
Gikam.component.Notice = Notice;
Gikam.component.Map = Map;
Gikam.component.ErrorPage = ErrorPage;
Gikam.component.SpreadEln = SpreadEln;
Gikam.component.Viewer = Viewer;

Gikam.DateUtils = DateUtils;
Gikam.md5 = md5;
Gikam.validator = validator;
Gikam.choose = choose;
Gikam.select = select;
Gikam.jQuery = jQuery;
Gikam.Vue = Vue;
Gikam.EditorOffice = EditorOffice;
Gikam.Eln = Eln;
Gikam.NumberUtils = NumberUtils;
Gikam.RsaEncryptUtils = RsaEncryptUtils;

Vue.use(VueComponent);
installHugeComponent();

//如果在iframe中，则将外层国际化放到本页面中
if (top.window !== window) {
    I18N.map = top.window.Gikam.component.FrontI18N.map;
    I18N.mixinVue();
}
window.Gikam = Gikam;
