<template>
    <div v-show="showTable" class="table" :style="{ height: height }">
        <div class="toolBar" v-if="toolbarList">
            <operation class="toolBar-operation" ref="operation"></operation>
            <generalGroup class="toolBar-general" v-if="generalShow"></generalGroup>
        </div>
        <div class="head-body" :style="{ height: toolbarList ? ' calc(100% - 35px)' : '100% ' }">
            <template v-if="noScroll">
                <div class="header headerCell" :style="{ width: tableWidth, background: '#fff' }">
                    <table-header :columns="columns" :level="0"> </table-header>
                </div>
                <div class="body" :style="{ width: tableWidth, background: '#fff' }">
                    <table-body ref="table-body" v-if="dataLength > 0"></table-body>
                    <no-data v-else> </no-data>
                </div>
            </template>
            <template v-else>
                <div class="header" :style="{ height: hasScrollHeight }">
                    <scroll :ops="headerScrolllProps" ref="headerScrolll">
                        <div class="headerCell" :style="{ width: tableWidth }">
                            <table-header :columns="columns" :level="0"> </table-header>
                        </div>
                    </scroll>
                </div>
                <div class="body">
                    <scroll :ops="bodyScrolllProps" @handle-scroll="bodyScrollEvent">
                        <div class="bodyCell bodyCellScrolll" :style="{ width: tableWidth }">
                            <table-body ref="table-body" v-if="dataLength > 0"></table-body>
                            <no-data v-else> </no-data>
                        </div>
                    </scroll>
                </div>
            </template>
        </div>
        <div class="foot"></div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import operation from './tool/operation';
import generalGroup from './tool/generalGroup';
import tableHeader from './head/header';
import tableBody from './body/body';
import { getUnit } from '../js/utils';

export default {
    props: {},

    created() {
        this.getUnit = getUnit.bind(this);
    },

    provide() {
        return {
            getBodyCell: this.getBodyCell
        };
    },

    data() {
        return {
            headerScrolllProps: {
                scrollPanel: { scrollingY: false, scrollingX: false }
            },
            bodyScrolllProps: {
                bar: { background: '#bbb', onlyShowBarOnScroll: false }
            },
            generalShow: false
        };
    },

    computed: {
        ...mapGetters(['toolbarList', 'tableHeight', 'tableHeaderHeight', 'fill', 'noScroll']),
        ...mapState(['columns', 'headerMaxLevel', 'data', 'showTable', 'headerHeightArr', 'allColsWidth']),
        ...mapState('scroll/', ['horizontal']),
        dataLength() {
            return this.data.length;
        },
        height() {
            if (this.noScroll === true) {
                return void 0;
            }
            if (this.fill) {
                return '100%';
            }
            return this.getUnit(this.tableHeight);
        },
        hasScrollHeight() {
            return this.headerHeightArr[0] + 'px';
        },
        tableWidth() {
            return this.allColsWidth + 'px';
        }
    },

    watch: {
        horizontal(val) {
            if (!this.$refs || !this.$refs.headerScrolll) {
                return;
            }
            this.$refs.headerScrolll.scrollTo(
                {
                    x: val
                },
                0
            );
        }
    },

    methods: {
        bodyScrollEvent(v, h, event) {
            event.stopPropagation();
            this.$store.commit('scroll/changeX', h.scrollLeft);
        },

        getBodyCell(rowIndex, colIndex) {
            return this.$refs['table-body'].getCell(rowIndex, colIndex);
        },

        onResize() {
            this.$refs.operation && this.$refs.operation.onResize();
        }
    },

    components: {
        operation,
        generalGroup,
        tableHeader,
        tableBody,
        scroll: () => import('vuescroll'),
        noData: () => import('./tool/noData')
    }
};
</script>

<style scoped>
.table {
    background: #fff;
    box-sizing: content-box;
    min-width: calc(100% - 18px);
    margin: 0 8px;
}
.toolBar {
    height: 35px;
    padding-top: 5px;
    display: flex;
    overflow: auto;
}
.toolBar .toolBar-operation {
    flex: 1;
}
.toolBar .toolBar-general {
    width: 28px;
    margin-left: 10px;
}

.head-body {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.header {
    min-height: 35px;
}
.header .headerCell {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.body {
    width: 100%;
    flex: 1;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
}

.body .bodyCell {
    margin-right: 8px;
}
</style>
