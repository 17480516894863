<template>
    <div
        ref="panel"
        :style="options.style"
        class="grid-generic-query-panel"
        @click.stop
        @mousedown.stop
        @mousewheel.stop
        @DOMMouseScroll.stop
    >
        <div class="wrapper">
            <div class="toolbar">
                <div class="add-row" @click="addFilterRow" :title="$i18n('grid.addFilterRow')"></div>
            </div>
            <section :style="{ height }">
                <scroll>
                    <ul>
                        <li v-for="(condition, index) in conditions" :key="condition.id">
                            <select-field
                                :options="{ items: fields, field: 'field', _isHeader: true }"
                                :rowIndex="index"
                                :propValue="condition.field"
                                @change="fieldChangeHandle"
                            />
                            <select-field
                                :options="{ items: condition.typeItems, field: 'type', _isHeader: true }"
                                :propValue="condition.typeValue"
                                :rowIndex="index"
                                :ref="condition.id"
                                :validateArg="condition"
                                @change="matchRuleChangeHandle"
                            />
                            <select-field
                                v-show="condition.type === 'select'"
                                :options="{ items: condition.items, field: 'select', _isHeader: true }"
                                :propValue="condition.value"
                                :rowIndex="index"
                                :ref="`${condition.id}_select`"
                                :validateArg="condition"
                                @change="inputValueChangeHandle"
                            />
                            <template v-if="condition.type === 'date'">
                                <div class="section" v-if="condition.typeValue === 'DR'">
                                    <dateField
                                        :options="{ field: 'DGOE_type' }"
                                        :propValue="condition.DGOE"
                                        :rowIndex="index"
                                        :validateArg="condition"
                                        @change="inputValueChangeHandle"
                                    />
                                    -
                                    <dateField
                                        :options="{ field: 'DLOE_type' }"
                                        :propValue="condition.DLOE"
                                        :rowIndex="index"
                                        :validateArg="condition"
                                        @change="inputValueChangeHandle"
                                    />
                                </div>
                                <dateField
                                    v-else
                                    :options="{ field: 'type' }"
                                    :propValue="condition.value"
                                    :rowIndex="index"
                                    :validateArg="condition"
                                    @change="inputValueChangeHandle"
                                />
                            </template>

                            <template v-else-if="condition.type === 'dateTime'">
                                <div class="section" v-if="condition.typeValue === 'TR'">
                                    <dateTimeField
                                        :options="{ field: 'TGOE_type', type: 'dateTime' }"
                                        :propValue="condition.TGOE"
                                        :rowIndex="index"
                                        :validateArg="condition"
                                        @change="inputValueChangeHandle"
                                    />
                                    -
                                    <dateTimeField
                                        :options="{ field: 'TLOE_type', type: 'dateTime' }"
                                        :propValue="condition.TLOE"
                                        :rowIndex="index"
                                        :validateArg="condition"
                                        @change="inputValueChangeHandle"
                                    />
                                </div>
                                <dateTimeField
                                    v-else
                                    :options="{ field: 'type', type: 'dateTime' }"
                                    :propValue="condition.value"
                                    :rowIndex="index"
                                    :validateArg="condition"
                                    @change="inputValueChangeHandle"
                                />
                            </template>

                            <template v-else-if="condition.type === 'number'">
                                <div class="section" v-if="condition.typeValue === 'NR'">
                                    <numberField
                                        :options="{ field: 'NGOE_type', hasArrow: true }"
                                        :propValue="condition.NGOE"
                                        :rowIndex="index"
                                        :validateArg="condition"
                                        @change="inputValueChangeHandle"
                                    />
                                    -
                                    <numberField
                                        :options="{ field: 'NLOE_type', hasArrow: true }"
                                        :propValue="condition.NLOE"
                                        :rowIndex="index"
                                        :validateArg="condition"
                                        @change="inputValueChangeHandle"
                                    />
                                </div>
                                <numberField
                                    v-else
                                    :options="{ field: 'type', hasArrow: true }"
                                    :propValue="condition.value"
                                    :rowIndex="index"
                                    :validateArg="condition"
                                    @change="inputValueChangeHandle"
                                />
                            </template>

                            <input v-else-if="condition.type !== 'select'" v-model="condition.value" />
                            <div class="cancel-btn" @click="removeFilterRow(index)"></div>
                        </li>
                    </ul>
                </scroll>
            </section>
            <footer>
                <btn :options="{ text: $i18n('grid.generalQueryPanelReset'), onClick: reset }" />
                <btn :options="{ text: $i18n('grid.generalQueryPanelSearch'), onClick: refresh }" />
            </footer>
        </div>
    </div>
</template>

<script>
import Btn from '../../template/button';
import Gikam from 'gikam';

export default {
    components: { Btn },
    inject: ['grid'],

    props: {
        options: Object
    },

    computed: {
        fields() {
            const result = [];
            const filterType = ['number', 'select'];
            Gikam.deepExtend(this.grid.store.state.columns).forEach(item => {
                if (item.field && item.title) {
                    const field = item.field.toLowerCase().replace('ext$.', '');
                    const type = item.filter?.type
                        ? item.filter?.type
                        : item?.type && filterType.indexOf(item.type) > -1
                        ? item.type
                        : this.fieldTypeMapper[field];
                    result.push({
                        ...item,
                        value: item.field,
                        text: Gikam.propI18N(item.title),
                        type
                    });
                }
            });
            return result;
        },

        height() {
            let height = (this.conditions.length || 1) * 44;
            if (height < this.options.maxHeight) {
                return height + 'px';
            } else {
                return this.options.maxHeight + 'px';
            }
        }
    },

    data() {
        return {
            conditions: Gikam.deepExtend(this.options.conditions),
            requestData: Gikam.deepExtend(this.grid.options.requestData),
            fieldTypeMapper: this.grid.model.$store.state.fieldTypeMapper
        };
    },

    beforeDestroy() {
        this.$emit('change', this.conditions);
    },

    methods: {
        reset() {
            this.grid.options.requestData = Gikam.deepExtend(this.requestData);
            this.conditions = [];
        },

        refresh() {
            const requestData = {};
            this.conditions.forEach(item => {
                const { field, typeValue, value } = item;
                if (typeValue === 'NR') {
                    requestData[`${field}_NGOE`] = item.NGOE;
                    requestData[`${field}_NLOE`] = item.NLOE;
                } else if (typeValue === 'TR') {
                    requestData[`${field}_TGOE`] = item.TGOE;
                    requestData[`${field}_TLOE`] = item.TLOE;
                } else if (typeValue === 'DR') {
                    requestData[`${field}_DGOE`] = item.DGOE;
                    requestData[`${field}_DLOE`] = item.DLOE;
                } else {
                    requestData[`${field}_${typeValue}`] = value;
                }
            });
            Gikam.extend(this.grid.options.requestData, requestData);
            this.grid.refresh();
            this.$nextTick(() => {
                this.grid.options.requestData = Gikam.deepExtend(this.requestData);
                Gikam.genericQueryPicker.close();
            });
        },

        addFilterRow() {
            this.conditions.push({
                id: new Date().getTime(),
                type: 'string',
                field: '',
                typeItems: [],
                items: [],
                typeValue: '',
                value: '',
                NGOE: '',
                NLOE: '',
                TGOE: '',
                TLOE: '',
                DGOE: '',
                DLOE: ''
            });
        },

        removeFilterRow(index) {
            this.conditions.splice(index, 1);
        },

        getMatchRules(type = 'string') {
            const rules = {
                string: [
                    {
                        value: 'SB',
                        text: this.$i18n('grid.search.start')
                    },
                    {
                        value: 'SC',
                        text: this.$i18n('grid.search.contain')
                    },
                    {
                        value: 'SE',
                        text: this.$i18n('grid.search.ending')
                    },
                    {
                        value: 'SEQ',
                        text: this.$i18n('grid.search.equal')
                    }
                ],

                date: [
                    {
                        value: 'DG',
                        text: this.$i18n('grid.search.greater')
                    },
                    {
                        value: 'DGOE',
                        text: this.$i18n('grid.search.greater.equal')
                    },
                    {
                        value: 'DL',
                        text: this.$i18n('grid.search.less')
                    },
                    {
                        value: 'DLOE',
                        text: this.$i18n('grid.search.less.equal')
                    },
                    {
                        value: 'DEQ',
                        text: this.$i18n('grid.search.equal')
                    },
                    {
                        value: 'DR',
                        text: this.$i18n('grid.search.section')
                    }
                ],

                dateTime: [
                    {
                        value: 'TG',
                        text: this.$i18n('grid.search.greater')
                    },
                    {
                        value: 'TGOE',
                        text: this.$i18n('grid.search.greater.equal')
                    },
                    {
                        value: 'TL',
                        text: this.$i18n('grid.search.less')
                    },
                    {
                        value: 'TLOE',
                        text: this.$i18n('grid.search.less.equal')
                    },
                    {
                        value: 'TEQ',
                        text: this.$i18n('grid.search.equal')
                    },
                    {
                        value: 'TR',
                        text: this.$i18n('grid.search.section')
                    }
                ],

                number: [
                    {
                        value: 'NG',
                        text: this.$i18n('grid.search.greater')
                    },
                    {
                        value: 'NGOE',
                        text: this.$i18n('grid.search.greater.equal')
                    },
                    {
                        value: 'NL',
                        text: this.$i18n('grid.search.less')
                    },
                    {
                        value: 'NLOE',
                        text: this.$i18n('grid.search.less.equal')
                    },
                    {
                        value: 'NEQ',
                        text: this.$i18n('grid.search.equal')
                    },
                    {
                        value: 'NR',
                        text: this.$i18n('grid.search.section')
                    }
                ]
            };
            return rules[type];
        },

        fieldChangeHandle(field, value, index) {
            const item = this.fields.find(item => item.field === value);
            const { type, items } = item;
            this.conditions[index].field = value;
            this.conditions[index].value = '';
            if (type === 'select') {
                this.conditions[index].type = 'select';
                this.conditions[index].items = items;
                this.conditions[index].typeItems = [{ text: this.$i18n('grid.search.equal'), value: 'EQ' }];
                this.conditions[index].typeValue = 'EQ';
                this.$refs[this.conditions[index].id + '_select'][0].setItems(items);
                this.$refs[this.conditions[index].id][0].setItems(this.conditions[index].typeItems);
            } else if (type === 'date' || type === 'number' || type === 'dateTime') {
                this.conditions[index].type = type;
            } else {
                this.conditions[index].type = 'string';
            }
            if (type !== 'select') {
                this.conditions[index].typeItems = this.getMatchRules(this.conditions[index].type);
                this.$refs[this.conditions[index].id][0].setItems(this.conditions[index].typeItems);
            }
        },

        matchRuleChangeHandle(field, value, index) {
            const item = this.conditions[index];
            item.value = '';
            if (value === 'NR') {
                item.NGOE = '';
                item.NLOE = '';
            } else if (value === 'TR') {
                item.TGOE = '';
                item.TLOE = '';
            } else if (value === 'DR') {
                item.DGOE = '';
                item.DLOE = '';
            }
            item.typeValue = value;
        },

        inputValueChangeHandle(field, value, index) {
            const item = this.conditions[index];
            if (field === 'select') {
                item.value = value;
                return;
            }
            if (field === 'type') {
                item.value = value;
            } else if (field.indexOf('_type') > -1) {
                const type = field.split('_')[0];
                item[type] = value;
            }
        },

        generalGroupDestroyHandle() {
            top.Gikam.genericQueryPicker && top.Gikam.genericQueryPicker.close();
        }
    }
};
</script>

<style scoped>
@keyframes date-slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.grid-generic-query-panel {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    min-height: 115px;
    background-color: #fff;
    padding: 16px;
    z-index: 10;
    animation: date-slide-bottom 0.3s;
}

.grid-generic-query-panel > .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.grid-generic-query-panel section {
    padding-right: 8px;
}

.grid-generic-query-panel ul {
    margin: 0;
    padding: 0;
}

.grid-generic-query-panel ul > li {
    display: flex;
    align-items: center;
    line-height: 28px;
    height: 28px;
    margin-bottom: 16px;
}

.grid-generic-query-panel ul > li > .section {
    display: flex;
    flex-direction: row;
    height: 28px;
}

.grid-generic-query-panel ul > li input {
    line-height: 28px;
    height: 28px;
    width: 144px;
    padding-left: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.grid-generic-query-panel ul > li input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid-generic-query-panel ul > li .select {
    padding-right: 8px;
    width: 110px;
}

.grid-generic-query-panel ul > li .select:first-child {
    width: 140px;
}

.cancel-btn {
    width: 16px;
    height: 16px;
    background: url('../../../../img/grid-generic-query-cancel.png') no-repeat center;
    margin-left: 8px;
    cursor: pointer;
}

.toolbar {
    display: flex;
    /* height: 16px; */
    flex: 0 0 16px;
    align-items: center;
    margin-bottom: 16px;
}

.toolbar .select {
    height: 28px;
    width: 125px;
    padding-right: 8px;
}

.toolbar .add-row {
    width: 16px;
    height: 16px;
    background: url('../../../../img/grid-generic-query-add-row.png') no-repeat center;
    cursor: pointer;
}

footer {
    /* height: 28px; */
    flex: 0 0 28px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

footer >>> .button {
    height: 28px;
    width: 64px;
    display: flex;
    justify-content: center;
    margin-left: 16px;
}

footer >>> .button:last-child {
    background-color: #007aff;
    color: #fff;
}

.grid-generic-query-panel ul > li > *:nth-child(3):not(.cancel-btn),
.grid-generic-query-panel ul > li > *:nth-child(4):not(.cancel-btn) {
    width: 185px;
    padding-right: 0;
}
</style>
