import Vuex from 'vuex';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core';
import handleColumns from './columns.js';

Vue.use(Vuex);

class GridStore {
    constructor(options, grid) {
        this.options = options;
        this.grid = grid;
        this.initStore();
    }

    refreshGrid(data) {
        this.grid.setOptions(data);
        Gikam.finalDelay(
            'searchGrid',
            () => {
                this.grid.refresh(data);
            },
            100
        );
    }

    pageSearch(data) {
        this.grid.$setPageRequest({
            field: data.args[0],
            value: data.args[1],
            column: data.column,
            selectText: data.args[4] || null
        });
        Gikam.finalDelay(
            'searchGrid',
            () => {
                this.grid.pageSearchGrid();
            },
            100
        );
    }

    initStore() {
        const _this = this;
        this.options.columns = this.getColumns();
        this.store = new Vuex.Store({
            state: {
                requestData: Gikam.deepExtend(this.options.requestData),
                headRequestData: this.options.headRequestData,
                fieldTypeMapper: null,
                columns: this.options.columns,
                gridId: this.options.id,
                $window: this.grid.$window,
                pageNum: this.options.pageNum,
                pageSize: this.options.pageSize,
                page: this.options.page,
                pageList: this.options.pageList,
                totalRecord: 0,
                width: this.options.width,
                headerHeight: 0,
                scrollTop: 0,
                scrollLeft: 0,
                columnsFill: this.options.columnsFill,
                defaultColumnWidth: this.options.defaultColumnWidth,
                allowWidth: this.options.allowWidth,
                renderTo: this.options.renderTo,
                readonly: this.options.readonly,
                contextmenu: this.options.contextmenu,
                totalMap: {},
                activeCoordinate: [0, 0],
                // 正在编辑的字段信息
                editingChildren: {},
                // TreeGrid中勾选节点容器，不包含半选状态
                checkedTreeNodes: []
            },

            getters: {
                totalPage(state) {
                    return Math.ceil(state.totalRecord / state.pageSize) || 1;
                },
                defaultColumns() {
                    return _this.grid.$rawColumns;
                }
            },

            mutations: {
                refresh(_state, data) {
                    _this.refreshGrid(data);
                },

                pageSearch(_state, data) {
                    _this.pageSearch(data);
                },

                refreshColumns(state, columns) {
                    handleColumns(this, columns);
                    state.columns = columns;
                    _this.options.columns = columns;
                    state.chooseColumns.columnsSelectIndex = null;
                },

                refreshColumnsConfig(state, columns) {
                    // 对于已经配置的字段，需要同步列配置
                    _this.processSettingColumns(columns);
                    _this.store.commit('refreshColumns', columns);
                },

                refreshTypeMapper(state, Mapper) {
                    state.fieldTypeMapper = Mapper;
                },

                updatePageSize(state, pageSize) {
                    state.pageSize = pageSize;
                    state.pageNum = 1;
                    _this.refreshGrid();
                },

                updatePageNum(state, pageNum) {
                    state.pageNum = pageNum;
                    _this.refreshGrid();
                },

                updateTotalRecord(state, totalRecord) {
                    state.totalRecord = totalRecord;
                },

                changeColumnsWidth(state, width) {
                    state.width = width;
                },

                changeHeaderHeight(state, height) {
                    state.headerHeight = height;
                },

                changeScrollTop(state, scrollTop) {
                    state.scrollTop = scrollTop;
                },

                changeScrollLeft(state, scrollLeft) {
                    state.scrollLeft = scrollLeft;
                },

                updateContextmenu(state, menu) {
                    state.contextmenu = menu;
                },

                changeActiveCoordinate(state, coordinate) {
                    if (Array.isArray(coordinate)) {
                        state.activeCoordinate = coordinate;
                    } else {
                        state.activeCoordinate = null;
                    }
                },

                changeEditor(state, editing) {
                    if (editing && Array.isArray(editing.coordinate)) {
                        state.editingChildren = editing;
                    } else {
                        state.editingChildren = {};
                    }
                },

                changeRequestData(state, requestData) {
                    state.requestData = Gikam.deepExtend(state.requestData, requestData);
                },

                updateHeadRequestData(state, data) {
                    const headRequestData = state.headRequestData;
                    for (let field in data) {
                        if (Gikam.isEmpty(data[field])) {
                            for (let headField in headRequestData) {
                                if (headField.split('_')[0] === field.split('_')[0]) {
                                    Vue.delete(headRequestData, field);
                                }
                            }
                        } else {
                            Vue.set(headRequestData, field, data[field]);
                        }
                    }
                },

                addCheckedTreeNodes(state, node) {
                    state.checkedTreeNodes.push(node);
                    state.checkedTreeNodes.sort((a, b) => {
                        return a.id - b.id;
                    });
                },

                removeCheckedTreeNodes(state, node) {
                    const index = state.checkedTreeNodes.indexOf(node);
                    index > -1 && state.checkedTreeNodes.splice(index, 1);
                },

                cleanCheckedTreeNodes(state) {
                    state.checkedTreeNodes.length = 0;
                }
            },

            modules: {
                chooseColumns: {
                    namespaced: true,
                    state: {
                        columnsSelectIndex: null
                    },
                    mutations: {
                        changeColumnsSelectIndex(state, number) {
                            if (Gikam.isNumber(number)) {
                                state.columnsSelectIndex = number;
                            } else {
                                state.columnsSelectIndex = null;
                            }
                        }
                    }
                },

                columnsGroup: {
                    namespaced: true,
                    state: {
                        groupMap: {},
                        relationship: {}
                    },
                    mutations: {
                        changeGroupMap(state, groupMap) {
                            state.groupMap = groupMap;
                        },

                        changeRelationship(state, relationship) {
                            state.relationship = relationship;
                        }
                    }
                }
            }
        });
    }

    /**
     * @description 获取表格列，优先从配置文件中获取
     * @memberof Grid
     * @returns Array
     */
    getColumns() {
        const columns = Gikam.deepExtend(this.options.columns);
        if (this.options.useStorageColumns === false) {
            return columns;
        }
        const manager = Gikam.compConfigManager;
        const gridId = this.grid.options.id;
        const preColumns = [];
        if (manager.grid && manager.grid[gridId]) {
            let settingColumns = Gikam.deepExtend(manager.grid[gridId].columns);
            for (let i = settingColumns.length - 1; i > -1; i--) {
                if (settingColumns[i].orderNo) {
                    delete settingColumns[i].orderNo;
                }
                const filterColumn = columns.filter(item => item.field === settingColumns[i].field);
                if (Gikam.isEmpty(filterColumn)) {
                    //删除的列去除
                    settingColumns.splice(i, 1);
                } else {
                    settingColumns.splice(i, 1, Gikam.extend(filterColumn[0], settingColumns[i]));
                }
            }
            //新增的列追加
            columns.forEach(item => {
                if (item.checkbox || item.radio || item.index === true) {
                    preColumns.push(item);
                    return;
                }
                if (!settingColumns.some(settingColumn => settingColumn.field === item.field)) {
                    settingColumns.push(item);
                }
            });
            return [...preColumns, ...settingColumns];
        }
        return columns;
    }

    processSettingColumns(dynamicColumns) {
        const columns = Gikam.deepExtend(this.options.columns);
        const filedMapper = {};
        columns.forEach(column => {
            if (column.field) {
                filedMapper[column.field] = column;
            }
        });
        dynamicColumns.forEach(column => {
            if (filedMapper[column.field]) {
                const oldColumn = filedMapper[column.field];
                column.visible = oldColumn.visible;
                column.fixed = oldColumn.fixed;
                column.title = oldColumn.title;
            }
        });
    }
}

export { GridStore };
