import Vue from 'vue';
const xss = require('xss');
//HTML 全局属性 全局属性是可与所有 HTML 元素一起使用的属性
const commonAttribute = [
    'accesskey',
    'class',
    'contenteditable',
    'contextmenu',
    'data-*',
    'dir',
    'draggable',
    'dropzone',
    'hidden',
    'id',
    'lang',
    'spellcheck',
    'style',
    'tabindex',
    'title',
    'translate',
    //文本类空格
    '&nbsp'
];

export const customXss = new xss.FilterXSS({
    whiteList: {
        a: ['href', 'onclick', ...commonAttribute], //定义锚。
        abbr: ['', ...commonAttribute], //定义缩写。
        acronym: ['', ...commonAttribute], //定义只取首字母的缩写。HTML5 中不支持。请使用 <abbr> 代替。
        address: ['', ...commonAttribute], //定义文档作者或拥有者的联系信息。
        applet: ['', ...commonAttribute], //定义嵌入的 applet。HTML5 中不支持。请使用 <embed> 和 <object> 代替。
        area: ['', ...commonAttribute], //定义图像映射内部的区域。
        article: ['', ...commonAttribute], //定义文章。
        aside: ['', ...commonAttribute], //定义页面内容之外的内容。
        audio: ['', ...commonAttribute], //定义声音内容。
        b: ['', ...commonAttribute], //定义粗体字。
        base: ['', ...commonAttribute], //定义页面中所有链接的默认地址或默认目标。
        basefont: ['', ...commonAttribute], //定义页面中文本的默认字体、颜色或尺寸。HTML5 中不支持。请使用 CSS 代替。
        bdi: ['', ...commonAttribute], //定义文本的文本方向，使其脱离其周围文本的方向设置。
        bdo: ['', ...commonAttribute], //定义文字方向。
        big: ['', ...commonAttribute], //定义大号文本。HTML5 中不支持。请使用 CSS 代替。
        blockquote: ['', ...commonAttribute], //定义长的引用。
        body: ['', ...commonAttribute], //定义文档的主体。
        br: ['', ...commonAttribute], //定义简单的折行。
        button: ['', ...commonAttribute], //定义按钮 (push button)。
        canvas: ['', ...commonAttribute], //定义图形。
        caption: ['', ...commonAttribute], //定义表格标题。
        center: ['', ...commonAttribute], //定义居中文本。HTML5 中不支持。请使用 CSS 代替。
        cite: ['', ...commonAttribute], //定义引用(citation)。
        code: ['', ...commonAttribute], //定义计算机代码文本。
        col: ['', ...commonAttribute], //定义表格中一个或多个列的属性值。
        colgroup: ['', ...commonAttribute], //定义表格中供格式化的列组。
        command: ['', ...commonAttribute], //定义命令按钮。
        data: ['', ...commonAttribute], //添加给定内容的机器可读翻译。
        datalist: ['', ...commonAttribute], //定义下拉列表。
        dd: ['', ...commonAttribute], //定义定义列表中项目的描述。
        del: ['', ...commonAttribute], //定义被删除文本。
        details: ['', ...commonAttribute], //定义元素的细节。
        dir: ['', ...commonAttribute], //定义目录列表。HTML5 中不支持。请使用 CSS 代替。
        div: ['onclick', 'onClick', ...commonAttribute], //定义文档中的节。
        dfn: ['', ...commonAttribute], //定义定义项目。
        dialog: ['', ...commonAttribute], //定义对话框或窗口。
        dl: ['', ...commonAttribute], //定义定义列表。
        dt: ['', ...commonAttribute], //定义定义列表中的项目。
        em: ['', ...commonAttribute], //定义强调文本。
        embed: ['', ...commonAttribute], //定义外部交互内容或插件。
        fieldset: ['', ...commonAttribute], //定义围绕表单中元素的边框。
        figcaption: ['', ...commonAttribute], //定义 figure 元素的标题。
        figure: ['', ...commonAttribute], //定义媒介内容的分组，以及它们的标题。
        font: ['face', 'color', 'size', ...commonAttribute], //定义文字的字体、尺寸和颜色。HTML5 中不支持。请使用 CSS 代替。
        footer: ['', ...commonAttribute], //定义 section 或 page 的页脚。
        form: ['', ...commonAttribute], //定义供用户输入的 HTML 表单。
        frame: ['', ...commonAttribute], //定义框架集的窗口或框架。HTML5 中不支持。
        frameset: ['', ...commonAttribute], //定义框架集。HTML5 中不支持。
        h1: ['', ...commonAttribute], //字体大小
        h2: ['', ...commonAttribute], //字体大小
        h3: ['', ...commonAttribute], //字体大小
        h4: ['', ...commonAttribute], //字体大小
        h5: ['', ...commonAttribute], //字体大小
        h6: ['', ...commonAttribute], //字体大小
        head: ['', ...commonAttribute], //定义关于文档的信息。
        header: ['', ...commonAttribute], //定义 section 或 page 的页眉。
        hr: ['', ...commonAttribute], //定义水平线。
        html: ['', ...commonAttribute], //定义 HTML 文档。
        i: ['', ...commonAttribute], //定义斜体字。
        iframe: ['', ...commonAttribute], //定义内联框架。
        img: ['src', 'alt', 'width', 'height', ...commonAttribute], //定义图像。
        input: ['type', 'name', 'value', 'readonly', 'checked', 'disabled', 'onChange', 'onchange', ...commonAttribute], //定义输入控件。
        ins: ['', ...commonAttribute], //定义被插入文本。
        isindex: ['', ...commonAttribute], //定义与文档相关的可搜索索引。不赞成使用。
        kbd: ['', ...commonAttribute], //定义键盘文本。
        keygen: ['', ...commonAttribute], //定义生成密钥。
        label: ['', ...commonAttribute], //定义 input 元素的标注。
        legend: ['', ...commonAttribute], //定义 fieldset 元素的标题。
        li: ['', ...commonAttribute], //定义列表的项目。
        link: ['', ...commonAttribute], //定义文档与外部资源的关系。
        main: ['', ...commonAttribute], //定义文档的主要内容。
        map: ['', ...commonAttribute], //定义图像映射。
        mark: ['', ...commonAttribute], //定义有记号的文本。
        menu: ['', ...commonAttribute], //定义命令的列表或菜单。
        menuitem: ['', ...commonAttribute], //定义用户可以从弹出菜单调用的命令/菜单项目。
        meta: ['', ...commonAttribute], //定义关于 HTML 文档的元信息。
        meter: ['', ...commonAttribute], //定义预定义范围内的度量。
        nav: ['', ...commonAttribute], //定义导航链接。
        noframes: ['', ...commonAttribute], //定义针对不支持框架的用户的替代内容。HTML5 中不支持。
        noscript: ['', ...commonAttribute], //定义针对不支持客户端脚本的用户的替代内容。
        object: ['type', 'data', ...commonAttribute], //定义内嵌对象。
        ol: ['', ...commonAttribute], //定义有序列表。
        optgroup: ['', ...commonAttribute], //定义选择列表中相关选项的组合。
        option: ['value', 'selected', ...commonAttribute], //定义选择列表中的选项。
        output: ['', ...commonAttribute], //定义输出的一些类型。
        p: ['', ...commonAttribute], //定义段落。
        param: ['', ...commonAttribute], //定义对象的参数。
        pre: ['', ...commonAttribute], //定义预格式文本。
        progress: ['', ...commonAttribute], //定义任何类型的任务的进度。
        q: ['', ...commonAttribute], //定义短的引用。
        rp: ['', ...commonAttribute], //定义若浏览器不支持 ruby 元素显示的内容。
        rt: ['', ...commonAttribute], //定义 ruby 注释的解释。
        ruby: ['', ...commonAttribute], //定义 ruby 注释。
        s: ['', ...commonAttribute], //定义加删除线的文本。
        samp: ['', ...commonAttribute], //定义计算机代码样本。
        script: ['', ...commonAttribute], //定义客户端脚本。
        section: ['', ...commonAttribute], //定义 section。
        select: ['', ...commonAttribute], //定义选择列表（下拉列表）。
        small: ['', ...commonAttribute], //定义小号文本。
        source: ['', ...commonAttribute], //定义媒介源。
        span: ['', ...commonAttribute], //定义文档中的节。
        strike: ['', ...commonAttribute], //定义加删除线文本。HTML5 中不支持。请使用 <del> 或 <s> 代替。
        strong: ['', ...commonAttribute], //定义强调文本。
        style: ['', ...commonAttribute], //定义文档的样式信息。
        sub: ['', ...commonAttribute], //定义下标文本。
        summary: ['', ...commonAttribute], //为 <details> 元素定义可见的标题。
        sup: ['', ...commonAttribute], //定义上标文本。
        svg: ['', ...commonAttribute], //定义 SVG 图形的容器。
        table: ['cellpadding', 'cellspacing', 'border', ...commonAttribute], //定义表格。
        tbody: ['', ...commonAttribute], //定义表格中的主体内容。
        td: ['colspan', 'rowspan', ...commonAttribute], //定义表格中的单元。
        template: ['', ...commonAttribute], //定义用作容纳页面加载时隐藏内容的容器。
        textarea: ['', ...commonAttribute], //定义多行的文本输入控件。
        tfoot: ['', ...commonAttribute], //定义表格中的表注内容（脚注）。
        th: ['colspan', 'rowspan', ...commonAttribute], //定义表格中的表头单元格。
        thead: ['', ...commonAttribute], //定义表格中的表头内容。
        time: ['', ...commonAttribute], //定义日期/时间。
        title: ['', ...commonAttribute], //定义文档的标题。
        tr: ['', ...commonAttribute], //定义表格中的行。
        track: ['', ...commonAttribute], //定义用在媒体播放器中的文本轨道。
        tt: ['', ...commonAttribute], //定义打字机文本。HTML5 中不支持。请使用 CSS 代替。
        u: ['', ...commonAttribute], //定义下划线文本。
        ul: ['', ...commonAttribute], //定义无序列表。
        var: ['', ...commonAttribute], //定义文本的变量部分。
        video: ['', ...commonAttribute], //定义视频。
        wbr: ['', ...commonAttribute], //定义可能的换行符。
        xmp: ['', ...commonAttribute], //定义预格式文本。HTML5 中不支持。
        'v:line': ['id', 'style', 'to'],
        'v:shape': ['type']
    },

    css: {
        whiteList: {
            'align-content': true, //规定弹性容器内的行之间的对齐方式，当项目不使用所有可用空间时。
            'align-items': true, //规定弹性容器内项目的对齐方式。
            'align-self': true, //规定弹性容器内所选项目的对齐方式。
            all: true, //重置所有属性（除了 unicode-bidi 和 direction）。
            animation: true, //所有 animation-* 属性的简写属性。
            'animation-delay': true, //规定开始动画的延迟。
            'animation-direction': true, //规定动画是向前播放、向后播放还是交替播放。
            'animation-duration': true, //规定动画完成一个周期应花费的时间。
            'animation-fill-mode': true, //规定元素在不播放动画时（在开始之前、结束之后、或同时）的样式。
            'animation-iteration-count': true, //规定动画的播放次数。
            'animation-name': true, //规定 @keyframes 动画的名称。
            'animation-play-state': true, //规定动画是播放还是暂停。
            'animation-timing-function': true, //规定动画的速度曲线。

            'backface-visibility': true, //定义当面对用户时元素的背面是否应可见。
            background: true, //所有 background-* 属性的简写属性。
            'background-attachment': true, //设置背景图像是与页面的其余部分一起滚动还是固定的。
            'background-blend-mode': true, //规定每个背景图层（颜色/图像）的混合模式。
            'background-clip': true, //定义背景（颜色或图像）应在元素内延伸的距离。
            'background-color': true, //规定元素的背景色。
            'background-image': true, //规定元素的一幅或多幅背景图像。
            'background-origin': true, //规定背景图像的初始位置。
            'background-position': true, //规定背景图像的位置。
            'background-repeat': true, //设置是否以及如何重复背景图像。
            'background-size': true, //规定背景图像的尺寸。
            border: true, //border-width、border-style 以及 border-color 的简写属性。
            'border-bottom': true, //border-bottom-width、border-bottom-style 以及 border-bottom-color 的简写属性。
            'border-bottom-color': true, //设置下边框的颜色。
            'border-bottom-left-radius': true, //定义左下角的边框圆角。
            'border-bottom-right-radius': true, //定义右下角的边框圆角。
            'border-bottom-style': true, //设置下边框的样式。
            'border-bottom-width': true, //设置下边框的宽度。
            'border-collapse': true, //设置表格边框是折叠为单一边框还是分开的。
            'border-color': true, //设置四条边框的颜色。
            'border-image': true, //border-image-* 属性的简写属性。
            'border-image-outset': true, //规定边框图像区域超出边框的量。
            'border-image-repeat': true, //规定边框图像应重复、圆角、还是拉伸。
            'border-image-slice': true, //规定如何裁切边框图像。
            'border-image-source': true, //规定用作边框的图像的路径。
            'border-image-width': true, //规定边框图像的宽度。
            'border-left': true, //所有 border-left-* 属性的简写属性。
            'border-left-color': true, //设置左边框的颜色。
            'border-left-style': true, //设置左边框的样式。
            'border-left-width': true, //设置左边框的宽度。
            'border-radius': true, //四个 border-*-radius 属性的简写属性。
            'border-right': true, //所有 border-right-* 属性的简写属性。
            'border-right-color': true, //设置右边框的颜色。
            'border-right-style': true, //设置右边框的样式。
            'border-right-width': true, //设置右边框的宽度。
            'border-spacing': true, //设置相邻单元格边框之间的距离。
            'border-style': true, //设置四条边框的样式。
            'border-top': true, //border-top-width、border-top-style 以及 border-top-color 的简写属性。
            'border-top-color': true, //设置上边框的颜色。
            'border-top-left-radius': true, //定义左上角的边框圆角。
            'border-top-right-radius': true, //定义右上角的边框圆角。
            'border-top-style': true, //设置上边框的样式。
            'border-top-width': true, //设置上边框的宽度。
            'border-width': true, //设置四条边框的宽度。
            bottom: true, //设置元素相对于其父元素底部的位置。
            'box-decoration-break': true, //设置元素在分页符处的背景和边框的行为，或对于行内元素在换行符处的行为。
            'box-shadow': true, //将一个或多个阴影附加到元素。
            'box-sizing': true, //定义元素的宽度和高度的计算方式：它们是否应包含内边距和边框。
            'break-after': true, //规定指定元素之后是否应出现 page-、column- 或 region-break。
            'break-before': true, //规定指定元素之前是否应出现 page-、column- 或 region-break。
            'break-inside': true, //规定指定元素内部是否应出现 page-、column- 或 region-break。

            'caption-side': true, //规定表格标题的放置方式。
            'caret-color': true, //规定光标在 input、textarea 或任何可编辑元素中的颜色。
            '@charset': true, //规定样式表中使用的字符编码。
            clear: true, //规定不允许在元素的哪一侧浮动元素
            clip: true, //剪裁绝对定位的元素。
            'clip-path': true, //将元素裁剪为基本形状或 SVG 源。
            color: true, //设置文本的颜色。
            'column-count': true, //规定元素应分为的列数。
            'column-fill': true, //指定如何填充列（是否 balanced）。
            'column-gap': true, //规定列间隙。
            'column-rule': true, //所有 column-rule-* 属性的简写属性。
            'column-rule-color': true, //规定列之间//规则的颜色。
            'column-rule-style': true, //规定列之间的//规则样式。
            'column-rule-width': true, //规定列之间的//规则宽度。
            'column-span': true, //规定元素应该跨越多少列。
            'column-width': true, //规定列宽度。
            columns: true, //column-width 和 column-count 的简写属性。
            content: true, //与 :before 和 :after 伪元素一起使用，来插入生成的内容。
            'counter-increment': true, //增加或减少一个或多个 CSS 计数器的值。
            'counter-reset': true, //创建或重置一个或多个 CSS 计数器。
            cursor: true, //规定当指向元素时要显示的鼠标光标。

            direction: true, //规定文本方向/书写方向。
            display: true, //规定如何显示某个 HTML 元素。

            'empty-cells': true, //规定是否在表格中的空白单元格上显示边框和背景。

            filter: true, //定义元素显示之前的效果（例如，模糊或颜色偏移）。
            flex: true, //flex-grow、flex-shrink 以及 flex-basis 的简写属性。
            'flex-basis': true, //规定弹性项目的初始长度。
            'flex-direction': true, //规定弹性项目的方向。
            'flex-flow': true, //flex-direction 和 flex-wrap 的简写属性。
            'flex-grow': true, //规定项目相对于其余项目的增量。
            'flex-shrink': true, //规定项目相对于其余项目的减量。
            'flex-wrap': true, //规定弹性项目是否应该换行。
            float: true, //规定是否应该对盒（box）进行浮动。
            font: true, //font-style、font-variant、font-weight、font-size/line-height 以及 font-family 的简写属性。
            '@font-face': true, //允许网站下载和使用 "web-safe" 字体以外的其他字体的//规则。
            'font-family': true, //规定文本的字体族（字体系列）。
            'font-feature-settings': true, //允许控制 OpenType 字体中的高级印刷特性。
            '@font-feature-values': true, //允许创作者使用 font-variant-alternate 中的通用名来实现在 OpenType 中以不同方式激活的特性。
            'font-kerning': true, //控制字距调整信息的使用（字母间距）。
            'font-language-override': true, //控制特定语言的字形在字体的使用。
            'font-size': true, //规定文本的字体大小。
            'font-size-adjust': true, //保持发生字体回退时的可读性。
            'font-stretch': true, //从字体系列中选择一个普通的、压缩的或扩展的字体。
            'font-style': true, //规定文本的字体样式。
            'font-synthesis': true, //控制哪些缺失的字体（粗体或斜体）可以由浏览器合成。
            'font-variant': true, //规定是否应该以小型大写字体显示文本。
            'font-variant-alternates': true, //控制与 @font-feature-values 中定义的备用名称关联的备用字形的使用。
            'font-variant-caps': true, //控制大写字母的备用字形的使用。
            'font-variant-east-asian': true, //控制东亚文字（例如中文和日语）的备用字形的使用。
            'font-variant-ligatures': true, //控制在适用于元素的文本内容中使用哪些连字和上下文形式。
            'font-variant-numeric': true, //控制数字、分数和序号标记的备用字形的使用。
            'font-variant-position': true, //控制较小字体的替代字形的使用，这些字形相对于字体基线定位为上标或下标。
            'font-weight': true, //规定字体的粗细。

            grid: true, //grid-template-rows、grid-template-columns、grid-template-areas、grid-auto-rows、grid-auto-columns 以及 grid-auto-flow 属性的简写属性。
            'grid-area': true, //规定网格项的名称，也可以是 grid-row-start、grid-column-start、grid-row-end 以及 grid-column-end 属性的简写属性。
            'grid-auto-columns': true, //规定默认的列尺寸。
            'grid-auto-flow': true, //规定如何在网格中插入自动放置的项目。
            'grid-auto-rows': true, //规定默认的行尺寸。
            'grid-column': true, //grid-column-start 和 grid-column-end 属性的简写属性。
            'grid-column-end': true, //规定如何结束网格项目。
            'grid-column-gap': true, //规定列间隙的尺寸。
            'grid-column-start': true, //规定网格项目从何处开始。
            'grid-gap': true, //grid-row-gap 和 grid-column-gap 的简写属性。
            'grid-row': true, //grid-row-start 和 grid-row-end 属性的简写属性。
            'grid-row-end': true, //规定网格项目在何处结束。
            'grid-row-gap': true, //规定列间隙的尺寸。
            'grid-row-start': true, //规定网格项目从何处开始。
            'grid-template': true, //grid-template-rows、grid-template-columns 以及 grid-areas 属性的简写属性。
            'grid-template-areas': true, //规定如何使用命名的网格项显示列和行。
            'grid-template-columns': true, //指定列的尺寸以及网格布局中的列数。
            'grid-template-rows': true, //指定网格布局中的行的尺寸。

            'hanging-punctuation': true, //规定是否可以在行框外放置标点符号。
            height: true, //设置元素的高度。
            hyphens: true, //设置如何分割单词以改善段落的布局。

            'image-rendering': true, //当图像被缩放时，向浏览器提供关于保留图像的哪些最重要的方面的信息。
            //'@import': true, //允许您将样式表导入另一张样式表。
            isolation: true, //定义元素是否必须创建新的堆叠内容。

            'justify-content': true, //规定项目在弹性容器内的对齐方式，当项目未用到所有可用空间时。

            '@keyframes': true, //规定动画代码。

            left: true, //规定定位元素的左侧位置。
            'letter-spacing': true, //增加或减少文本中的字符间距。
            'line-break': true, //如何如何/是否换行。
            'line-height': true, //设置行高。
            'list-style': true, //在一条声明中设置所有列表属性。
            'list-style-image': true, //把图像指定为列表项标记。
            'list-style-position': true, //规定列表项标记的位置。
            'list-style-type': true, //规定列表项标记的类型。

            margin: true, //在一条声明中设置所有外边距属性。
            'margin-bottom': true, //设置元素的下外边距。
            'margin-left': true, //设置元素的左外边距。
            'margin-right': true, //设置元素的右外边距。
            'margin-top': true, //设置元素的上外边距。
            mask: true, //通过在特定位置遮罩或剪切图像来隐藏元素。
            'mask-type': true, //规定将遮罩元素用作亮度或 Alpha 遮罩。
            'max-height': true, //设置元素的最大高度。
            'max-width': true, //设置元素的最大宽度。
            '@media': true, //为不同的媒体类型、设备、尺寸设置样式规则。
            'min-height': true, //设置元素的最小高度。
            'min-width': true, //设置元素的最小宽度。
            'mix-blend-mode': true, //规定元素内容应如何与其直接父的背景相混合。

            'object-fit': true, //规定替换元素的内容应如何适合其所用高度和宽度建立的框。
            'object-position': true, //指定替换元素在其框内的对齐方式。
            opacity: true, //设置元素的不透明等级。
            order: true, //设置弹性项目相对于其余项目的顺序。
            orphans: true, //设置在元素内发生分页时必须保留在页面底部的最小行数。
            outline: true, //outline-width、outline-style 以及 outline-color 属性的简写属性。
            'outline-color': true, //设置轮廓的颜色。
            'outline-offset': true, //对轮廓进行偏移，并将其绘制到边框边缘之外。
            'outline-style': true, //设置轮廓的样式。
            'outline-width': true, //设置轮廓的宽度。
            overflow: true, //规定如果内容溢出元素框会发生什么情况。
            'overflow-wrap': true, //规定浏览器是否可能为了防止溢出而在单词内折行（当字符串太长而无法适应其包含框时）。
            'overflow-x': true, //规定是否剪裁内容的左右边缘，如果它溢出了元素的内容区域。
            'overflow-y': true, //规定是否剪裁内容的上下边缘，如果它溢出了元素的内容区域。

            padding: true, //所有 padding-* 属性的简写属性。
            'padding-bottom': true, //设置元素的下内边距。
            'padding-left': true, //设置元素的左内边距。
            'padding-right': true, //设置元素的右内边距。
            'padding-top': true, //设置元素的上内边距。
            'page-break-after': true, //设置元素之后的分页（page-break）行为。
            'page-break-before': true, //设置元素之前的分页（page-break）行为。
            'page-break-inside': true, //设置元素内的分页（page-break）行为。
            perspective: true, //为 3D 定位元素提供透视。
            'perspective-origin': true, //定义用户观看 3D 定位元素的位置。
            'pointer-events': true, //定义元素是否对指针事件做出反应。
            position: true, //规定用于元素的定位方法的类型（静态、相对、绝对或固定）。

            quotes: true, //设置引号类型。

            resize: true, //定义用户是否以及如何调整元素的尺寸。
            right: true, //规定定位元素的左侧位置。

            'scroll-behavior': true, //规定可滚动框中是否平滑地滚动，而不是直接跳跃。

            'tab-size': true, //规定制表符的宽度。
            'table-layout': true, //定义用于对单元格、行和列进行布局的算法。
            'text-align': true, //规定文本的水平对齐方式。
            'text-align-last': true, //描述当 text-align 为 "justify" 时，如何在强制换行之前对齐块或行的最后一行。
            'text-combine-upright': true, //将多个字符组合到到单个字符的空间中。
            'text-decoration': true, //规定文本装饰。
            'text-decoration-color': true, //规定文本装饰（text-decoration）的颜色。
            'text-decoration-line': true, //规定文本装饰（text-decoration）中的的行类型。
            'text-decoration-style': true, //规定文本装饰（text-decoration）中的行样式。
            'text-indent': true, //规定文本块（text-block）中的的首行缩进。
            'text-justify': true, //规定当 text-align 为 "justify" 时使用的对齐方法。
            'text-orientation': true, //定义行中的文本方向。
            'text-overflow': true, //规定当文本溢出包含元素时应该发生的情况。
            'text-shadow': true, //添加文本阴影。
            'text-transform': true, //控制文本的大写。
            'text-underline-position': true, //规定使用 text-decoration 属性设置的下划线的位置。
            top: true, //规定定位元素的顶端位置。
            transform: true, //向元素应用 2D 或 3D 转换。
            'transform-origin': true, //允许您更改转换元素的位置。
            'transform-style': true, //规定如何在 3D 空间中渲染嵌套的元素。
            transition: true, //所有 transition-* 属性的简写属性。
            'transition-delay': true, //规定合适开始过渡效果。
            'transition-duration': true, //规定完成过渡效果所需的秒或毫秒数。
            'transition-property': true, //规定过渡效果对应的 CSS 属性的名称。
            'transition-timing-function': true, //规定过渡效果的速度曲线。

            'unicode-bidi': true, //与 direction 属性一起使用，设置或返回是否应覆写文本来支持同一文档中的多种语言。
            'user-select': true, //规定是否能选取元素的文本。

            'vertical-align': true, //设置元素的垂直对齐方式。
            visibility: true, //规定元素是否可见。

            'white-space': true, //规定如何处理元素内的空白字符。
            widows: true, //设置如果元素内发生分页，必须在页面顶部保留的最小行数。
            width: true, //设置元素的宽度。
            'word-break': true, //规定单词到达行末后如何换行。
            'word-spacing': true, //增加或减少文本中的单词间距。
            'word-wrap': true, //允许长的、不能折行的单词换到下一行。
            'writing-mode': true, //规定文本行是水平还是垂直布局。

            'z-index': true //设置定位元素的堆叠顺序。
        }
    },

    // svg图标的xss配置
    onTag(tag, html) {
        const allowTag = ['svg', 'path', 'rect', 'g', 'br', 'text', 'tspan', 'image', 'use', 'symbol', 'circle'];
        if (allowTag.includes(tag.toLocaleLowerCase())) {
            return html;
        }
    },

    onTagAttr(tag, name, value, isWhiteAttr) {
        const tagName = tag.toLocaleLowerCase();
        const attrName = name.toLocaleLowerCase();
        if (!isWhiteAttr) {
            return;
        }
        if ((tagName === 'img' && attrName === 'src') || (tagName === 'a' && attrName === 'href')) {
            return `${name}="${value}"`;
        }
    }
});

Vue.mixin({
    methods: {
        $xss(text) {
            return customXss.process(text?.toString());
        }
    }
});
