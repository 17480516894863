import { render, staticRenderFns } from "./tab.vue?vue&type=template&id=16736bc7&scoped=true&"
import script from "./tab.vue?vue&type=script&lang=js&"
export * from "./tab.vue?vue&type=script&lang=js&"
import style0 from "./tab.vue?vue&type=style&index=0&id=16736bc7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16736bc7",
  null
  
)

export default component.exports