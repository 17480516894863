<template>
    <div class="grid-footer-container" v-show="showBody" v-if="page">
        <scroll :scrollingY="false">
            <div class="grid-footer">
                <div class="grid-page-toolbar">
                    <div
                        :title="$i18n('grid.lastPage')"
                        class="button last-page"
                        :class="{ disabled: currentNum === '1' }"
                        @click="toPage(currentNum - 1)"
                    >
                        <lastPageImg v-bind="{ color: '#999', width: '14px', height: '14px' }" />
                    </div>
                    <input class="page-num-input" v-model.lazy.trim="currentNum" />
                    <span class="total-pages">&nbsp;/&nbsp;{{ totalPage }}&nbsp;</span>
                    <div
                        :title="$i18n('grid.nextPage')"
                        class="button next-page"
                        :class="{ disabled: currentNum === totalPage + '' }"
                        @click="toPage(currentNum - 1 + 2)"
                    >
                        <nextPageImg v-bind="{ color: '#999', width: '14px', height: '14px' }" />
                    </div>
                    <selectField
                        :options="{ items: pageList, value: pageSize }"
                        @change="pageSizeChangeHandle"
                    ></selectField>
                    <span class="total-record">{{
                        $i18n('grid.totalRecord') + ' ' + totalRecord + ' ' + $i18n('grid.totalRecordItem')
                    }}</span>
                </div>
            </div>
        </scroll>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: {
        showBody: Boolean
    },

    data() {
        return {
            currentNum: '1'
        };
    },

    computed: {
        ...mapState(['pageNum', 'totalRecord', 'page', 'pageList', 'pageSize']),
        ...mapGetters(['totalPage'])
    },

    watch: {
        pageNum: {
            handler(val) {
                this.currentNum = val + '';
            },
            immediate: true
        },

        currentNum(val, oldVal) {
            const _val = parseInt(val);
            if (_val > this.totalPage) {
                this.currentNum = this.totalPage + '';
            } else if (_val < 1 || isNaN(_val)) {
                this.currentNum = '1';
            } else if (_val === this.totalPage && oldVal > this.totalPage) {
                return;
            } else {
                this.$store.commit('updatePageNum', _val);
				this.$emit('pageChange');
            }
        }
    },

    methods: {
        toPage(num) {
            this.currentNum = num;
            this.$store.commit('changeScrollTop', 0);
			this.$emit('pageChange');
        },

        pageSizeChangeHandle(_field, value) {
            this.$store.commit('updatePageSize', value);
        }
    }
};
</script>

<style scoped>
.grid-footer-container {
    width: 100%;
    height: 36px;
    border: 1px solid #eee;
    border-top: none;
    padding-bottom: 2px;
}

.grid-footer {
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
}

.grid-footer > .grid-page-toolbar {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.grid-footer > .grid-page-toolbar > .button {
    border: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
}

.grid-footer > .grid-page-toolbar > .page-num-input {
    width: 42px;
    height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    text-align: center;
    color: #666;
    margin-right: 12px;
    margin-left: 13px;
    font-size: 12px;
}

.grid-footer > .grid-page-toolbar > .page-num-input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid-footer > .grid-page-toolbar > .total-record {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 16px;
}

.grid-footer > .grid-page-toolbar > .select {
    width: 100px;
    height: 24px;
    padding: 0;
    margin-left: 21px;
    margin-left: 12px;
}

.grid-footer > .grid-page-toolbar > .total-pages {
    margin-right: 10px;
}

.grid-footer > .grid-page-toolbar > .button.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.grid-footer > .grid-page-toolbar > .button.last-page,
.grid-footer > .grid-page-toolbar > .button.next-page {
    width: 20px;
    height: 24px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-footer > .grid-footer-toolbar {
    position: absolute;
    right: 28px;
    display: flex;
    align-items: center;
}

.grid-footer > .grid-footer-toolbar > .item {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 8px;
    cursor: pointer;
}
</style>
