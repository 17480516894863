<template>
    <div class="upload-file">
        <div class="filename">
            <div :class="{ errorName: fileState === 'fail' }">{{ file.name }}</div>
            <div class="error" v-if="fileState === 'fail'" :title="errorMessage"></div>
        </div>
        <div class="operate">
            <div
                class="reset"
                v-if="fileState === 'fail'"
                @click="resetFile"
                :title="$i18n('bulkUploads.file.reset')"
            ></div>
            <div class="loading" v-if="fileState === 'uploading' || fileState === 'start'">
                <span v-if="fileState === 'uploading'">{{ file.process + '%' }}</span>
            </div>
            <template v-if="fileState === 'success'">
                <div class="preview" @click="previewFile" :title="$i18n('bulkUploads.file.preview')"></div>
                <div class="download" @click="downloadFile" :title="$i18n('bulkUploads.file.download')"></div>
                <div
                    v-if="!readonly"
                    class="delete"
                    @click="deleteFile"
                    :title="$i18n('bulkUploads.file.delete')"
                ></div>
            </template>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../../core/gikam-core';

export default {
    props: ['file', 'readonly'],

    data() {
        return {};
    },

    computed: {
        fileState() {
            return this.file.state ?? 'success';
        },

        errorMessage() {
            return this.file.errorMessage;
        },
    },

    methods: {
        previewFile() {
            Gikam.openFile(this.file.id);
        },

        async downloadFile() {
            const blob = await fetch(Gikam.getContextUrl(this.file.downloadUrl)).then(res => res.blob());
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = objectUrl;
            a.download = this.file.name;
            a.click();
            a.remove();
        },

        deleteFile() {
            Gikam.confirm(
                this.$i18n('bulkUploads.file.confirm'),
                `${this.$i18n('bulkUploads.file.confirm.delete') + this.file.name}？`,
                () => {
                    Gikam.showMask();
                    Gikam.del(
                        `${Gikam.IFM_CONTEXT}/core/module/item/files`,
                        Gikam.getJsonWrapper(null, ['', [{ id: this.file.id }]]),
                    ).done(() => {
                        this.$emit('deleteFile', this.file.id);
                        Gikam.cleanMask();
                    });
                },
            );
        },

        resetFile() {
            const file = this.file;
            this.$emit('resetFile', file);
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-file {
    width: 100%;
    margin: 2px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    height: 18px;

    .filename {
        letter-spacing: 0;
        font-weight: 400;
        width: calc(100% - 55px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;

        .errorName {
            color: #d40000;
        }

        .error {
            width: 12px;
            height: 12px;
            margin-left: 8px;
            background-image: url(./img/error.svg);
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    .operate {
        width: 55px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > div {
            width: 12px;
            height: 12px;
            background-repeat: no-repeat;
            cursor: pointer;
            margin: 0 2px;
        }

        .loading {
            background-image: url(./img/uploading.svg);
            line-height: 12px;
            text-indent: -34px;
            letter-spacing: 1.2px;
        }

        .download {
            background-image: url(./img/download.svg);
        }

        .preview {
            background-image: url(./img/preview.svg);
        }

        .reset {
            background-image: url(./img/reset.svg);
        }

        .delete {
            background-image: url(./img/delete.svg);
        }
    }
}
</style>
