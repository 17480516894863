import Vuex from 'vuex';
import Vue from 'vue';
import Gikam from 'gikam';
import { addLeftTreeTip, addMoveIdentification, getLinearList, addComp } from './utils';
Vue.use(Vuex);

//组件上方工具栏根据实例模板显示情况
function getToolStore() {
    return {
        namespaced: true,
        state: {
            //type 控制les模板，les实例，eln实例，readonly有没有
            toolList: [
                {
                    text: '查看',
                    svg: 'view-img',
                    id: 'view',
                    bind: { width: '16px', height: '15px', color: '#29CF68' },
                    type: ['readonly']
                },
                {
                    text: '刷新',
                    svg: 'refresh-img',
                    id: 'refresh',
                    bind: { width: '16px', height: '15px', color: '#007AFF' },
                    type: ['lesInstances']
                },
                {
                    text: '编辑',
                    svg: 'edit-img',
                    id: 'edit',
                    bind: { width: '16px', height: '15px', color: '#29CF68' },
                    type: ['lesTem', 'lesInstances', 'elnInstances']
                },
                {
                    text: '删除',
                    svg: 'remove-img',
                    id: 'remove',
                    bind: { width: '15px', height: '15px', color: '#FF352E' },
                    type: ['lesTem', 'elnInstances']
                },
                {
                    text: '上移',
                    svg: 'move-up-img',
                    id: 'moveUp',
                    bind: { width: '15px', height: '15px', color: '#F9762B' },
                    type: ['lesTem', 'elnInstances']
                },
                {
                    text: '下移',
                    svg: 'move-down-img',
                    id: 'moveDown',
                    bind: { width: '15px', height: '15px', color: '#F08239' },
                    type: ['lesTem', 'elnInstances']
                },
                {
                    text: '升级',
                    svg: 'menu-switch-shortcut-img',
                    id: 'upgrade',
                    bind: { width: '15px', height: '15px', color: ' #009933' },
                    type: ['lesTem', 'elnInstances']
                },
                {
                    text: '降级',
                    svg: 'menu-switch-shortcut-img',
                    id: 'downgrade',
                    bind: { width: '15px', height: '15px', color: '#00aa33' },
                    rotate: true,
                    type: ['lesTem', 'elnInstances']
                },
                {
                    text: '审计跟踪',
                    svg: 'audit-img',
                    id: 'audit',
                    bind: { width: '16px', height: '16px', color: '#007aff' },
                    type: ['lesTem', 'lesInstances', 'elnInstances', 'readonly']
                },
                {
                    text: '打印',
                    svg: 'print-img',
                    id: 'print',
                    bind: { width: '15px', height: '15px', color: '#F06655' },
                    type: ['lesTem', 'lesInstances', 'elnInstances', 'readonly']
                },
                {
                    text: '下载',
                    svg: 'icon-img',
                    id: 'download',
                    bind: { width: '15px', height: '15px', color: '#007aff' },
                    type: ['lesTem', 'lesInstances', 'elnInstances', 'readonly']
                }
            ],
            tempType: 'lesTem',
            lesReadonly: false
        },

        getters: {
            allToolList(state) {
                const key = state.lesReadonly ? 'readonly' : state.tempType;
                return state.toolList.filter(item => item.type.indexOf(key) > -1);
            },

            showEastButton(state) {
                return state.tempType === 'lesTem' || state.tempType === 'elnInstances' ? true : false;
            },

            isReadonly(state) {
                return state.lesReadonly || false;
            },

            isInstances(state) {
                return state.tempType === 'elnInstances' || state.tempType === 'lesInstances' ? true : false;
            }
        },

        mutations: {
            changeTempType(state, arg) {
                if (arg === 'ELN') {
                    state.tempType = 'elnInstances';
                } else if (arg === 'LES') {
                    state.tempType = 'lesInstances';
                } else {
                    state.tempType = 'lesTem';
                }
            },
            changeReadonlyStates(state, arg) {
                state.lesReadonly = Gikam.isTrue(arg);
            }
        }
    };
}

//中间部分滚动条
function getCenterScroll() {
    return {
        namespaced: true,
        state: {
            scrollLeft: 0
        },
        mutations: {
            changeScrollLeft(state, number) {
                state.scrollLeft = number;
            }
        }
    };
}

function getLayoutWidth() {
    return {
        namespaced: true,
        state: {
            lesWidth: 0, //中间内容宽度
            eastWidth: 0, //中间内容宽度
            westWidth: 200 //中间内容宽度
        },
        mutations: {
            changeStoreMember(state, { key, value }) {
                let _value = 0;
                if (typeof value === 'string') {
                    _value = Number(value.slice(0, value.length - 2));
                } else if (!isNaN(value)) {
                    _value = value;
                }
                state[key] = _value;
            }
        }
    };
}

//添加组件按钮弹窗
function getModalStore() {
    return {
        namespaced: true,

        state: {
            typeList: [],
            allTemplate: [],
            coreComp: [
                'chapter',
                'ckeditor',
                'chemDoodle',
                'imgDraw',
                'spread',
                'imgEdit',
                'imgUpload',
                'diagramDraw',
                'baseInfo',
                'formOrQueryList'
            ]
        },

        getters: {
            compTypeNameMap(state) {
                const map = {};
                state.typeList.forEach(comp => {
                    map[comp.unitCategory] = comp.unitName;
                });
                return map;
            },

            outCompDefaultConfig(state) {
                const map = {};
                state.typeList.forEach(comp => {
                    comp.config && (map[comp.unitCategory] = comp.config);
                });
                return map;
            }
        },

        mutations: {
            changeTypeList(state, arg) {
                arg.forEach(item => {
                    item.unitCategory = item.id;
                });
                state.typeList = arg;
            },

            changeAllTemplate(state, arg) {
                state.allTemplate = arg;
            }
        }
    };
}

//审计跟踪
function getAuditStore() {
    return {
        namespaced: true,
        state: {
            currentConfig: {},
            currentCompId: null
        },
        getters: {
            config(state) {
                return state.currentConfig.config ? JSON.parse(state.currentConfig.config) : {};
            }
        },
        mutations: {
            changeCurrentCompId(state, id) {
                state.currentCompId = id;
            },
            changeCurrentConfig(state, currentConfig) {
                state.currentConfig = currentConfig;
            },
            cleanStore(state) {
                state.currentCompId = null;
                state.currentConfig = {};
            }
        }
    };
}

function getRemarkStore() {
    return {
        namespaced: true,
        state: {
            remarkData: []
        },
        mutations: {
            changeRemarkData(state, data) {
                state.remarkData = data;
            }
        }
    };
}

//存储添加的外部组件
function getOutCompConfig() {
    return {
        namespaced: true,
        state: {
            comps: []
        },
        getters: {
            showOutSeniorArray(state) {
                return state.comps.filter(comp => comp.configureButton !== false).map(comp => comp.unitCategory);
            }
        },
        mutations: {
            changeComps(state, comps) {
                state.comps = comps;
            }
        }
    };
}

//其他添加的事务
function getOtherSectionButtons() {
    return {
        namespaced: true,
        state: {
            section: {},
            activeSectionUnitCategory: ''
        },
        getters: {
            currentTypeSection(state) {
                return Gikam.isNotEmpty(state.activeSectionUnitCategory)
                    ? state.section[state.activeSectionUnitCategory]
                    : [];
            }
        },
        mutations: {
            changeStoreMember(state, { key, value }) {
                state[key] = value;
            }
        }
    };
}

//seniorConfig      需要保存的，

//dsConfigMap       富文本监听使用
// otherConfigMap   其他高级配置舰艇使用

//chapterSelect     章节替换使用
//baseInfoConfig    基础信息替换使用
//queryOrFormConfig                  表单表格监听使用
//queryOrFormConfigDisplayStyle     表单表格基础信息监听使用
function newSeniorConfig() {
    return {
        namespaced: true,
        state: {
            seniorConfig: {},
            dsConfigMap: [],
            otherConfigMap: [],
            chapterSelect: {},
            baseInfoConfig: {},
            queryOrFormConfig: {},
            queryOrFormConfigDisplayStyle: '',
            outCompConfig: {},

            editFlag: 'yes'
        },
        getters: {},
        mutations: {
            changeConfig(state, sectionConfig) {
                state.seniorConfig = sectionConfig;
            },
            updateConfig(state, { key, value }) {
                state.seniorConfig[key] = value;
            },
            changeStoreMember(state, { key, value }) {
                state[key] = value;
            }
        }
    };
}

export function initStore(options) {
    return new Vuex.Store({
        state: {
            options: options,
            componentConfig: options?.componentConfig || {},
            isInstances: false, //是否为les实例
            isReadonly: false, //是否只读
            bodyIsEdit: false,
            activeComp: {},
            contentCompArr: [],
            expendIds: new Map(),
            treeCompArr: [],
            annexGlobalData: [],
            monitorAnnexChange: false, // 监听单个模板附件变化，变化一次取反
            compName: [],
            centerChildrenComp: {},
            currentOutComp: null,
            lesInfo: {},
            allChildrenVue: {} //用于刷新组件
        },

        getters: {
            getLinerCompIdList(state) {
                return state.contentCompArr.map(item => ({ id: item.id }));
            },

            getActiveUnitBody(state) {
                return JSON.parse(state.activeComp.unitBody || '{}');
            },

            ckeditorConvertContent(state) {
                const key = state.tool.lesReadonly ? 'readonly' : state.tool.tempType;
                return state.componentConfig.ckeditorConvertContent
                    ? state.componentConfig.ckeditorConvertContent.includes(key)
                    : false;
            },

            operationPanel(state) {
                const key = state.tool.lesReadonly ? 'readonly' : state.tool.tempType;
                return state.componentConfig.closeOperationPanel
                    ? !state.componentConfig.closeOperationPanel.includes(key)
                    : true;
            },

            beforeUploadAnnexHooks(state) {
                return state.options.beforeUploadAnnex;
            },

            fullScreenComp(state) {
                return state.componentConfig.fullScreenComp || [];
            }
        },

        mutations: {
            changeLesInfo(state, arg) {
                state.lesInfo = arg;
            },
            changeInstances(state, arg) {
                state.isInstances = arg;
            },
            changeReadonly(state, arg) {
                state.isReadonly = arg;
            },
            changeCompName(state, comp) {
                state.compName = comp;
            },

            changeCurrentOutComp(state, comp) {
                state.currentOutComp = comp;
            },

            changeCenterChildrenComp(state, [id, comp]) {
                state.centerChildrenComp[id] = comp;
            },

            changeExpendsComp(state, { id, val }) {
                state.expendIds.set(id, val);
            },

            changeEditState(state, _state) {
                state.bodyIsEdit = _state;
            },

            changeActiveComp(state, arg) {
                let expend, _comp;
                if (arg.unitCategory) {
                    state.activeComp = arg;
                    expend = true;
                    _comp = arg;
                } else {
                    state.activeComp = arg.comp;
                    expend = arg.expend;
                    _comp = arg.comp;
                }
                if (expend && _comp.parentIds) {
                    state.contentCompArr.forEach(item => {
                        if (_comp.parentIds.indexOf(item.id) >= 0) {
                            item.expend = true;
                        }
                    });
                }
            },

            changAnnexMonitor(state) {
                state.monitorAnnexChange = !state.monitorAnnexChange;
            },

            // 设置全局附件面板的数据
            setAnnexData(state, data) {
                state.annexGlobalData = data;
            },

            initComp(state, contentList) {
                addLeftTreeTip(state.expendIds, contentList);
                addMoveIdentification(contentList);
                state.contentCompArr = getLinearList(contentList);
                state.treeCompArr = contentList;
            },

            addComp(state, comp) {
                if (!state.activeComp.unitCategory || !state.activeComp.id) {
                    state.contentCompArr.push(comp);
                } else {
                    if (state.activeComp.parentId !== 'null') {
                        comp.parentId = state.activeComp.parentId;
                    } else {
                        comp.parentId = '';
                    }

                    if (state.activeComp.unitCategory === 'chapter' && comp.unitCategory !== 'chapter') {
                        comp.parentId = state.activeComp.id;
                    } else {
                        comp.preUnitId = state.activeComp.id;
                    }

                    let _tipId;
                    if (state.activeComp.children) {
                        _tipId = state.activeComp.children[state.activeComp.children.length - 1].id;
                    } else {
                        _tipId = state.activeComp.id;
                    }
                    addComp(state.contentCompArr, _tipId, comp);
                }
                state.activeComp = comp;
            },

            deleteComp(state) {
                state.contentCompArr = state.contentCompArr.filter(item => item.isNew !== true);
                state.bodyIsEdit = false;
                state.activeComp = {};
            },

            saveVueComp(state, { id, val }) {
                state.allChildrenVue[id] = val;
            }
        },

        modules: {
            modal: getModalStore(),
            remark: getRemarkStore(),
            tool: getToolStore(),
            audit: getAuditStore(),
            centerScroll: getCenterScroll(),
            outComp: getOutCompConfig(),
            section: getOtherSectionButtons(),
            newSeniorConfig: newSeniorConfig(),
            layout: getLayoutWidth()
        }
    });
}
