import Gikam from '@/gikam/js/core/gikam-core';
import { I18N } from '@/gikam/i18n/I18N.js';
import progress from '../vue/progress/progress';

export default class GridExport {
    constructor(options) {
        this.def = Gikam.jQuery.Deferred();
        Object.assign(this, options);
        this.initParams();
        this.initHeader();
        this.export();
        this.exportAllNumber = true;
    }

    initParams() {
        delete this.params.s;
        this.params.dt = Gikam.propI18N(this.grigFileName || this.arg[0]) || I18N.prop('grid.exportResults');
    }

    initHeader() {
        this.header = {
            'GIKAM-EXCEL': '1',
            'GIKAM-REQUEST-MODE': 'sync'
        };
    }

    export() {
        if (this.arg[2]) {
            this.initChooseModel().done((filterColumns, state) => {
                this.exportMode = state;
                this.header['GIKAM-REQUEST-MODE'] = state;
                Gikam.isTrue(this.exportAllNumber) && (this.params.n = -1);
                const _columns = this.asyncGetColumns(filterColumns);
                this.columns = this.getMapColumns(_columns);
                this.download();
            });
        } else {
            this.exportMode = 'sync';
            const _columns = this.asyncGetColumns(this.store.state.columns);
            this.columns = this.getMapColumns(_columns);
            this.download();
        }
    }

    download() {
        if (this.exportMode === 'sync') {
            this.progress = new progress({
                id: 'excel-progress',
                download: this.syncDownload.bind(this)
            });
            this.syncDownload();
        } else if (this.exportMode === 'async') {
            this.asyncDownload();
        }
    }

    syncDownload() {
        this.getUrl()
            .done((downloadUrl, status, xhr) => {
                this.progress.stopProgress();
                this.progress.changePercentageNum(100);
                setTimeout(() => {
                    this.progress.closeProgress();
                    const url = xhr.getResponseHeader('GIKAM-EXCEL-URL') || downloadUrl;
                    url && Gikam.download(Gikam.IFM_CONTEXT + decodeURIComponent(url));
                }, 300);
            })
            .fail(() => {
                this.progress.stopProgress();
                this.progress.changeProgress('wrong');
            });
    }

    asyncDownload() {
        this.getUrl();
    }

    getUrl() {
        return Gikam.postText(this.url, Gikam.getJsonWrapper(this.params, ['', this.columns]), this.header);
    }

    getToolbar() {
        return [
            {
                type: 'button',
                text: I18N.prop('grid.cancel'),
                onClick: () => {
                    this.modal.close();
                    this.def.reject();
                }
            },
            // {
            //     type: 'button',
            //     text: '异步导出',
            //     onClick: () => {
            //         const filterColumns = this.grid.getSelections();
            //         if (filterColumns.length <= 0) {
            //             return;
            //         }
            //         this.modal.close();
            //         this.def.resolve(filterColumns, 'async');
            //     }
            // },
            {
                type: 'button',
                text: I18N.prop('grid.synchronousExport'),
                class: 'blue',
                onClick: () => {
                    const filterColumns = this.grid.getSelections();
                    if (filterColumns.length <= 0) {
                        return;
                    }
                    this.modal.close();
                    this.def.resolve(filterColumns, 'sync');
                }
            }
        ];
    }

    initChooseModel() {
        const { modal, layout } = Gikam.createSimpleModal({
            title: I18N.prop('grid.selectExportColumn'),
            width: 360,
            height: 500,
            toolbarFormatter: () => {
                return this.getToolbar();
            }
        });
        this.modal = modal;
        this.layout = layout;
        layout.model.$nextTick(() => {
            this.initGrid();
        });
        return this.def;
    }

    initGrid() {
        const grid = Gikam.create('grid', {
            type: 'grid',
            renderTo: this.layout.options.center.$dom,
            page: false,
            genericQuery: false,
            filter: false,
            toolbarAlign: 'left',
            generalButtonGroup: false,
            checkOneOnActive: false,
            toolbar: [{ type: 'form', fields: [] }],
            data: this.getGridData(),
            columns: this.getGridColumns()
        });
        this.grid = grid;
        grid.model.$nextTick(() => {
            grid.model.$refs.vm.allChecked = true;
            this.initForm();
        });
    }

    initForm() {
        const form = Gikam.create('form', {
            type: 'form',
            renderTo: this.grid.model.$refs.vm.$refs['toolbar_form_0'][0],
            titleWidthAuto: true,
            autoSave: false,
            triggerChangeFlag: true,
            fields: [
                {
                    title: I18N.prop('grid.exportContent'),
                    type: 'select',
                    field: 'exportAll',
                    value: '1',
                    readonly: this.params.n !== -1 ? false : true,
                    items: [
                        { text: I18N.prop('grid.exportThisPage'), value: '0' },
                        { text: I18N.prop('grid.exportAll'), value: '1' }
                    ],
                    onChange: (...arg) => {
                        if (arg[1] === '1') {
                            this.exportAllNumber = true;
                        } else {
                            this.exportAllNumber = false;
                        }
                    }
                }
            ]
        });
        this.form = form;
    }

    getGridData() {
        return this.store.state.columns.filter(item => Gikam.isNotEmpty(item.title) && item.visible !== '0');
    }

    getGridColumns() {
        return [
            { checkbox: true },
            { index: true },
            {
                title: '列名称',
                field: 'title',
                width: 240,
                formatter(rowIndex, cellValue) {
                    return Gikam.propI18N(cellValue);
                }
            }
        ];
    }

    asyncGetColumns(filterColumns) {
        return this.store.state.columns.filter(function(item) {
            return Gikam.isNotEmpty(item.field) && filterColumns.some(filterItem => item.field === filterItem.field);
        });
    }

    getMapColumns(columns) {
        return columns.map(item => {
            const filedDefine = {
                id: item.field,
                name: Gikam.propI18N(item.title),
                alignment: 'left',
                type: item.fieldType ? item.fieldType : this.getFieldType(item.field)
            };

            if (item.type === 'select') {
                let optionsMapper = {};
                item.items.forEach(item => {
                    optionsMapper[item.value] = item.text;
                });
                filedDefine.options = optionsMapper;
            }

            if (item.styleFormatter) {
                filedDefine.styleFormatter = item.styleFormatter + '';
            }

            return filedDefine;
        });
    }

    getFieldType(field) {
        const mapper = this.store.state.fieldTypeMapper;
        const type = mapper ? mapper[field.toLowerCase().replace('ext$.', '')] : 'string';
        return type;
    }
}
