import Gikam from './gikam-core';

export function openFile(fileId, readonly, otherParam, popupIsModal) {
    if (!fileId) return;
    const supportType = [
        {
            code: 'text',
            typeArray: [
                'doc',
                'docm',
                'docx',
                'dot',
                'dotm',
                'dotx',
                'epub',
                'fodt',
                'htm',
                'html',
                'mht',
                'odt',
                'ott',
                'pdf',
                'rtf',
                'txt',
                'djvu',
                'xps'
            ]
        },
        {
            code: 'spreadsheet',
            typeArray: ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
        },
        {
            code: 'presentation',
            typeArray: ['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx']
        }
    ];

    const pageOfficeSupportType = [
        {
            code: 'text',
            typeArray: ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'pdf', 'rtf']
        },
        {
            code: 'spreadsheet',
            typeArray: ['csv', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
        },
        {
            code: 'presentation',
            typeArray: ['ppt', 'pptm', 'pptx']
        }
    ];

    let sunwayofficeTypeStr = '';
    let pageOfficeTypeStr = '';
    Gikam.getJson(Gikam.IFM_CONTEXT + '/core/module/item/files/' + fileId).done(file => {
        supportType.forEach(item => {
            if (item.typeArray.indexOf(file.fileExt) !== -1) {
                sunwayofficeTypeStr = item.code;
            }
        });

        pageOfficeSupportType.forEach(item => {
            if (item.typeArray.indexOf(file.fileExt.toLocaleLowerCase()) !== -1) {
                pageOfficeTypeStr = item.code;
            }
        });

        const pdfView = Gikam.pdf && Gikam.pdf.view;
        const viewType = (Gikam.onlyoffice.viewType || 'sunwayoffice').toLocaleLowerCase();
        if (['pdfjs', 'pdfComment'].indexOf(pdfView) > -1 && file.fileExt.toLocaleLowerCase() === 'pdf') {
            Gikam.printFile(fileId, readonly, null, null, null, popupIsModal, otherParam);
        } else if (viewType === 'sunwayoffice' && sunwayofficeTypeStr) {
            Gikam.openOfficeFile({
                fileId: fileId,
                readonly: readonly,
                otherParam: otherParam ? otherParam : null,
                popupIsModal: popupIsModal
            });
        } else if (viewType === 'pageoffice' && pageOfficeTypeStr) {
            Gikam.openFileByPageOffice(fileId, readonly, file.downloadUrl);
        } else {
            Gikam.printFile(fileId, readonly, null, null, null, popupIsModal);
        }
    });
}

export function openOfficeFile(param) {
    const documentParam = {
        fileId: param.fileId,
        mode: param.readonly ? 'view' : 'edit',
        popupIsModal: param.popupIsModal
    };
    Gikam.isNotEmpty(param.otherParam)
        ? Gikam.openFileByOffice(documentParam, param.otherParam)
        : Gikam.openFileByOffice(documentParam);
}

/**
 * @description 打开office编辑页面
 * @public
 * @param {Object, Object} 编辑器参数
 * @memberof this
 */

export function openFileByOffice(options, value) {
    if (Gikam.isNotEmpty(value)) {
        options.otherParam = value;
    }
    return new Gikam.EditorOffice(options);
}

export function printFile(fileId, readonly, waterText, printFlag, pdfWaterFlag, popupIsModal, otherParam) {
    const pageUrl = Gikam.IFM_CONTEXT + '/core/module/item/files/page/open-file';
    const imgFile = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'svg',
        'webp',
        'bmp',
        'JPG',
        'JPEG',
        'PNG',
        'GIF',
        'SVG',
        'WEBP',
        'BMP'
    ];
    const param = {
        fileId: fileId, // 文件id
        readonly: readonly, // 是否只读，office文件只读的话不能加水印不能有打印按钮；
        waterText: escape(Gikam.propI18N(waterText)), // readonly为false的时候,office文件才会加上水印,office水印内容为所传的值；pdf水印内容后台处理
        printFlag: printFlag, // readonly为
        // false的时候才执行，是否打印，文件显示打印按钮的话为true
        waterFlag: pdfWaterFlag
    };
    let url = pageUrl + Gikam.param(param);

    const pdfView = Gikam.pdf && Gikam.pdf.view;

    Gikam.getConstantValue('FILESERVER_CLUSTER')
        .done(rt => {
            url += '&fileserverCluster=' + rt.constantValue;
        })
        .then(() => {
            return Gikam.getJson(Gikam.IFM_CONTEXT + '/core/module/item/files/' + fileId);
        })
        .done(file => {
            if (imgFile.indexOf(file.fileExt.toLocaleLowerCase()) > -1) {
                const modal = Gikam.create('modal', {
                    title: file.name,
                    toolbarTop: true,
                    showMaxBtn: false,
                    isModal: Gikam.isFalse(popupIsModal) ? false : true
                });
                Gikam.create('viewer', {
                    renderTo: modal.window.$dom,
                    images: [Gikam.IFM_CONTEXT + file.downloadUrl]
                });
            } else if (pdfView === 'pdfComment' && file.fileExt.toLocaleLowerCase() === 'pdf') {
                if (otherParam && otherParam.viewType === 'blank') {
                    window.open(Gikam.IFM_CONTEXT + `/core/module/item/files/page/pdf-comment-blank?fileId=${file.id}`);
                } else {
                    const modal = Gikam.create('modal', {
                        title: file.name,
                        toolbarTop: true,
                        showMaxBtn: false,
                        isModal: Gikam.isFalse(popupIsModal) ? false : true
                    });
                    Gikam.create('pdfView', {
                        fileId: file.id,
                        renderTo: modal.window.$dom
                    });
                }
            } else {
                Gikam.create('modal', {
                    title: file.name,
                    iframe: true,
                    isFullScreen: true,
                    src: url,
                    isModal: Gikam.isFalse(popupIsModal) ? false : true
                });
            }
        });
}

/**
 * @des 通过pageoffice打开文档方法
 * @param {*} fileId
 * @param {*} readonly
 */
export function openFileByPageOffice(fileId, readonly, fileUrl) {
    fileUrl = fileUrl && Gikam.getFileContextUrl(encodeURIComponent(fileUrl));
    if (!window.POBrowser) {
        const script = document.createElement('script');
        script.src = Gikam.IFM_CONTEXT + '/pageoffice.js';
        script.onload = function() {
            const poiframe1 = document.getElementById('poiframe1');
            poiframe1.onload = function() {
                if (fileId) {
                    window.POBrowser.openWindowModeless(
                        Gikam.IFM_CONTEXT + `/open/page-office/document?fileId=${fileId}&readonly=${readonly}`,
                        'width=1200px;height=800px;'
                    );
                } else {
                    window.POBrowser.openWindowModeless(
                        Gikam.IFM_CONTEXT + `/open/page-office/document?fileUrl=${fileUrl}&readonly=true`,
                        'width=1200px;height=800px;'
                    );
                }
            };
        };
        document.body.appendChild(script);
    } else {
        if (fileId) {
            window.POBrowser.openWindowModeless(
                Gikam.IFM_CONTEXT + `/open/page-office/document?fileId=${fileId}&readonly=${readonly}`,
                'width=1200px;height=800px;'
            );
        } else {
            window.POBrowser.openWindowModeless(
                Gikam.IFM_CONTEXT + `/open/page-office/document?fileUrl=${fileUrl}&readonly=true`,
                'width=1200px;height=800px;'
            );
        }
    }
}
