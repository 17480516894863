<template>
    <div class="link readonly" @click.stop="clickHandle" :name="field">
        <div class="link-text" v-html="$xss(value)"></div>
        <linkImg class="link-icon" color="#777" />
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
export default {
    props: {
        options: Object,
        propValue: String
    },

    data() {
        return {
            field: this.options.field,
            value: Gikam.isEmpty(this.options.text)
                ? Gikam.isEmpty(this.propValue)
                    ? this.options.value
                    : this.propValue
                : this.options.text
        };
    },

    watch: {
        propValue(value) {
            this.value = Gikam.isEmpty(value) ? this.value : value;
        }
    },

    methods: {
        clickHandle() {
            this.$emit('click', this.options.field, this.value);
        }
    }
};
</script>
<style scoped>
.link {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
    cursor: pointer;
}

.link:active {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.link .link-text {
    width: 100%;
    display: flex;
    align-items: center;
}

.link .link-text >>> a {
    color: inherit;
    text-decoration: none;
}

.link .link-icon {
    width: 14px;
    margin-right: 8px;
}
</style>
