<template>
    <div class="tree-grid-node">
        <div class="tree-grid-node-blank" v-for="index of row.lvl" :title="index" :key="index"></div>
        <span v-if="noChildren" class="noswitch"></span>
        <span v-else class="switch" :class="{ 'expend-node': isExpend }" @click.stop="toggleNode"></span>
        <div
            :is="single ? 'radio' : 'checkbox'"
            @change="changeHandle"
            :propChecked="checked"
            :propHalfChecked="halfCheck"
            v-show="!fieldOptions.hideBox && grid.options.checkbox"
        ></div>

        <span v-if="htmlRender" class="node-text" v-html="text"></span>
        <span class="node-text" v-else>{{ text }}</span>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import { mapMutations } from 'vuex';

export default {
    props: {
        row: Object,
        single: Boolean,
        fieldOptions: Object,
        rowIndex: Number,
        checkedNodes: Array,
        checked: Boolean,
        halfCheck: {
            type: Boolean,
            default: false
        }
    },

    inject: {
        grid: {
            default: null
        }
    },

    computed: {
        noChildren() {
            return Gikam.isEmpty(this.row.children);
        },

        isExpend() {
            return this.row.expend;
        },

        htmlRender() {
            return this.fieldOptions.htmlRender;
        },

        text() {
            let valueText = '';
            if (this.fieldOptions.formatter) {
                valueText = this.fieldOptions.formatter(this.row);
            } else {
                valueText = Gikam.getFieldValue(this.row, this.fieldOptions.field);
            }
            return this.$xss(valueText);
        }
    },

    watch: {
        checked() {
            if (Gikam.isEmpty(this.row.checked)) {
                return;
            }
            if (this.row.checked) {
                if (this.single) {
                    if (this.checkedNodes.length > 0) {
                        this.checkedNodes.length = 0;
                    }
                }
                this.checkedNodes.push(this);
                this.checkedNodes.sort((a, b) => {
                    return a.id - b.id;
                });
                if (!this.halfCheck) {
                    this.addCheckedTreeNodes(this);
                }
            } else {
                const index = this.checkedNodes.indexOf(this);
                index > -1 && this.checkedNodes.splice(index, 1);
                this.removeCheckedTreeNodes(this);
            }
        },

        halfCheck(value, preValue) {
            if (value === true && preValue === false) {
                this.removeCheckedTreeNodes(this);
            }
        }
    },

    methods: {
        changeHandle(checked) {
            event && event.stopPropagation();
            this.$emit('change', checked, this.single, this.row, this.rowIndex);
        },

        getcheckedNodesIndex() {
            return this.checkedNodes.map(item => item.rowIndex);
        },

        getCheckedNodeRow() {
            return this.checkedNodes.map(item => item.row);
        },

        toggleNode() {
            if (Gikam.isEmpty(this.row.children)) {
                return;
            }
            this.row.expend = !this.row.expend;
            this.grid.changeChildren(this.row.id, this.row.expend);
        },

        ...mapMutations(['addCheckedTreeNodes', 'removeCheckedTreeNodes'])
    }
};
</script>

<style scoped>
.tree-grid-node {
    display: flex;
    align-items: center;
}

.tree-grid-node-blank {
    width: 15px;
}

.node-text {
    padding-left: 5px;
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}

.tree-grid-node .noswitch {
    padding: 6px;
    margin-right: 5px;
}

.tree-grid-node .switch {
    padding: 6px;
    margin-right: 5px;
    cursor: pointer;
    background: url(../img/tree-plus.png) center no-repeat;
}

.tree-grid-node .switch.expend-node {
    background: url(../img/tree-minus.png) center no-repeat;
}
</style>
