/* eslint-disable no-undef */
// noinspection JSUnusedGlobalSymbols
import Base from './base.js';
import Gikam from '../core/gikam-core.js';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N';

let defaultOptions = {
    url: void 0,
    taskId: void 0,
    data: void 0,
    columns: void 0,
    appendFields: [],
    requestParam: void 0,
    initData: void 0,
    success: [],
    fail: [],
    def: void 0,
    selectNextUser: false,
    selectNextRole: false,
    // 是否显示默认审核意见
    showDefaultOpinion: true,
    // 是否回车直接提交
    enterKeyConfirm: false
};

export default class Workflow extends Base {
    constructor(props) {
        super();
        this.initialize(props, defaultOptions);
        this.removeEventHandle = null;
        this.options.def = jQuery.Deferred();
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    validateUser(userId, password) {
        const pwd = Gikam.getJsonWrapper({ password: Gikam.encryptPassword(password) });
        return Gikam.postText(
            Gikam.printf(
                Gikam.IFM_CONTEXT + '/open/core/module/mdm/user-passwords/userId/{userId}/action/validate-password',
                {
                    userId: userId
                }
            ),
            pwd
        );
    }

    submit(param) {
        Gikam.extend(true, this.options, param, {
            title: Gikam.propI18N(param.title || 'GIKAM.WORKFLOW.BUTTON.SUBMIT'),
            opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.SUBMIT.OPINION.TITLE'),
            defaultOpinion: Gikam.propI18N(param.defaultOpinion || 'GIKAM.WORKFLOW.SUBMIT.OPINION.DEFAULT'),
            fileUpload: false,
            url: param.pageObject.baseUrl + '/action/start-process',
            columns: param.pageObject.workflow.columns,
            //是否显示选择下级审核人或角色
            assignCandidate: true
        });
        this.options.bpmn_pass = 1;
        this.openOpinionModal();
        return this.options.def;
    }

    pass(param) {
        Gikam.extend(true, this.options, param, {
            title: Gikam.propI18N(param.title || 'GIKAM.WORKFLOW.BUTTON.PASS'),
            opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.PASS.OPINION.TITLE'),
            defaultOpinion: Gikam.propI18N(param.defaultOpinion || 'GIKAM.WORKFLOW.PASS.OPINION.DEFAULT'),
            fileUpload: true,
            url: param.pageObject.baseUrl + '/action/complete-task',
            columns: param.pageObject.workflow.columns,
            //是否显示选择下级审核人或角色
            assignCandidate: true
        });
        this.options.bpmn_pass = 1;
        this.openOpinionModal('pass');
        return this.options.def;
    }

    review(param) {
        let _this = this;

        Gikam.create('modal', {
            title: 'GIKAM.WORKFLOW.REVIEW.MODAL.CHOOSE_USER',
            url: IFM_CONTEXT + '/secure/core/module/mdm/users/page/choose-list?targetType=audit',
            onAfterClose: function(rows) {
                if (rows) {
                    let chooseData = {
                        auditor: rows.map(function(item) {
                            return item.id;
                        })
                    };

                    Gikam.extend(true, _this.options, param, {
                        title: Gikam.propI18N(param.title || 'GIKAM.WORKFLOW.BUTTON.REVIEW'),
                        opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.REVIEW.OPINION.TITLE'),
                        defaultOpinion: Gikam.propI18N(param.defaultOpinion || 'GIKAM.WORKFLOW.REVIEW.OPINION.DEFAULT'),
                        fileUpload: true,
                        requestParam: chooseData,
                        url: param.pageObject.baseUrl + '/action/review-task',
                        columns: param.pageObject.workflow.columns
                    });

                    _this.openOpinionModal();
                }
            }
        });
        return _this.options.def;
    }

    delegate(param) {
        const _this = this;

        Gikam.create('modal', {
            title: 'GIKAM.WORKFLOW.DELEGATE.MODAL.CHOOSE_USER',
            url: IFM_CONTEXT + '/secure/core/module/mdm/users/page/choose-list?targetType=audit',
            onAfterClose: function(rows) {
                if (rows) {
                    const chooseData = {
                        auditor: rows.map(function(item) {
                            return item.id;
                        })
                    };

                    Gikam.extend(true, _this.options, param, {
                        title: Gikam.propI18N(param.title || 'GIKAM.WORKFLOW.BUTTON.DELEGATE'),
                        opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.DELEGATE.OPINION.TITLE'),
                        defaultOpinion: Gikam.propI18N(
                            param.defaultOpinion || 'GIKAM.WORKFLOW.DELEGATE.OPINION.DEFAULT'
                        ),
                        fileUpload: true,
                        requestParam: chooseData,
                        url: param.pageObject.baseUrl + '/action/delegate-task',
                        columns: param.pageObject.workflow.columns
                    });

                    _this.openOpinionModal();
                }
            }
        });
        return _this.options.def;
    }

    undo(param) {
        Gikam.extend(true, this.options, param, {
            title: Gikam.propI18N(param.title || 'GIKAM.BPMN.COMMENT.UNDO'),
            opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.UNDO.OPINION.TITLE'),
            defaultOpinion: Gikam.propI18N(param.defaultOpinion || 'GIKAM.WORKFLOW.UNDO.OPINION.DEFAULT'),
            fileUpload: false,
            url: param.pageObject.baseUrl + '/action/undo',
            columns: param.pageObject.workflow.columns
        });
        this.openOpinionModal();
        return this.options.def;
    }

    withdraw(param) {
        Gikam.extend(true, this.options, param, {
            title: Gikam.propI18N(param.title || 'GIKAM.BPMN.COMMENT.WITHDRAW'),
            opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.WITHDRAW.OPINION.TITLE'),
            defaultOpinion: Gikam.propI18N(param.defaultOpinion || 'GIKAM.WORKFLOW.WITHDRAW.OPINION.DEFAULT'),
            fileUpload: false,
            url: param.pageObject.baseUrl + '/action/withdraw-process'
        });
        this.options.bpmn_pass = -1;
        this.openOpinionModal();
        return this.options.def;
    }

    countersign(param) {
        let _this = this;

        Gikam.create('modal', {
            title: 'GIKAM.WORKFLOW.COUNTERSIGN.MODAL.CHOOSE_USER',
            url: IFM_CONTEXT + '/secure/core/module/mdm/users/page/choose-list?targetType=audit',
            onAfterClose: function(rows) {
                if (rows) {
                    let chooseData = {
                        auditor: rows.map(function(item) {
                            return item.id;
                        })
                    };

                    Gikam.extend(true, _this.options, param, {
                        title: Gikam.propI18N(param.title || 'GIKAM.WORKFLOW.BUTTON.COUNTERSIGN'),
                        opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.COUNTERSIGN.OPINION.TITLE'),
                        defaultOpinion: Gikam.propI18N(
                            param.defaultOpinion || 'GIKAM.WORKFLOW.COUNTERSIGN.OPINION.DEFAULT'
                        ),
                        fileUpload: true,
                        requestParam: chooseData,
                        url: param.pageObject.baseUrl + '/action/countersign-task',
                        columns: param.pageObject.workflow.columns
                    });

                    _this.openOpinionModal();
                }
            }
        });
        return _this.options.def;
    }

    reject(param) {
        if (Gikam.isEmpty(param.data)) {
            Gikam.alert('GIKAM.TIP.CHOOSE_AT_LEAST_ONE_ITEM');
            return this.options.def;
        }
        let _this = this;
        let bpmn_currentStatusCode = Gikam.getRequestParam(workspace.activeUrl).bpmn_currentStatusCode;
        let p = { bpmn_table: param.pageObject.workflow.dbTable };
        if (bpmn_currentStatusCode) {
            param.bpmn_currentStatusCode = bpmn_currentStatusCode;
        }
        let json = Gikam.getJsonWrapper(p, [
            '',
            param.data.map(item => {
                return {
                    id: item.id
                };
            })
        ]);
        workspace.window.showMask();
        Gikam.post(IFM_CONTEXT + '/secure/core/module/sys/bpmn-exts/options/rejectable-tasks', json)
            .done(function(nodes) {
                _this.options.requestParam = Gikam.extend(_this.options.requestParam, {
                    reverse: true
                });
                Gikam.extend(true, _this.options, param, {
                    title: Gikam.propI18N(param.title || 'GIKAM.WORKFLOW.BUTTON.REJECT'),
                    opinionTitle: Gikam.propI18N(param.opinionTitle || 'GIKAM.WORKFLOW.REJECT.OPINION.TITLE'),
                    defaultOpinion: Gikam.propI18N(param.defaultOpinion || 'GIKAM.WORKFLOW.REJECT.OPINION.DEFAULT'),
                    fileUpload: false,
                    url: param.pageObject.baseUrl + '/action/reject-task',
                    columns: param.pageObject.workflow.columns,
                    appendFields: [
                        {
                            title: Gikam.propI18N('GIKAM.WORKFLOW.REJECT.REJECT_TO_TITLE'),
                            type: 'select',
                            field: 'bpmn_targetStatusCode',
                            firstBlank: false,
                            validators: ['notEmpty'],
                            value: nodes[0].statusCode,
                            items: nodes.map(function(item) {
                                return {
                                    value: item.statusCode,
                                    text: item.taskName
                                };
                            })
                        }
                    ]
                });
                _this.options.bpmn_pass = 0;
                _this.openOpinionModal();
            })
            .fail(() => {
                workspace.window.closeMask();
            });

        return this.options.def;
    }

    getBpmnTaskStatus() {
        if (this.options.requestStatus === false) {
            const def = jQuery.Deferred();
            return def.resolve();
        }
        const params = this.options.data.map(item => {
            return {
                id: item.id
            };
        });
        const bpmnParams = this.options.bpmn_pass === null ? '' : { bpmn_pass: this.options.bpmn_pass };
        const url = this.options.pageObject.baseUrl + '/bpmn-task-status';
        const jsonWrapper = Gikam.getJsonWrapper(bpmnParams, ['', params]);
        //是不是需要校验用户名和密码、是否需要选择下级审核人或审核角色、是否需要上传附件
        return Gikam.post(url, jsonWrapper)
            .done(r => {
                Gikam.extend(this.options, {
                    bpmnTaskStatus: r
                });
            })
            .fail(() => {
                workspace.window.closeMask();
            });
    }

    getModalHeight() {
        const { appendFields, bpmnTaskStatus, assignCandidate } = this.options;
        let defaultHeight = 230;
        if (assignCandidate && bpmnTaskStatus && bpmnTaskStatus.nextCandidatorOptStrategy !== 'none') {
            defaultHeight += 42;
        }
        if (bpmnTaskStatus && bpmnTaskStatus.authRequired) {
            defaultHeight += 84;
        }
        if (bpmnTaskStatus && bpmnTaskStatus.attachmentStrategy !== 'none') {
            defaultHeight += 42;
        }
        if (Gikam.isNotEmpty(appendFields)) {
            const highHeightTypes = ['textarea', 'image', 'checkbox'];
            const highTypeFields = appendFields.filter(item => {
                return highHeightTypes.indexOf(item.type) > -1;
            });
            return appendFields.length * 42 + highTypeFields.length * 8 + defaultHeight;
        }
        return defaultHeight;
    }

    openOpinionModal(type) {
        let _this = this;

        if (Gikam.isEmpty(this.options.data)) {
            Gikam.alert('GIKAM.TIP.CHOOSE_AT_LEAST_ONE_ITEM');
            return;
        }

        if (Gikam.isFalse(_this.options.manual)) {
            let opinion = {};
            opinion.bpmn_comment = _this.options.default_comment ? _this.options.default_comment : '';
            _this.options.opinionObject = opinion;
            _this.submitData();
        } else {
            workspace.window.showMask();
            this.getBpmnTaskStatus().done(() => {
                workspace.window.closeMask();
                let modal = Gikam.create('modal', {
                    title: this.options.title,
                    width: 500,
                    height: this.options.height || this.getModalHeight(),
                    onAfterClose: function(data) {
                        if (!data) {
                            return;
                        }
                        _this.options.opinionObject = data;
                        delete _this.options.opinionModal;
                        _this.submitData();
                        _this.removeEventHandle && _this.removeEventHandle();
                    },
                    onBeforeClose: function() {
                        _this.removeEventHandle && _this.removeEventHandle();
                    }
                });

                Gikam.extend(this.options, {
                    opinionModal: modal
                });

                if (this.options.data.length === 1 && this.options.fileUpload) {
                    this.getTaskId().done(function() {
                        _this.createOpinionPage(type);
                    });
                } else {
                    _this.createOpinionPage(type);
                }
            });
        }
    }

    createOpinionPage(type) {
        const bpmnTaskStatus = this.options.bpmnTaskStatus || {};
        const assignCandidate = this.options.assignCandidate;
        // 是否显示转办按钮
        const showTransfer = type === 'pass' && bpmnTaskStatus.transfer;
        // eslint-disable-next-line complexity
        const getFields = () => {
            let fields = _this.options.appendFields;
            //是否选择下一级审核人/角色
            if (assignCandidate === true && bpmnTaskStatus.nextCandidatorOptStrategy === 'assigned') {
                fields = fields.concat({
                    field: 'bpmn_nextCandidator_name',
                    title: 'GIKAM.WORKFLOW.PASS.NEXT_AUDITOR',
                    type: 'customChoose',
                    single: false,
                    mode: 'user',
                    data: bpmnTaskStatus.candidatorList,
                    validators: showTransfer ? [] : ['notEmpty'],
                    onAfterChoose: function(rows) {
                        if (Gikam.isNotEmpty(rows)) {
                            let users = '';
                            let names = '';
                            rows.forEach(user => {
                                users += user.id + ',';
                                names += user.userName + ',';
                            });
                            this.setData({
                                bpmn_nextCandidator: users.substr(0, users.length - 1),
                                bpmn_nextCandidator_name: names.substr(0, names.length - 1)
                            });
                        }
                    }
                });
            }
            if (assignCandidate === true && bpmnTaskStatus.nextCandidatorOptStrategy === 'assignedRole') {
                fields = fields.concat({
                    field: 'bpmn_nextCandidateRole_name',
                    title: 'GIKAM.WORKFLOW.PASS.NEXT_ROLE',
                    type: 'customChoose',
                    mode: 'role',
                    single: false,
                    data: bpmnTaskStatus.candidateRoleList,
                    validators: showTransfer ? [] : ['notEmpty'],
                    onAfterChoose: function(rows) {
                        if (Gikam.isNotEmpty(rows)) {
                            let roles = '';
                            let names = '';
                            rows.forEach(user => {
                                roles += user.id + ',';
                                names += user.roleName + ',';
                            });
                            this.setData({
                                bpmn_nextCandidateRole: roles.substr(0, roles.length - 1),
                                bpmn_nextCandidateRole_name: names.substr(0, names.length - 1)
                            });
                        }
                    }
                });
            } else if (assignCandidate === true && bpmnTaskStatus.nextCandidatorOptStrategy === 'allUser') {
                fields = fields.concat({
                    field: 'bpmn_nextCandidator_name',
                    title: 'GIKAM.WORKFLOW.PASS.NEXT_AUDITOR',
                    type: 'choose',
                    single: false,
                    category: 'user',
                    validators: showTransfer ? [] : ['notEmpty'],
                    onAfterChoose: function(rows) {
                        if (Gikam.isNotEmpty(rows)) {
                            let users = '';
                            let names = '';
                            rows.forEach(user => {
                                users += user.id + ',';
                                names += user.userName + ',';
                            });
                            this.setData({
                                bpmn_nextCandidator: users.substr(0, users.length - 1),
                                bpmn_nextCandidator_name: names.substr(0, names.length - 1)
                            });
                        }
                    }
                });
            } else if (assignCandidate === true && bpmnTaskStatus.nextCandidatorOptStrategy === 'allRole') {
                fields = fields.concat({
                    field: 'bpmn_nextCandidateRole_name',
                    title: 'GIKAM.WORKFLOW.PASS.NEXT_ROLE',
                    type: 'choose',
                    single: false,
                    category: 'role',
                    validators: showTransfer ? [] : ['notEmpty'],
                    onAfterChoose: function(rows) {
                        if (Gikam.isNotEmpty(rows)) {
                            let roles = '';
                            let names = '';
                            rows.forEach(user => {
                                roles += user.id + ',';
                                names += user.roleName + ',';
                            });
                            this.setData({
                                bpmn_nextCandidateRole: roles.substr(0, roles.length - 1),
                                bpmn_nextCandidateRole_name: names.substr(0, names.length - 1)
                            });
                        }
                    }
                });
            }

            const validators = bpmnTaskStatus.commentRequired ? ['notEmpty'] : [];

            fields = fields.concat([
                {
                    title: this.options.opinionTitle,
                    field: 'bpmn_comment',
                    type: 'textarea',
                    height: 100,
                    value: this.options.showDefaultOpinion === false ? '' : this.options.defaultOpinion,
                    rows: 5,
                    validators
                }
            ]);

            if (bpmnTaskStatus.authRequired) {
                fields = fields.concat([
                    {
                        title: '用户名',
                        field: 'userId',
                        type: 'text',
                        readonly: true,
                        validators: ['notEmpty'],
                        value: workspace.user.id
                    },
                    {
                        title: '密码',
                        field: 'password',
                        type: 'password',
                        validators: ['notEmpty']
                    }
                ]);
            }

            if (bpmnTaskStatus.attachmentStrategy && bpmnTaskStatus.attachmentStrategy !== 'none') {
                let validators = null;
                if (bpmnTaskStatus.attachmentStrategy === 'mandatory') {
                    validators = ['notEmpty'];
                }
                fields = fields.concat({
                    title: 'GIKAM.FILE.ATTACHMENT',
                    field: 'bpmn_attachment',
                    type: 'uploadButton',
                    dbTable: 'BPMN',
                    bizId: 'COMMENT',
                    deleteUrl: '/core/module/item/files',
                    validators,
                    value: ''
                });
            }
            return fields;
        };

        const _this = this;

        const buttons = [
            {
                type: 'button',
                text: 'GIKAM.BUTTON.CONFIRM',
                class: 'blue',
                onClick: function() {
                    if (Gikam.getComp(formId).validate()) {
                        const data = Gikam.getComp(formId).getData();
                        if (bpmnTaskStatus.authRequired) {
                            _this.validateUser(data.userId, data.password).done(function(r) {
                                if (r === '1') {
                                    _this.options.opinionModal.close(data);
                                } else {
                                    Gikam.alert('GIKAM.SECURITY.LOGIN.INCORRECT_USER_OR_PASSWORD');
                                }
                            });
                            return;
                        }
                        _this.options.opinionModal.close(data);
                    }
                }
            },
            {
                type: 'button',
                text: 'GIKAM.BUTTON.CANCEL',
                onClick: function() {
                    _this.options.opinionModal.close();
                }
            }
        ];

        if (showTransfer) {
            buttons.unshift({
                type: 'button',
                text: I18N.prop('workflow.transfer'),
                class: 'blue',
                onClick: function() {
                    if (Gikam.getComp(formId).validate()) {
                        const data = Gikam.getComp(formId).getData();
                        const config = Gikam.choose.getConfig('user');

                        const selectTransferor = function() {
                            Gikam.create('modal', {
                                title: I18N.prop('workflow.transferor'),
                                width: 618,
                                url: Gikam.IFM_CONTEXT + config.url + '?single=1',
                                onAfterClose: function(rows) {
                                    if (!rows || !rows.length) {
                                        Gikam.toast(I18N.prop('workflow.transferor.select'));
                                        return;
                                    }
                                    data.bpmn_transferCandidator = rows[0].id;
                                    _this.options.opinionModal.close(data);
                                    _this.options.url = _this.options.pageObject.baseUrl + '/action/transfer-task';
                                }
                            });
                        };

                        if (bpmnTaskStatus.authRequired) {
                            _this.validateUser(data.userId, data.password).done(function(r) {
                                if (r === '1') {
                                    selectTransferor();
                                } else {
                                    Gikam.alert('GIKAM.SECURITY.LOGIN.INCORRECT_USER_OR_PASSWORD');
                                }
                            });
                            return;
                        }

                        selectTransferor();
                    }
                }
            });
        }

        let formId = 'workflow-opinion-form-' + Date.now(),
            layout = Gikam.create('layout', {
                renderTo: this.options.opinionModal.window.$dom,
                center: {
                    items: [
                        {
                            type: 'btnToolbar',
                            items: buttons
                        },
                        {
                            type: 'form',
                            columns: 1,
                            id: formId,
                            style: {
                                padding: '10px'
                            },
                            fields: getFields()
                        }
                    ]
                }
            });

        if (this.options.enterKeyConfirm) {
            const submitHandle = e => {
                if (e.keyCode === 13) {
                    if (!Gikam.getLastModal()) {
                        document.removeEventListener('keyup', submitHandle);
                        return;
                    }
                    buttons[0].onClick();
                }
            };
            document.addEventListener('keyup', submitHandle);
            this.removeEventHandle = () => {
                document.removeEventListener('keyup', submitHandle);
            };
        }

        this.options.$fileContainer = this.createFileContainer(layout);

        if (this.options.taskId) {
            this.getFiles().done(files => {
                if (files) {
                    _this.refreshFileList([JSON.parse(files)]);
                }
            });
        }
    }

    createFileContainer(layout) {
        let fileContainerTpl =
            '<div class="workflow-upload-result-container">' +
            '<div class="inner-container">' +
            '<div class="title">' +
            Gikam.propI18N('GIKAM.FILE.ATTACHMENT') +
            '：</div>' +
            '</div>' +
            '</div>';
        return jQuery(fileContainerTpl)
            .appendTo(layout.options.center.$dom)
            .hide();
    }

    refreshFileList(files) {
        if (Gikam.isEmpty(files)) {
            return;
        }
        let itemTpl = '<a href="javascript:;" onclick="Gikam.printFile({id}, true, null, false, true)">{name}</a>';
        let itemHtml = '';
        Gikam.each(files, function() {
            itemHtml += Gikam.printf(itemTpl, this);
        });
        this.options.$fileContainer
            .children('.inner-container')
            .children('a')
            .remove()
            .end()
            .append(itemHtml);
        this.options.$fileContainer.show();
    }

    getFiles() {
        return Gikam.getText(
            Gikam.printf(IFM_CONTEXT + '/core/module/item/files/filter-target/{targetId}', {
                targetId: 'ACT_COMMENT_[{loginUserId}]$' + this.options.taskId
            })
        );
    }

    getTaskId() {
        return Gikam.getJson(this.options.pageObject.baseUrl + `/${this.options.data[0].id}/info/status`);
    }

    uploadFile() {
        let _this = this;
        _this.getFiles().done(function(r) {
            if (r) {
                Gikam.alert('GIKAM.FILE.TIP.UPLOAD_ONE_ONLY');
                return;
            }

            Gikam.create('simpleUploader', {
                multiple: false,
                onBeforeUpload: function() {
                    this.setOptions({
                        dbTable: 'ACT_COMMENT_[{loginUserId}]',
                        bizId: _this.options.taskId
                    });
                },
                onAfterClose: function() {
                    _this.getFiles().done(function(files) {
                        files && _this.refreshFileList([JSON.parse(files)]);
                    });
                }
            });
        });
    }

    submitData() {
        workspace.window.showMask();
        let _this = this;
        let backData = {};
        backData.bpmn_comment = this.options.opinionObject.bpmn_comment;
        Gikam.put(
            this.options.url,
            Gikam.getJsonWrapper(Gikam.extend(true, {}, this.options.requestParam, this.options.opinionObject), [
                '',
                this.options.data.map(item => {
                    return {
                        id: item.id
                    };
                })
            ]),
            this.options.header
        )
            .done(() => {
                _this.options.def.resolve(backData);
            })
            .always(() => {
                workspace.window.closeMask();
            });
    }
}
