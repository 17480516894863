var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},_vm._l((_vm.leafColumns),function(col,colIndex){return _c('div',{key:_vm._f("getColKey")(col,_vm.columnGroupMap),staticClass:"body-col",style:(_vm._f("getWidth")(col.field,_vm.fieldWidthMap, colIndex))},[_vm._l((_vm.data),function(row,rowIndex){return [_c('body-cell',{directives:[{name:"cell-merge",rawName:"v-cell-merge:[{rowIndex,col}]",value:(_vm.cellMerge),expression:"cellMerge",arg:{rowIndex,col}}],key:row.id || rowIndex,ref:rowIndex + '_' + colIndex,refInFor:true,staticClass:"body-row",class:[
                    {
                        activeRow: _vm.activeRowIndex === rowIndex,
                        couldActiveCell: _vm.activeCell,
                        activeField: rowIndex + '_' + colIndex === _vm.currentCell
                    }
                ],attrs:{"propsFeild":col,"propsRow":row,"rowIndex":rowIndex,"colIndex":colIndex},on:{"click":function($event){return _vm.changeActiveRowIndex({ index: rowIndex, type: 'row' })}}})]})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }